<template>
  <div v-for="(details, index) in props.ticketDetails" :key="index"
    class="w-auto bg-backgroundSidebar grid grid-rows-[auto_1fr_auto] grid-cols-[auto] gap-[21px] h-auto m-4 p-2.5 rounded-[25px]">
    <div class="flex gap-[3px] mt-1">
      <img class="w-15 h-15 rounded-full m-1" :src="'https://ui-avatars.com/api/?background=1c3450&color=fff&name=' +
        details.requesterEmail
        " alt="" srcset="" />
      <div class="flex flex-col justify-center pr-[15px] sm:pl-4 w-full">
        <div class="flex justify-between items-center">
          <span class="text-base font-semibold">{{ details.requesterEmail }} </span><span
            class="text-[medium] text-right">{{
              currentTime(details.createdAt)
            }}</span>
        </div>
        <div class="text-[x-large] font-bold" v-html="details.subject"></div>

        <div class="flex gap-2.5 text-[large]">
          <span v-if="details?.to?.length > 0">To:
            <span class="flex gap-2.5 text-[large] text-[rgb(41,57,72)] font-medium" v-for="to in details.to"
              :key="to">{{ details?.to?.length > 1 ? to + ", " : to + "" }}</span></span><span
            v-if="details?.cc?.length > 0">Cc:
            <span class="flex gap-2.5 text-[large]" v-for="cc in details.cc" :key="cc">{{ details?.cc?.length > 1 ? cc +
              ", " : cc + "" }}</span></span>
        </div>
      </div>
    </div>
    <div class="bg-[rgb(29,47,62)] h-0.5"></div>
    <div class="pt-0 pb-2.5 px-2.5">
      <div class="grid grid-cols-[auto]">
        <div class="overflow-auto mb-[26px] max-h-[600px] chat-response">
          <div v-if="!details.isAutoGenerated">
            <vue-markdown :source="details.description" />
          </div>
          <div v-else-if="!isEditableResponse && details.isAutoGenerated">
            <vue-markdown :source="beforeeditedContentMessage" />
          </div>
          <div v-else-if="isEditableResponse && details.isAutoGenerated">
            <textarea rows="15" v-model="beforeeditedContentMessage"
              style="width: 100%; background-color: transparent; border: none;resize: none;height: 100%">
            </textarea>
          </div>
        </div>
        <div class="mb-8">
          <div class="w-full grid grid-cols-[25%_25%_25%] gap-[9px]">
            <a class="grid gap-2 grid-cols-[23%_auto] w-full whitespace-nowrap overflow-hidden text-ellipsis p-2.5 rounded-xl background: rgb(17, 56, 91)"
              v-for="(attchment, index) in details.attachments" :key="index" :href="attchment.url" target="_blank"
              rel="noopener noreferrer">
              <div class="w-[86%] grid text-center justify-center p-0.5 bg-backgroundSidebar">
                <img style="
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    background-color: white;
                  " :src="getFileImageIcon(attchment.contentType)" alt="" srcset="" />
              </div>
              <div class="w-[197px]">
                <div class="text-ellipsis whitespace-nowrap overflow-hidden">
                  {{ attchment.name }}
                </div>
                <div>
                  {{
                    attchment.contentType
                      .split("/")[1]
                      .toUpperCase()
                      .replace("PLAIN", "TEXT")
                  }}
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-[auto_auto_auto] gap-x-[2%] px-[2%] pb-[2%]" v-if="!isEditableResponse">
    <div class="flex w-full rounded-xl bg-[rgb(241,77,77)] relative flex flex-col gap-2 dummy-class">
      <div v-if="activeMenu === 'Decline' && !isButtonVisible"
        class="absolute bg-transparent text-[white] z-10 w-full flex flex-col items-center transition-all duration-[0.2s] ease-[ease] p-2.5 rounded-[5px]"
        style="bottom: 50px; left: 0" ref="buttonWrapper">
        <button class="bg-menuSelected mt-1 rounded-[5px] p-[0.625rem] mb-2 w-full" @click="regenerate"
          style="color: white">Regenerate</button>
        <button class="bg-menuSelected mt-1 rounded-[5px] p-[0.625rem] mb-2 w-full" @click="declineContent"
          style="color: red">Decline</button>
      </div>
      <button class="w-full flex items-center justify-center gap-1.5 bg-transparent p-1.5 border-[none]"
        :style="declinestyle" @click="toggleMenu('Decline')">
        <img src="../../assets/decline-icon.svg" alt="" srcset="" /> Decline
      </button>
    </div>
    <div class="flex w-full rounded-xl bg-[rgb(53,68,81)]">
      <button class="w-full flex items-center justify-center gap-1.5 bg-transparent p-1.5 border-[none]"
        @click="adjust">
        <img src="../../assets/edit-05.svg" alt="" srcset="" /> Adjust
      </button>
    </div>
    <div class="flex w-full rounded-xl bg-[rgb(123,168,239)]">
      <button class="w-full flex items-center justify-center gap-1.5 bg-transparent p-1.5 border-[none]"
        @click="approve">
        <img src="../../assets/approve-check.svg" alt="" srcset="" /> Approve
      </button>
    </div>
  </div>
  <div class="grid grid-cols-[auto_auto_auto] gap-x-[2%] px-[2%] pb-[2%]"
    style="justify-content: end; grid-template-columns: 25% 25%" v-else>
    <div class="flex w-full rounded-xl bg-[rgb(53,68,81)]">
      <button class="w-full flex items-center justify-center gap-1.5 bg-transparent p-1.5 border-[none]"
        style="background-color: rgb(26, 42, 57); border-radius: 12px" @click="cancelEditedResponse">
        Cancel
      </button>
    </div>
    <div class="flex w-full rounded-xl bg-[rgb(123,168,239)]">
      <button class="w-full flex items-center justify-center gap-1.5 bg-transparent p-1.5 border-[none]"
        @click="saveEditedResponse()">
        Save
      </button>
    </div>
  </div>
  <div class="absolute w-full h-full flex justify-between items-center z-[1000] backdrop-blur-sm top-0" v-if="false">
    <div class="bg-[rgb(17,56,91)] w-[58%] text-white text-left ml-[7%] p-5 rounded-lg">
      <div class="flex justify-between items-center mb-[3px]">
        <div class="text-xl flex gap-[5px]">
          <img src="../../assets/thumbs-down-icon.svg" alt="" srcset="" />
          Provide additional feedback
        </div>
        <div class="cursor-pointer" @click="declineModalVisible = false">
          <img src="../../assets/icon-x.svg" srcset="" />
        </div>
      </div>
      <div class="mb-[1%] border-[thin] border-solid border-[#324457]"></div>
      <div class="inline-flex bg-[#1a2637] rounded-lg font-sans border rounded-sm border-buttonBlue mb-[1%]">
        <div id="personal"
          class="text-lg cursor-pointer transition-all duration-[0.3s] ease-[ease] select-none px-3.5 rounded-md bg-blue-400 text-[white]">
          Personal</div>
        <!-- <div id="item" class="tab inactive">Item</div> -->
      </div>
      <div style="font-size: small; margin-bottom: 2px">
        <textarea v-model="declineContentMessage" class="w-full bg-[rgb(7,26,43)] resize-none p-3 rounded-[5px]"
          placeholder="What do you personally not like about the style of this response?" id="" rows="8"></textarea>
      </div>

      <div style="text-align: end">
        <button class="w-1/5 bg-[rgb(123,168,239)] p-[7px] rounded-md" @click="declineContent">
          Send feedback
        </button>
      </div>
    </div>
  </div>

  <div class="absolute w-full h-full flex justify-center items-center z-[1000] backdrop-blur-sm top-0"
    v-if="regenerateModalisVisible">
    <div
      class="bg-[rgb(17,56,91)] w-[90%] sm:w-[40%] md:w-[30%] lg:w-[50%] text-white text-left p-5 rounded-lg mr-[20%] md:mr-[25%]">
      <div class="flex justify-between items-center mb-[3px]">
        <div class="text-lg sm:text-xl flex gap-2">
          <!-- <img src="../../assets/instructions-icon.svg" alt="" srcset="" /> -->
          Freddy’s instructions
        </div>
        <div class="cursor-pointer" @click="regenerateModalisVisible = false">
          <img src="../../assets/icon-x.svg" srcset="" />
        </div>
      </div>
      <div class="mb-[1%] border-[thin] border-solid border-[#324457]"></div>

      <div style="font-size: small; margin-bottom: 2px">
        <textarea v-model="regenerateContentmessage"
          class="w-full bg-[rgb(7,26,43)] resize-none p-3 rounded-md text-sm md:text-base"
          placeholder="Please let us know how we can improve your experience." id="" rows="8"></textarea>
      </div>

      <div style="text-align: end">
        <button class="w-full sm:w-1/3 bg-[rgb(123,168,239)] py-2 rounded-md hover:bg-blue-500 transition"
          @click="regenerateContent">
          Regenerate
        </button>
      </div>
    </div>
  </div>

  <div v-if="isLoading || isLoadTicketDetails"
    class="absolute w-[69.5%] h-3/4 flex flex-col justify-center items-center z-[2] left-[28%] top-[23%] backdrop-blur-sm">
    <div
      class="w-[50px] h-[50px] animate-spin rounded-[50%] border-t-[white] border-[6px] border-solid border-[rgba(255,255,255,0.3)]">
    </div>
    <p class="text-base text-[white] text-center mt-2.5">Please give us a second</p>
  </div>
  <!-- <div class="app-container">     
     Loading Overlay 
  </div> -->
</template>

<script setup lang="ts">
import { ref, onMounted, onUpdated, watch, reactive, onBeforeUnmount } from "vue";
import type { ITicketDetails } from "@/Models/CheckPoints/ITickets";
import pdfIcon from "../../assets/pdfIcon.png";
//import docIcon from "../../assets/docIcon.png";
import imageIcon from "../../assets/imageIcon.png";
import textIcon from "../../assets/textIcon.png";
import { CheckPointsService } from "@/Services/CheckPointsService";
import VueMarkdown from "vue-markdown-render";

const service = new CheckPointsService();

const regenerateContentmessage = ref("");
const declineContentMessage = ref("");
const beforeeditedContentMessage = ref<string>("");
const isLoading = ref(false);
const isLoadTicketDetails = ref(false);
const regenerateModalisVisible = ref(false);
const declineModalVisible = ref(false);
const activeMenu = ref("");
const declinestyle = ref({});
const isNewResponseGenerated = ref<boolean>(false);
const isEditableResponse = ref(false);
const buttonWrapper = ref<HTMLElement | null>(null);
const isButtonVisible = ref(false);
// const btnClickcount = ref(0);

const emits = defineEmits(["onTicketSelected"]);

const props = defineProps({
  ticketDetails: {
    type: Object,
    default: {} as ITicketDetails,
  },
  selectedTicketId: {
    type: Number,
    default: 0,
  },
  isLoadingTicket: {
    type: Boolean,
    default: false,
  },
});

const currentTime = (value: string) => {
  const dateTime = new Date(value);
  const time = dateTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  return time;
};

const handleClickOutside = (event: MouseEvent) => {
  const target = event.target as HTMLElement;
  if (!target.closest('.dummy-class')) {
    isButtonVisible.value = true;
    declinestyle.value = { 
      backgroundColor: "rgb(241,77,77)",
      border: "1px solid rgb(241,77,77)",
      borderRadius: "12px",
    };
  }
  else {
    isButtonVisible.value = false;
  }
};

onMounted(() => {
  beforeeditedContentMessage.value = props.ticketDetails[props.ticketDetails.length - 1].description;

  isLoadTicketDetails.value = props.isLoadingTicket;
  setTimeout(() => {
    isLoadTicketDetails.value = false;
  }, 100);
  document.addEventListener("click", handleClickOutside);
});

watch(
  () => props.ticketDetails,
  () => {
    if (!isNewResponseGenerated.value) {
      isLoadTicketDetails.value = props.isLoadingTicket;
      setTimeout(() => {
        isLoadTicketDetails.value = false;
      }, 200);
    }
    isNewResponseGenerated.value = false;
  }
);

function simulateLoading() {
  isLoading.value = true;
  setTimeout(() => {
    isLoading.value = false;
  }, 3000); // 3 seconds loading
}

const getFileImageIcon = (fileType: string): string => {
  switch (fileType) {
    case "application/pdf":
      return pdfIcon;
    // case "application/msword":
    // case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    //   return docIcon;
    case "image/png":
    case "image/jpeg":
    case "image/gif":
    case "image/tiff":
      return imageIcon;
    default:
      return textIcon;
  }
};

function toggleMenu(menuName: string) {
  activeMenu.value = activeMenu.value === menuName ? "" : menuName;
  declinestyle.value =
    menuName === activeMenu.value
      ? {
        backgroundColor: "rgb(98, 43, 53)",
        border: "1px solid rgb(98, 43, 53)",
        borderRadius: "12px",
      }
      : {
        backgroundColor: "rgb(241,77,77)", border: "1px solid rgb(241,77,77)",
        borderRadius: "12px",
      }
}
const onHotReload = reactive({
  ticketId: 0,
  isDeclined: false,
});
const selectTicket = (ticketId: number, isDeclineOrApprove: boolean) => {
  onHotReload.ticketId = ticketId;
  onHotReload.isDeclined = isDeclineOrApprove;
  emits("onTicketSelected", onHotReload);
};

// Method to close the menu
function closeMenu() {
  activeMenu.value = "";
  declinestyle.value = {};
}
function regenerate() {
  closeMenu();
  regenerateModalisVisible.value = true;
}

function regenerateContent() {
  regenerateModalisVisible.value = false;
  //simulateLoading();
  isLoadTicketDetails.value = true;
  const response = service
    .regenerateCheckPointsTicketsById(
      props.selectedTicketId,
      regenerateContentmessage.value
    )
    .then((value) => {

      isNewResponseGenerated.value = value;
      if (value) {
        isLoadTicketDetails.value = false;
        selectTicket(props.selectedTicketId, false);
      } else {
        console.error("Regeneration failed.");
        isLoadTicketDetails.value = false;
      }
    });
}
function decline() {
  closeMenu();
  declineModalVisible.value = true;
}

function declineContent() {
  isLoadTicketDetails.value = true;
  declineModalVisible.value = false;
  const response = service
    .DeclineCheckPointsTicketsById(
      props.selectedTicketId,
      declineContentMessage.value
    )
    .then((value) => {
      if (value) {
        isLoadTicketDetails.value = false;
        selectTicket(props.selectedTicketId, true);
      } else {
        console.error("Decline failed.");
        isLoadTicketDetails.value = false;
      }
    });
}
function approve() {
  closeMenu();
  isLoading.value = true;
  const response = service
    .approveCheckPointsTicketsById(props.selectedTicketId)
    .then((value) => {
      if (value) {
        isLoading.value = false;
        selectTicket(props.selectedTicketId, true);
      } else {
        console.error("approve failed.");
        isLoading.value = false;
      }
    });
}

function adjust() {
  isEditableResponse.value = true;
  closeMenu();
}

function cancelEditedResponse() {
  isEditableResponse.value = false;
  beforeeditedContentMessage.value = props.ticketDetails[props.ticketDetails.length - 1].description;
}

function saveEditedResponse() {
  const selectedTicket: ITicketDetails = props.ticketDetails[props.ticketDetails.length - 1];
  isLoading.value = true;
  const response = service
    .saveEditedResponseByTicketId(
      props.selectedTicketId,
      beforeeditedContentMessage.value
    )
    .then((value) => {

      if (value) {
        isEditableResponse.value = false;

        isLoading.value = false;
      } else {
        console.error("Regeneration failed.");
        isLoading.value = false;
      }
    });
}

function beforeUnmount() {
  throw new Error("Function not implemented.");
}
onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});

</script>

<!-- <style scoped lang="scss">
.ticket-main {
  @apply w-auto min-h-[500px] bg-[#071a2b] grid grid-rows-[19%_1%_80%] grid-cols-[auto] gap-[21px] h-auto m-8 p-2.5 rounded-[25px];
}

.ticket-header {
  @apply grid grid-cols-[9%_auto];
}

.ticket-header-title {
  @apply flex justify-between;
}

.ticket-header-name {
  @apply text-base font-semibold;
}

.ticket-header-time {
  @apply text-[medium];
}

.ticket-header-subject {
  @apply text-[x-large] font-bold;
}

.ticket-header-icon {
  @apply flex justify-center items-center;
}

.ticket-header-logo {
  @apply w-[75px] h-[75px];
  border-radius: 50px;
}

.ticket-header-details {
  @apply flex flex-col justify-center pr-[15px];
}

.ticket-header-mailsender {
  @apply flex gap-2.5 text-[large];
}

.ticket-header-mailsender span span {
  @apply text-[rgb(41, 57, 72)] font-medium;
}

.ticket-border {
  @apply bg-[rgb(29, 47, 62)] h-0.5;
}

.ticket-body {
  @apply pt-0 pb-2.5 px-2.5;
}

.ticket-body-text {
  @apply grid grid-cols-[auto];
}

.ticket-body-text-body {
  @apply h-[400px] overflow-auto mb-[26px];
}

.ticket-body-text p {
  @apply mb-2.5;
}

.ticket-body-text-footer {
  @apply mb-8;
}

.ticket-body-text-footer-attchments {
  @apply w-full grid grid-cols-[25%_25%_25%] gap-[9px];
}

.ticket-body-text-footer-attchment-card {
  @apply grid gap-2 grid-cols-[23%_auto] w-full whitespace-nowrap overflow-hidden text-ellipsis p-2.5 rounded-xl;
  background: rgb(17, 56, 91);
}

.ticket-body-text-footer-attchment-file-details {
  @apply w-[197px];
}

.ticket-body-text-footer-attchment-file-details-name {
  @apply text-ellipsis whitespace-nowrap overflow-hidden;
}

.ticket-body-text-footer-attchment-file {
  @apply w-[86%] grid text-center justify-center p-0.5;
  background: #071a2b;
}

.ticket-action {
  @apply grid grid-cols-[auto_auto_auto] gap-x-[2%] px-[2%] pb-[2%];
}

.ticket-action-btn {
  @apply flex w-full rounded-xl;
}

.approve {
  @apply bg-[rgb(123, 168, 239)];
}

.adjust {
  @apply bg-[rgb(53, 68, 81)];
}

.decline {
  @apply bg-[rgb(241, 77, 77)];
}

.ticket-action-decline {
  @apply w-full flex items-center justify-center gap-1.5 bg-transparent p-1.5 border-[none];
}

.ticket-action-adjust {
  @apply w-full flex items-center justify-center gap-1.5 bg-transparent p-1.5 border-[none];
}

.ticket-action-approve {
  @apply w-full flex items-center justify-center gap-1.5 bg-transparent p-1.5 border-[none];
}

.decline-buttons-container {
  @apply relative flex flex-col gap-2;
  /* Ensures child elements are positioned relative to this container */
}

.action-button {
  @apply w-[200px] bg-[#1e3a5f] text-[white] cursor-pointer p-2.5 rounded-[5px] border-[none];
}

.decline-popup {
  @apply absolute bg-transparent text-[white] z-10 w-full flex flex-col items-center transition-all duration-[0.2s] ease-[ease] p-2.5 rounded-[5px];
}

/* Adjust the z-index to make sure menu overlays other elements */
.decline-popup button {
  @apply bg-[rgb(17, 56, 91)] text-[white] cursor-pointer w-full mt-[5px] p-2.5 rounded-[5px] border-[none];
}

.decline-popup::before {
  @apply absolute -translate-x-2/4 border-8 border-solid border-[transparent_transparent_#2c3e50_transparent] left-2/4 -bottom-2;
}

.modal-overlay {
  @apply absolute w-full h-full flex justify-between items-center z-[1000] backdrop-blur-sm top-0;
}

.modal {
  @apply bg-[rgb(17, 56, 91)] w-[58%] text-white text-left ml-[7%] p-5 rounded-lg;
}

.modal-header {
  @apply flex justify-between items-center mb-[3px];
}

.modal-title {
  @apply text-xl flex gap-[5px];
}

.border {
  @apply mb-[1%] border-[thin] border-solid border-[#324457];
}

.modal-body-textarea {
  @apply w-full bg-[rgb(7, 26, 43)] resize-none p-3 rounded-[5px];
}

.modal-body-regenerate-btn {
  @apply w-1/5 bg-[rgb(123, 168, 239)] p-[7px] rounded-md;
}

.app-container {
  @apply relative h-screen flex flex-col justify-center items-center bg-[#1c1c1c] text-[white];
}

.content {
  @apply z-[1] text-center;
}

/* Loading Overlay */
.loading-overlay {
  @apply absolute w-[69.5%] h-3/4 flex flex-col justify-center items-center z-[2] left-[28%] top-[23%] backdrop-blur-sm;
}

/* Spinner Animation */
.spinner {
  @apply w-[50px] h-[50px] animate-spin rounded-[50%] border-t-[white] border-[6px] border-solid border-[rgba(255, 255, 255, 0.3)];
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Loading Text */
.loading-text {
  @apply text-base text-[white] text-center mt-2.5;
}

.tab-container {
  display: inline-flex;
  background-color: #1a2637;
  border-radius: 8px;
  font-family: Arial, sans-serif;
  border-radius: var(--radius-sm, 6px);
  border: 1px solid var(--Button, #7ba8ef);
  margin-bottom: 1%;
}

.tab {
  @apply text-lg cursor-pointer transition-all duration-[0.3s] ease-[ease] select-none px-3.5 rounded-md;
}

.active {
  @apply bg-blue-400 text-[white];
}

.inactive {
  @apply bg-transparent text-blue-300 hover:bg-[rgba(255, 255, 255, 0.1)];
}
</style> -->
