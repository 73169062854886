<template>
  <div
    class="bg-custom-bg bg-no-repeat bg-cover w-full h-screen flex items-center justify-center"
  >
    <div
      class="bg-[#031525] border-[3px] border-[#a7d0f8] w-[355px] h-auto p-6 rounded-[40px]"
    >
      <div
        class="w-16 h-16 p-5 border border-[#a7d0f8] rounded-2xl flex items-center justify-center m-auto"
      >
        <IconKey class="flex items-center justify-center" />
      </div>
      <h1 class="text-xl font-[600] leading-9 text-white text-center mt-7">
        Check your email
      </h1>
      <p
        class="text-white mt-[10px] mb-[15px] text-center text-base font-normal leading-6"
      >
        We sent a verification link to {{ email }}
      </p>
      <form>
        <div class="mb-4">
          <button
            @click.prevent="EnterCodeManually"
            class="text-[17px] font-medium leading-5 w-[300px] h-12 p-3 pl-4 pr-3 rounded-2xl border border-white bg-white text-black text-center placeholder:text-black hover:cursor-pointer focus:outline-0 focus:shadow-none"
          >
            Enter code manually
          </button>
        </div>
      </form>
      <p class="text-sm font-medium leading-4 text-white mt-5 mb-4 text-center">
        Didn't receive the email?
        <a
          @click="resendEmail"
          class="text-sm font-medium leading-4 text-left text-[#a7d0f8] hover:underline hover:bg-transparent"
        >
          Click to resend
        </a>
      </p>
      <div class="flex justify-center items-center gap-[2px] text-white">
        <div class="mail-code__arrow mr-2">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.8327 6.9974H1.16602M1.16602 6.9974L6.99935 12.8307M1.16602 6.9974L6.99935 1.16406"
              stroke="white"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <a
          :href="`${loginUrl}`"
          @click.prevent="backToLogin"
          class="text-sm font-medium leading-4 text-left"
        >
          Back to log in
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import IconKey from "./icons/IconKey.vue";
import AuthService from "@/Services/AuthService";

export default defineComponent({
  components: {
    IconKey,
  },
  data() {
    return {
      loginUrl: `${window.location.origin}/auth/login`,
      router: useRouter(),
      route: useRoute(),
      email: "",
    };
  },
  methods: {
    EnterCodeManually() {
      this.router.push({
        path: "/token/verify",
        query: { emailKey: this.route.query.emailKey },
      });
    },
    async resendEmail() {
      const authService = new AuthService();
      this.email = sessionStorage.getItem("userEmail");
      this.fullName = sessionStorage.getItem("fullName");
      this.userId = sessionStorage.getItem("userId");
      try {
        const data = {
          email: this.email,
          fullName: this.fullName,
          userId: this.userId,
        };
        const response = await authService.ResendVerificationEmail(data);
        this.router.push({
          path: "/user/enter-code",
          query: { emailKey: response.emailKey },
        });
      } catch (error) {
        console.log(error);
      }
    },
    backToLogin() {
      this.router.push("/auth/login");
    },
  },
  created() {
    this.email = sessionStorage.getItem("userEmail");
    console.log(this.email); // Debug to check if email is being retrieved
  },
});
</script>
