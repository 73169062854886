<template>
    <div class="icon-present">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M7.6002 6.39844C6.43324 6.39844 5.31409 6.86201 4.48893 7.68717C3.66377 8.51233 3.2002 9.63148 3.2002 10.7984V21.1984C3.2002 22.3654 3.66377 23.4845 4.48893 24.3097C5.31409 25.1349 6.43324 25.5984 7.6002 25.5984H17.6802C17.8002 25.0064 18.0498 24.4624 18.4002 23.9984H7.6002C6.85759 23.9984 6.1454 23.7034 5.6203 23.1783C5.09519 22.6532 4.8002 21.941 4.8002 21.1984V10.7984C4.8002 9.25284 6.0546 7.99844 7.6002 7.99844H24.4002C25.9458 7.99844 27.2002 9.25284 27.2002 10.7984V15.04C27.8418 15.4128 28.3906 15.928 28.8002 16.544V10.7984C28.8002 9.63148 28.3366 8.51233 27.5115 7.68717C26.6863 6.86201 25.5672 6.39844 24.4002 6.39844H7.6002ZM28.0002 19.1984C28.0002 20.0471 27.6631 20.8611 27.0629 21.4612C26.4628 22.0613 25.6489 22.3984 24.8002 22.3984C23.9515 22.3984 23.1376 22.0613 22.5375 21.4612C21.9373 20.8611 21.6002 20.0471 21.6002 19.1984C21.6002 18.3497 21.9373 17.5358 22.5375 16.9357C23.1376 16.3356 23.9515 15.9984 24.8002 15.9984C25.6489 15.9984 26.4628 16.3356 27.0629 16.9357C27.6631 17.5358 28.0002 18.3497 28.0002 19.1984ZM30.4002 26.3984C30.4002 28.3904 28.8002 30.3984 24.8002 30.3984C20.8002 30.3984 19.2002 28.3984 19.2002 26.3984C19.2002 25.7619 19.4531 25.1515 19.9031 24.7014C20.3532 24.2513 20.9637 23.9984 21.6002 23.9984H28.0002C28.6367 23.9984 29.2472 24.2513 29.6973 24.7014C30.1473 25.1515 30.4002 25.7619 30.4002 26.3984Z" fill="white" />
        </svg>
    </div>
</template>

<script setup lang="ts">

</script>

<style>
    .icon-present {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        color: rgb(177, 168, 168);
    }
</style>