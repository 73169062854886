<template>
  <div class="icon-freddy-small">
    <svg
      :width="width"
      :height="height"
      :class="borderRound ? 'rounded-2' : ''"
      viewBox="0 0 469 489"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M444.535 320.395C441.025 307.885 430.635 299.845 420.855 296.135C411.755 381.495 342.825 449.095 255.155 449.095H218.385C127.045 448.465 57.1345 381.395 48.0245 296.045C40.0545 299.075 33.3845 304.805 29.1345 312.095C25.9645 317.535 24.1445 323.845 24.1445 330.555V386.605C24.1445 440.685 68.3945 484.935 122.475 484.935H350.305C404.385 484.935 445.885 442.135 445.885 388.055L446.395 332.905C446.395 328.205 445.495 323.795 444.545 320.395H444.535Z"
        fill="url(#paint0_linear_17472_35089)"
      />
      <path
        d="M423.584 187.665C423.584 187.575 423.574 187.485 423.564 187.395H423.544C423.554 187.485 423.574 187.565 423.584 187.655V187.665Z"
        fill="url(#paint1_linear_17472_35089)"
      />
      <path
        d="M194.544 236.215C190.524 220.065 177.814 207.365 161.674 203.345C177.724 207.535 190.354 220.165 194.544 236.215Z"
        fill="url(#paint2_linear_17472_35089)"
      />
      <path
        d="M194.544 236.215C190.524 220.065 177.814 207.365 161.674 203.345C177.724 207.535 190.354 220.165 194.544 236.215Z"
        fill="url(#paint3_linear_17472_35089)"
      />
      <path
        d="M194.544 236.215C190.524 220.065 177.814 207.365 161.674 203.345C177.724 207.535 190.354 220.165 194.544 236.215Z"
        fill="url(#paint4_linear_17472_35089)"
      />
      <path
        d="M124.204 247.155C124.204 261.495 135.824 273.115 150.164 273.115C164.504 273.115 176.124 261.495 176.124 247.155C176.124 232.815 164.504 221.195 150.164 221.195C135.824 221.195 124.204 232.815 124.204 247.155ZM159.764 226.675C167.174 226.675 173.184 232.685 173.184 240.095C173.184 247.505 167.174 253.515 159.764 253.515C152.354 253.515 146.344 247.505 146.344 240.095C146.344 232.685 152.354 226.675 159.764 226.675Z"
        fill="url(#paint5_linear_17472_35089)"
      />
      <path
        d="M124.204 247.155C124.204 261.495 135.824 273.115 150.164 273.115C164.504 273.115 176.124 261.495 176.124 247.155C176.124 232.815 164.504 221.195 150.164 221.195C135.824 221.195 124.204 232.815 124.204 247.155ZM159.764 226.675C167.174 226.675 173.184 232.685 173.184 240.095C173.184 247.505 167.174 253.515 159.764 253.515C152.354 253.515 146.344 247.505 146.344 240.095C146.344 232.685 152.354 226.675 159.764 226.675Z"
        fill="url(#paint6_linear_17472_35089)"
      />
      <path
        d="M124.204 247.155C124.204 261.495 135.824 273.115 150.164 273.115C164.504 273.115 176.124 261.495 176.124 247.155C176.124 232.815 164.504 221.195 150.164 221.195C135.824 221.195 124.204 232.815 124.204 247.155ZM159.764 226.675C167.174 226.675 173.184 232.685 173.184 240.095C173.184 247.505 167.174 253.515 159.764 253.515C152.354 253.515 146.344 247.505 146.344 240.095C146.344 232.685 152.354 226.675 159.764 226.675Z"
        fill="url(#paint7_linear_17472_35089)"
      />
      <path
        d="M124.204 247.155C124.204 261.495 135.824 273.115 150.164 273.115C164.504 273.115 176.124 261.495 176.124 247.155C176.124 232.815 164.504 221.195 150.164 221.195C135.824 221.195 124.204 232.815 124.204 247.155ZM159.764 226.675C167.174 226.675 173.184 232.685 173.184 240.095C173.184 247.505 167.174 253.515 159.764 253.515C152.354 253.515 146.344 247.505 146.344 240.095C146.344 232.685 152.354 226.675 159.764 226.675Z"
        fill="url(#paint8_linear_17472_35089)"
      />
      <path
        d="M124.204 247.155C124.204 261.495 135.824 273.115 150.164 273.115C164.504 273.115 176.124 261.495 176.124 247.155C176.124 232.815 164.504 221.195 150.164 221.195C135.824 221.195 124.204 232.815 124.204 247.155ZM159.764 226.675C167.174 226.675 173.184 232.685 173.184 240.095C173.184 247.505 167.174 253.515 159.764 253.515C152.354 253.515 146.344 247.505 146.344 240.095C146.344 232.685 152.354 226.675 159.764 226.675Z"
        fill="#A8BDE1"
      />
      <path
        d="M166.831 251.497C173.135 247.6 175.087 239.331 171.19 233.026C167.294 226.721 159.024 224.77 152.719 228.666C146.415 232.563 144.463 240.833 148.36 247.138C152.256 253.442 160.526 255.394 166.831 251.497Z"
        fill="url(#paint9_linear_17472_35089)"
      />
      <path
        d="M166.831 251.497C173.135 247.6 175.087 239.331 171.19 233.026C167.294 226.721 159.024 224.77 152.719 228.666C146.415 232.563 144.463 240.833 148.36 247.138C152.256 253.442 160.526 255.394 166.831 251.497Z"
        fill="url(#paint10_linear_17472_35089)"
      />
      <path
        d="M166.831 251.497C173.135 247.6 175.087 239.331 171.19 233.026C167.294 226.721 159.024 224.77 152.719 228.666C146.415 232.563 144.463 240.833 148.36 247.138C152.256 253.442 160.526 255.394 166.831 251.497Z"
        fill="url(#paint11_linear_17472_35089)"
      />
      <path
        d="M166.831 251.497C173.135 247.6 175.087 239.331 171.19 233.026C167.294 226.721 159.024 224.77 152.719 228.666C146.415 232.563 144.463 240.833 148.36 247.138C152.256 253.442 160.526 255.394 166.831 251.497Z"
        fill="url(#paint12_linear_17472_35089)"
      />
      <path
        d="M166.831 251.497C173.135 247.6 175.087 239.331 171.19 233.026C167.294 226.721 159.024 224.77 152.719 228.666C146.415 232.563 144.463 240.833 148.36 247.138C152.256 253.442 160.526 255.394 166.831 251.497Z"
        fill="url(#paint13_linear_17472_35089)"
      />
      <path
        d="M166.831 251.497C173.135 247.6 175.087 239.331 171.19 233.026C167.294 226.721 159.024 224.77 152.719 228.666C146.415 232.563 144.463 240.833 148.36 247.138C152.256 253.442 160.526 255.394 166.831 251.497Z"
        fill="#F7F5F9"
      />
      <path
        d="M362.834 236.215C358.814 220.065 346.104 207.365 329.964 203.345C346.014 207.535 358.644 220.165 362.834 236.215Z"
        fill="url(#paint14_linear_17472_35089)"
      />
      <path
        d="M362.834 236.215C358.814 220.065 346.104 207.365 329.964 203.345C346.014 207.535 358.644 220.165 362.834 236.215Z"
        fill="url(#paint15_linear_17472_35089)"
      />
      <path
        d="M362.834 236.215C358.814 220.065 346.104 207.365 329.964 203.345C346.014 207.535 358.644 220.165 362.834 236.215Z"
        fill="url(#paint16_linear_17472_35089)"
      />
      <path
        d="M328.054 253.515C335.466 253.515 341.474 247.507 341.474 240.095C341.474 232.683 335.466 226.675 328.054 226.675C320.643 226.675 314.634 232.683 314.634 240.095C314.634 247.507 320.643 253.515 328.054 253.515Z"
        fill="url(#paint17_linear_17472_35089)"
      />
      <path
        d="M328.054 253.515C335.466 253.515 341.474 247.507 341.474 240.095C341.474 232.683 335.466 226.675 328.054 226.675C320.643 226.675 314.634 232.683 314.634 240.095C314.634 247.507 320.643 253.515 328.054 253.515Z"
        fill="url(#paint18_linear_17472_35089)"
      />
      <path
        d="M328.054 253.515C335.466 253.515 341.474 247.507 341.474 240.095C341.474 232.683 335.466 226.675 328.054 226.675C320.643 226.675 314.634 232.683 314.634 240.095C314.634 247.507 320.643 253.515 328.054 253.515Z"
        fill="url(#paint19_linear_17472_35089)"
      />
      <path
        d="M328.054 253.515C335.466 253.515 341.474 247.507 341.474 240.095C341.474 232.683 335.466 226.675 328.054 226.675C320.643 226.675 314.634 232.683 314.634 240.095C314.634 247.507 320.643 253.515 328.054 253.515Z"
        fill="url(#paint20_linear_17472_35089)"
      />
      <path
        d="M328.054 253.515C335.466 253.515 341.474 247.507 341.474 240.095C341.474 232.683 335.466 226.675 328.054 226.675C320.643 226.675 314.634 232.683 314.634 240.095C314.634 247.507 320.643 253.515 328.054 253.515Z"
        fill="url(#paint21_linear_17472_35089)"
      />
      <path
        d="M444.234 320.555C431.704 408.245 355.824 476.225 264.814 476.225H207.334C114.564 476.225 37.5042 405.575 27.2542 315.435C24.0842 320.875 22.2642 327.185 22.2642 333.895V389.945C22.2642 444.025 66.5142 488.275 120.594 488.275H348.424C402.504 488.275 446.754 444.025 446.754 389.945V333.895C446.754 329.195 445.854 324.705 444.234 320.555Z"
        fill="url(#paint22_linear_17472_35089)"
      />
      <path
        opacity="0.47"
        d="M207.334 476.225H264.814C355.824 476.225 431.704 408.245 444.234 320.555C440.424 310.825 432.594 303.065 422.814 299.355C413.714 384.715 340.954 451.785 253.274 451.785H215.674C128.004 451.785 55.2544 384.735 46.1444 299.375C38.1744 302.405 31.5044 308.135 27.2544 315.425C37.4944 405.575 114.554 476.215 207.334 476.215V476.225Z"
        fill="url(#paint23_linear_17472_35089)"
      />
      <path
        d="M207.334 476.225H264.814C355.824 476.225 431.704 408.245 444.234 320.555C440.424 310.825 432.594 303.065 422.814 299.355C413.714 384.715 340.954 451.785 253.274 451.785H215.674C128.004 451.785 55.2544 384.735 46.1444 299.375C38.1744 302.405 31.5044 308.135 27.2544 315.425C37.4944 405.575 114.554 476.215 207.334 476.215V476.225Z"
        fill="url(#paint24_linear_17472_35089)"
      />
      <path
        d="M233.764 373.605C234.064 373.605 234.374 373.605 234.674 373.605C264.104 373.605 281.884 355.215 282.624 354.425C286.044 350.825 285.884 345.135 282.284 341.715C278.684 338.295 272.994 338.455 269.574 342.055C269.444 342.195 256.104 355.625 234.654 355.625C234.534 355.625 234.404 355.625 234.274 355.625H234.144C212.514 355.795 199.414 342.635 198.814 342.015C195.394 338.445 189.734 338.305 186.144 341.715C182.544 345.135 182.394 350.825 185.804 354.425C186.554 355.215 204.324 373.605 233.754 373.605H233.764Z"
        fill="#0C132C"
      />
      <path
        d="M233.764 373.605C234.064 373.605 234.374 373.605 234.674 373.605C264.104 373.605 281.884 355.215 282.624 354.425C286.044 350.825 285.884 345.135 282.284 341.715C278.684 338.295 272.994 338.455 269.574 342.055C269.444 342.195 256.104 355.625 234.654 355.625C234.534 355.625 234.404 355.625 234.274 355.625H234.144C212.514 355.795 199.414 342.635 198.814 342.015C195.394 338.445 189.734 338.305 186.144 341.715C182.544 345.135 182.394 350.825 185.804 354.425C186.554 355.215 204.324 373.605 233.754 373.605H233.764Z"
        fill="#0B132F"
      />
      <path
        d="M319.144 170.295C308.604 167.815 284.884 167.785 272.084 164.835C231.784 155.535 132.284 113.925 125.634 108.555C93.0741 124.555 72.0141 155.805 65.7741 190.065C64.5741 196.635 63.9541 203.395 63.9541 210.285V254.235C63.9541 268.985 65.8541 283.305 69.4141 296.985C88.5141 370.335 155.504 424.895 234.614 424.895C313.734 424.895 380.714 370.335 399.814 296.985C403.374 283.315 405.274 268.985 405.274 254.235V211.425C403.324 209.925 396.744 188.555 319.144 170.295ZM150.164 293.895C124.744 293.895 104.134 273.285 104.134 247.865C104.134 222.445 124.744 201.835 150.164 201.835C154.194 201.835 158.104 202.355 161.824 203.325C177.974 207.345 190.674 220.055 194.694 236.195C195.664 239.915 196.184 243.825 196.184 247.855C196.184 273.275 175.574 293.885 150.154 293.885L150.164 293.895ZM318.444 293.895C293.024 293.895 272.414 273.285 272.414 247.865C272.414 222.445 293.024 201.835 318.444 201.835C322.474 201.835 326.384 202.355 330.104 203.325C346.254 207.345 358.954 220.055 362.974 236.195C363.944 239.915 364.464 243.825 364.464 247.855C364.464 273.275 343.854 293.885 318.434 293.885L318.444 293.895Z"
        fill="url(#paint25_linear_17472_35089)"
      />
      <path
        d="M43.4445 187.015C43.4445 187.015 43.4545 186.845 43.4845 186.545C43.4545 186.535 43.4245 186.515 43.3945 186.505C43.4145 186.675 43.4245 186.845 43.4345 187.015H43.4445Z"
        fill="url(#paint26_linear_17472_35089)"
      />
      <path
        opacity="0.2"
        d="M135.124 307.165C110.274 291.355 89.9544 267.575 97.1044 235.915C103.954 205.565 127.834 197.485 140.164 195.335C143.704 194.715 145.554 190.795 143.744 187.695C135.274 173.215 115.224 140.995 105.574 142.745C98.7244 143.995 86.3844 159.835 78.1944 189.235C78.0644 189.685 78.0044 190.165 78.0044 190.635V247.835C78.0044 302.905 107.114 351.505 150.694 379.035C151.404 379.485 152.234 379.745 153.074 379.815C153.094 379.815 153.114 379.815 153.124 379.815C154.504 379.925 155.864 379.445 156.904 378.535C188.164 351.275 159.544 322.725 135.114 307.175L135.124 307.165Z"
        fill="url(#paint27_linear_17472_35089)"
      />
      <path
        opacity="0.2"
        d="M341.204 308.425C366.054 292.615 376.214 275.105 374.524 236.145C372.834 197.185 323.704 196.615 323.704 196.615C323.704 196.615 353.634 140.705 366.054 142.965C372.954 144.225 385.444 160.305 393.624 190.145L389.104 250.815C383.454 319.705 346.944 361.775 320.184 378.015C318.274 379.355 316.514 380.555 316.514 380.555C282.634 352.885 316.364 324.235 341.214 308.425H341.204Z"
        fill="url(#paint28_linear_17472_35089)"
      />
      <path
        d="M292.174 116.725C316.984 116.725 340.394 126.475 358.094 144.165C375.794 161.865 385.534 185.275 385.534 210.085V254.035C385.534 294.085 369.814 331.875 341.264 360.425C312.714 388.975 274.934 404.695 234.874 404.695C194.814 404.695 157.034 388.975 128.484 360.425C99.9343 331.875 84.2144 294.095 84.2144 254.035V210.085C84.2144 185.275 93.9644 161.865 111.654 144.165C129.354 126.465 152.764 116.725 177.574 116.725H292.164M292.164 96.725H177.574C115.224 96.725 64.2144 147.735 64.2144 210.085V254.035C64.2144 347.895 141.014 424.695 234.874 424.695C328.734 424.695 405.534 347.895 405.534 254.035V210.085C405.534 147.735 354.524 96.725 292.174 96.725H292.164Z"
        fill="url(#paint29_linear_17472_35089)"
      />
      <path
        d="M235.424 376.465C235.114 376.465 234.804 376.465 234.504 376.465C204.504 376.465 185.864 355.345 186.204 354.185C187.804 348.815 186.494 347.935 190.174 344.045C193.834 340.155 195.884 342.145 199.364 346.215C199.994 346.945 212.694 355.955 234.504 355.955C234.664 355.955 234.814 355.955 234.964 355.955C235.114 355.955 235.264 355.955 235.414 355.955C257.274 355.955 273.784 344.965 273.914 344.805C277.394 340.695 276.884 338.865 280.554 342.765C284.224 346.665 285.624 351.825 282.144 355.935C281.384 356.835 265.414 376.465 235.424 376.475V376.465Z"
        fill="#F7F5F9"
      />
      <path
        d="M235.235 373.605C234.935 373.605 234.625 373.605 234.325 373.605C204.895 373.605 187.115 355.215 186.375 354.425C182.955 350.825 183.115 345.135 186.715 341.715C190.305 338.305 195.975 338.455 199.395 342.025C200.015 342.665 212.935 355.625 234.325 355.625C234.475 355.625 234.625 355.625 234.785 355.625C234.935 355.625 235.085 355.625 235.225 355.625C256.675 355.625 270.015 342.195 270.145 342.055C273.555 338.455 279.245 338.295 282.855 341.715C286.455 345.135 286.605 350.825 283.195 354.425C282.445 355.215 264.665 373.605 235.245 373.605H235.235Z"
        fill="#0C132C"
      />
      <path
        d="M195.894 247.155C195.894 272.095 175.674 292.315 150.734 292.315C125.794 292.315 105.574 272.095 105.574 247.155C105.574 222.215 125.794 201.995 150.734 201.995C154.504 201.995 158.174 202.465 161.674 203.335C157.954 202.365 154.044 201.845 150.014 201.845C124.594 201.845 103.984 222.455 103.984 247.875C103.984 273.295 124.594 293.905 150.014 293.905C175.434 293.905 196.044 273.295 196.044 247.875C196.044 243.845 195.524 239.935 194.554 236.215C195.424 239.715 195.894 243.385 195.894 247.155Z"
        fill="url(#paint30_linear_17472_35089)"
      />
      <path
        d="M195.894 247.155C195.894 272.095 175.674 292.315 150.734 292.315C125.794 292.315 105.574 272.095 105.574 247.155C105.574 222.215 125.794 201.995 150.734 201.995C154.504 201.995 158.174 202.465 161.674 203.335C157.954 202.365 154.044 201.845 150.014 201.845C124.594 201.845 103.984 222.455 103.984 247.875C103.984 273.295 124.594 293.905 150.014 293.905C175.434 293.905 196.044 273.295 196.044 247.875C196.044 243.845 195.524 239.935 194.554 236.215C195.424 239.715 195.894 243.385 195.894 247.155Z"
        fill="url(#paint31_linear_17472_35089)"
      />
      <path
        d="M195.894 247.155C195.894 272.095 175.674 292.315 150.734 292.315C125.794 292.315 105.574 272.095 105.574 247.155C105.574 222.215 125.794 201.995 150.734 201.995C154.504 201.995 158.174 202.465 161.674 203.335C157.954 202.365 154.044 201.845 150.014 201.845C124.594 201.845 103.984 222.455 103.984 247.875C103.984 273.295 124.594 293.905 150.014 293.905C175.434 293.905 196.044 273.295 196.044 247.875C196.044 243.845 195.524 239.935 194.554 236.215C195.424 239.715 195.894 243.385 195.894 247.155Z"
        fill="#F7F5F9"
      />
      <path
        d="M105.574 247.155C105.574 272.095 125.794 292.315 150.734 292.315C175.674 292.315 195.894 272.095 195.894 247.155C195.894 243.385 195.424 239.715 194.554 236.215C190.364 220.165 177.734 207.535 161.684 203.345C158.184 202.475 154.514 202.005 150.744 202.005C125.804 202.005 105.584 222.225 105.584 247.165L105.574 247.155ZM176.124 247.155C176.124 261.495 164.504 273.115 150.164 273.115C135.824 273.115 124.204 261.495 124.204 247.155C124.204 232.815 135.824 221.195 150.164 221.195C164.504 221.195 176.124 232.815 176.124 247.155Z"
        fill="url(#paint32_linear_17472_35089)"
      />
      <path
        d="M105.574 247.155C105.574 272.095 125.794 292.315 150.734 292.315C175.674 292.315 195.894 272.095 195.894 247.155C195.894 243.385 195.424 239.715 194.554 236.215C190.364 220.165 177.734 207.535 161.684 203.345C158.184 202.475 154.514 202.005 150.744 202.005C125.804 202.005 105.584 222.225 105.584 247.165L105.574 247.155ZM176.124 247.155C176.124 261.495 164.504 273.115 150.164 273.115C135.824 273.115 124.204 261.495 124.204 247.155C124.204 232.815 135.824 221.195 150.164 221.195C164.504 221.195 176.124 232.815 176.124 247.155Z"
        fill="url(#paint33_linear_17472_35089)"
      />
      <path
        d="M105.574 247.155C105.574 272.095 125.794 292.315 150.734 292.315C175.674 292.315 195.894 272.095 195.894 247.155C195.894 243.385 195.424 239.715 194.554 236.215C190.364 220.165 177.734 207.535 161.684 203.345C158.184 202.475 154.514 202.005 150.744 202.005C125.804 202.005 105.584 222.225 105.584 247.165L105.574 247.155ZM176.124 247.155C176.124 261.495 164.504 273.115 150.164 273.115C135.824 273.115 124.204 261.495 124.204 247.155C124.204 232.815 135.824 221.195 150.164 221.195C164.504 221.195 176.124 232.815 176.124 247.155Z"
        fill="url(#paint34_linear_17472_35089)"
      />
      <path
        d="M105.574 247.155C105.574 272.095 125.794 292.315 150.734 292.315C175.674 292.315 195.894 272.095 195.894 247.155C195.894 243.385 195.424 239.715 194.554 236.215C190.364 220.165 177.734 207.535 161.684 203.345C158.184 202.475 154.514 202.005 150.744 202.005C125.804 202.005 105.584 222.225 105.584 247.165L105.574 247.155ZM176.124 247.155C176.124 261.495 164.504 273.115 150.164 273.115C135.824 273.115 124.204 261.495 124.204 247.155C124.204 232.815 135.824 221.195 150.164 221.195C164.504 221.195 176.124 232.815 176.124 247.155Z"
        fill="#091334"
      />
      <path
        d="M364.174 247.155C364.174 272.095 343.954 292.315 319.014 292.315C294.074 292.315 273.854 272.095 273.854 247.155C273.854 222.215 294.074 201.995 319.014 201.995C322.784 201.995 326.454 202.465 329.954 203.335C326.234 202.365 322.324 201.845 318.294 201.845C292.874 201.845 272.264 222.455 272.264 247.875C272.264 273.295 292.874 293.905 318.294 293.905C343.714 293.905 364.324 273.295 364.324 247.875C364.324 243.845 363.804 239.935 362.834 236.215C363.704 239.715 364.174 243.385 364.174 247.155Z"
        fill="url(#paint35_linear_17472_35089)"
      />
      <path
        d="M364.174 247.155C364.174 272.095 343.954 292.315 319.014 292.315C294.074 292.315 273.854 272.095 273.854 247.155C273.854 222.215 294.074 201.995 319.014 201.995C322.784 201.995 326.454 202.465 329.954 203.335C326.234 202.365 322.324 201.845 318.294 201.845C292.874 201.845 272.264 222.455 272.264 247.875C272.264 273.295 292.874 293.905 318.294 293.905C343.714 293.905 364.324 273.295 364.324 247.875C364.324 243.845 363.804 239.935 362.834 236.215C363.704 239.715 364.174 243.385 364.174 247.155Z"
        fill="url(#paint36_linear_17472_35089)"
      />
      <path
        d="M364.174 247.155C364.174 272.095 343.954 292.315 319.014 292.315C294.074 292.315 273.854 272.095 273.854 247.155C273.854 222.215 294.074 201.995 319.014 201.995C322.784 201.995 326.454 202.465 329.954 203.335C326.234 202.365 322.324 201.845 318.294 201.845C292.874 201.845 272.264 222.455 272.264 247.875C272.264 273.295 292.874 293.905 318.294 293.905C343.714 293.905 364.324 273.295 364.324 247.875C364.324 243.845 363.804 239.935 362.834 236.215C363.704 239.715 364.174 243.385 364.174 247.155Z"
        fill="#F7F5F9"
      />
      <path
        d="M273.854 247.155C273.854 272.095 294.074 292.315 319.014 292.315C343.954 292.315 364.174 272.095 364.174 247.155C364.174 243.385 363.705 239.715 362.835 236.215C358.645 220.165 346.014 207.535 329.964 203.345C326.464 202.475 322.794 202.005 319.024 202.005C294.084 202.005 273.865 222.225 273.865 247.165L273.854 247.155ZM344.404 247.155C344.404 261.495 332.784 273.115 318.444 273.115C304.104 273.115 292.484 261.495 292.484 247.155C292.484 232.815 304.104 221.195 318.444 221.195C332.784 221.195 344.404 232.815 344.404 247.155Z"
        fill="url(#paint37_linear_17472_35089)"
      />
      <path
        d="M273.854 247.155C273.854 272.095 294.074 292.315 319.014 292.315C343.954 292.315 364.174 272.095 364.174 247.155C364.174 243.385 363.705 239.715 362.835 236.215C358.645 220.165 346.014 207.535 329.964 203.345C326.464 202.475 322.794 202.005 319.024 202.005C294.084 202.005 273.865 222.225 273.865 247.165L273.854 247.155ZM344.404 247.155C344.404 261.495 332.784 273.115 318.444 273.115C304.104 273.115 292.484 261.495 292.484 247.155C292.484 232.815 304.104 221.195 318.444 221.195C332.784 221.195 344.404 232.815 344.404 247.155Z"
        fill="url(#paint38_linear_17472_35089)"
      />
      <path
        d="M273.854 247.155C273.854 272.095 294.074 292.315 319.014 292.315C343.954 292.315 364.174 272.095 364.174 247.155C364.174 243.385 363.705 239.715 362.835 236.215C358.645 220.165 346.014 207.535 329.964 203.345C326.464 202.475 322.794 202.005 319.024 202.005C294.084 202.005 273.865 222.225 273.865 247.165L273.854 247.155ZM344.404 247.155C344.404 261.495 332.784 273.115 318.444 273.115C304.104 273.115 292.484 261.495 292.484 247.155C292.484 232.815 304.104 221.195 318.444 221.195C332.784 221.195 344.404 232.815 344.404 247.155Z"
        fill="url(#paint39_linear_17472_35089)"
      />
      <path
        d="M273.854 247.155C273.854 272.095 294.074 292.315 319.014 292.315C343.954 292.315 364.174 272.095 364.174 247.155C364.174 243.385 363.705 239.715 362.835 236.215C358.645 220.165 346.014 207.535 329.964 203.345C326.464 202.475 322.794 202.005 319.024 202.005C294.084 202.005 273.865 222.225 273.865 247.165L273.854 247.155ZM344.404 247.155C344.404 261.495 332.784 273.115 318.444 273.115C304.104 273.115 292.484 261.495 292.484 247.155C292.484 232.815 304.104 221.195 318.444 221.195C332.784 221.195 344.404 232.815 344.404 247.155Z"
        fill="#0B1332"
      />
      <path
        d="M292.494 247.155C292.494 261.495 304.114 273.115 318.454 273.115C332.794 273.115 344.414 261.495 344.414 247.155C344.414 232.815 332.794 221.195 318.454 221.195C304.114 221.195 292.494 232.815 292.494 247.155ZM328.054 226.675C335.464 226.675 341.474 232.685 341.474 240.095C341.474 247.505 335.464 253.515 328.054 253.515C320.644 253.515 314.634 247.505 314.634 240.095C314.634 232.685 320.644 226.675 328.054 226.675Z"
        fill="url(#paint40_linear_17472_35089)"
      />
      <path
        d="M292.494 247.155C292.494 261.495 304.114 273.115 318.454 273.115C332.794 273.115 344.414 261.495 344.414 247.155C344.414 232.815 332.794 221.195 318.454 221.195C304.114 221.195 292.494 232.815 292.494 247.155ZM328.054 226.675C335.464 226.675 341.474 232.685 341.474 240.095C341.474 247.505 335.464 253.515 328.054 253.515C320.644 253.515 314.634 247.505 314.634 240.095C314.634 232.685 320.644 226.675 328.054 226.675Z"
        fill="url(#paint41_linear_17472_35089)"
      />
      <path
        d="M292.494 247.155C292.494 261.495 304.114 273.115 318.454 273.115C332.794 273.115 344.414 261.495 344.414 247.155C344.414 232.815 332.794 221.195 318.454 221.195C304.114 221.195 292.494 232.815 292.494 247.155ZM328.054 226.675C335.464 226.675 341.474 232.685 341.474 240.095C341.474 247.505 335.464 253.515 328.054 253.515C320.644 253.515 314.634 247.505 314.634 240.095C314.634 232.685 320.644 226.675 328.054 226.675Z"
        fill="url(#paint42_linear_17472_35089)"
      />
      <path
        d="M292.494 247.155C292.494 261.495 304.114 273.115 318.454 273.115C332.794 273.115 344.414 261.495 344.414 247.155C344.414 232.815 332.794 221.195 318.454 221.195C304.114 221.195 292.494 232.815 292.494 247.155ZM328.054 226.675C335.464 226.675 341.474 232.685 341.474 240.095C341.474 247.505 335.464 253.515 328.054 253.515C320.644 253.515 314.634 247.505 314.634 240.095C314.634 232.685 320.644 226.675 328.054 226.675Z"
        fill="url(#paint43_linear_17472_35089)"
      />
      <path
        d="M292.494 247.155C292.494 261.495 304.114 273.115 318.454 273.115C332.794 273.115 344.414 261.495 344.414 247.155C344.414 232.815 332.794 221.195 318.454 221.195C304.114 221.195 292.494 232.815 292.494 247.155ZM328.054 226.675C335.464 226.675 341.474 232.685 341.474 240.095C341.474 247.505 335.464 253.515 328.054 253.515C320.644 253.515 314.634 247.505 314.634 240.095C314.634 232.685 320.644 226.675 328.054 226.675Z"
        fill="#A8BDE1"
      />
      <path
        d="M316.484 221.975C331.574 221.975 343.354 231.445 343.804 249.295C343.854 251.485 343.544 253.605 343.054 255.645C343.864 253.105 344.194 250.405 344.314 247.595C344.954 233.045 332.314 220.595 318.014 221.295C314.254 221.475 310.674 222.095 307.434 223.515C310.264 222.525 313.304 221.975 316.484 221.975Z"
        fill="#F7F5F9"
      />
      <path
        d="M148.034 222.195C163.124 222.195 174.594 231.955 175.354 249.515C175.444 251.695 175.094 253.825 174.604 255.865C175.414 253.325 175.744 250.625 175.864 247.815C176.514 232.465 163.864 220.815 149.564 221.515C145.804 221.695 142.224 222.315 138.984 223.735C141.814 222.745 144.854 222.195 148.034 222.195Z"
        fill="#F7F5F9"
      />
      <path
        d="M326.585 256.935C335.25 256.935 342.275 249.91 342.275 241.245C342.275 232.58 335.25 225.555 326.585 225.555C317.919 225.555 310.895 232.58 310.895 241.245C310.895 249.91 317.919 256.935 326.585 256.935Z"
        fill="#F7F5F9"
      />
      <path
        d="M157.954 257.215C166.619 257.215 173.644 250.19 173.644 241.525C173.644 232.86 166.619 225.835 157.954 225.835C149.289 225.835 142.264 232.86 142.264 241.525C142.264 250.19 149.289 257.215 157.954 257.215Z"
        fill="#F7F5F9"
      />
      <g opacity="0.2">
        <path
          d="M106.775 25.465C106.065 24.315 105.375 23.145 104.705 21.975C105.385 23.145 106.075 24.315 106.775 25.465Z"
          fill="url(#paint44_linear_17472_35089)"
        />
        <path
          d="M109.205 29.315C108.835 28.745 108.475 28.175 108.115 27.595C108.475 28.165 108.845 28.745 109.205 29.315Z"
          fill="url(#paint45_linear_17472_35089)"
        />
        <path
          d="M134.214 61.355C134.654 61.845 135.104 62.335 135.544 62.835C135.354 62.625 135.154 62.415 134.964 62.205C134.714 61.925 134.464 61.645 134.214 61.355Z"
          fill="url(#paint46_linear_17472_35089)"
        />
        <path
          d="M131.354 58.125C131.944 58.805 132.544 59.485 133.144 60.155C132.544 59.485 131.954 58.805 131.354 58.125Z"
          fill="url(#paint47_linear_17472_35089)"
        />
        <path
          d="M404.695 212.325C404.085 205.545 404.135 206.485 402.915 198.725C398.065 166.135 382.255 141.815 364.245 124.125C348.455 108.605 330.975 98.185 317.755 91.755C316.055 90.925 314.365 90.155 312.675 89.405C289.525 79.135 267.465 75.925 247.845 73.065C232.725 70.865 218.445 68.785 205.235 63.905C167.255 49.855 154.805 26.655 150.855 15.175L143.805 14.015H123.235C115.815 14.015 108.595 14.865 101.645 16.475C95.5746 17.885 89.7146 19.885 84.1346 22.385C47.6646 36.795 21.6846 72.435 21.6846 113.875V163.365C21.6846 178.025 30.3546 190.765 42.8146 196.705C42.8446 196.715 42.8746 196.735 42.9046 196.745C43.5246 197.035 44.1546 197.315 44.8046 197.575C47.1746 157.435 70.6746 122.745 104.265 104.535C107.885 108.085 114.065 113.845 122.725 120.845C151.195 143.855 187.385 155.225 221.875 165.825C253.735 175.625 286.305 182.835 318.575 191.135C348.525 198.845 380.195 206.265 405.205 225.445L405.015 218.995C405.075 219.035 404.685 212.315 404.685 212.315L404.695 212.325Z"
          fill="url(#paint48_linear_17472_35089)"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M404.924 211.335C404.724 209.085 404.594 207.685 404.464 206.535C400.794 204.505 397.024 202.635 393.194 200.895C390.804 199.925 388.404 198.985 386.004 198.055C386.004 198.065 386.004 198.085 385.994 198.095C386.904 203.185 387.754 208.305 388.054 213.425C394.114 216.645 399.944 220.295 405.454 224.515L405.264 218.045C405.324 218.085 404.934 211.335 404.934 211.335H404.924Z"
          fill="url(#paint49_linear_17472_35089)"
        />
        <path
          d="M125.904 107.585C125.784 107.445 125.664 107.295 125.544 107.155C122.584 109.955 119.104 112.315 115.344 114.135C117.304 115.815 119.444 117.595 121.754 119.465C124.874 121.985 128.104 124.365 131.394 126.625C135.544 124.495 140.064 123.035 144.624 122.355C137.734 118.635 131.364 113.965 125.904 107.585Z"
          fill="url(#paint50_linear_17472_35089)"
        />
      </g>
      <path
        d="M43.8043 206.525C43.8043 203.155 40.7943 200.565 37.4643 201.095C16.4443 204.445 0.384277 222.645 0.384277 244.605C0.384277 266.565 16.4543 284.765 37.4643 288.115C40.7943 288.645 43.7943 286.055 43.7943 282.685V206.535L43.8043 206.525Z"
        fill="url(#paint51_linear_17472_35089)"
      />
      <path
        d="M43.8043 226.235C43.8043 223.685 41.5243 221.715 38.9943 222.115C23.0443 224.655 10.8643 238.465 10.8643 255.125C10.8643 271.785 23.0543 285.595 38.9943 288.135C41.5143 288.535 43.8043 286.575 43.8043 284.015V226.245V226.235Z"
        fill="#2B70B8"
      />
      <path
        d="M425.264 206.525C425.264 203.155 428.274 200.565 431.604 201.095C452.624 204.445 468.684 222.645 468.684 244.605C468.684 266.565 452.614 284.765 431.604 288.115C428.274 288.645 425.274 286.055 425.274 282.685V206.535L425.264 206.525Z"
        fill="url(#paint52_linear_17472_35089)"
      />
      <path
        d="M425.264 226.235C425.264 223.685 427.544 221.715 430.074 222.115C446.024 224.655 458.204 238.465 458.204 255.125C458.204 271.785 446.014 285.595 430.074 288.135C427.554 288.535 425.264 286.575 425.264 284.015V226.245V226.235Z"
        fill="#2B70B8"
      />
      <path
        d="M347.075 0.625011H168.415C169.245 2.19501 170.295 3.91501 171.585 5.73501C171.735 5.94501 171.875 6.14501 172.025 6.36501C179.785 16.945 192.575 25.715 209.015 31.725C220.415 35.895 233.225 37.735 248.065 39.875C270.775 43.145 296.505 46.855 324.545 60.335C342.815 69.125 368.365 84.595 388.985 109.145C394.525 115.745 399.715 122.995 404.305 130.955C412.805 145.685 423.205 152.225 426.135 172.065C429.315 165.935 426.915 177.955 426.915 177.955C440.465 172.475 445.385 163.875 445.385 148.425V98.935C445.385 44.855 401.135 0.605011 347.055 0.605011L347.075 0.625011Z"
        fill="url(#paint53_linear_17472_35089)"
      />
      <path
        d="M348.095 2.66502H169.435C170.265 4.23502 171.315 5.95502 172.605 7.77502C172.755 7.98502 172.895 8.18502 173.045 8.40502C180.805 18.985 193.595 27.755 210.035 33.765C221.435 37.935 234.245 39.775 249.085 41.915C271.795 45.185 297.525 48.895 325.565 62.375C343.835 71.165 369.385 86.635 390.005 111.185C395.545 117.785 400.735 125.035 405.325 132.995C413.825 147.725 420.285 164.865 423.215 184.715H423.235C436.785 179.235 446.415 165.935 446.415 150.485V100.995C446.415 46.915 402.165 2.66502 348.085 2.66502H348.095Z"
        fill="url(#paint54_linear_17472_35089)"
      />
      <path
        d="M106.774 11.805C106.064 10.655 105.374 9.48502 104.704 8.31502C105.384 9.48502 106.074 10.655 106.774 11.805Z"
        fill="white"
      />
      <path
        d="M109.204 15.665C108.834 15.095 108.474 14.525 108.114 13.945C108.474 14.515 108.844 15.095 109.204 15.665Z"
        fill="white"
      />
      <path
        d="M134.214 47.695C134.654 48.185 135.104 48.675 135.544 49.175C135.354 48.965 135.154 48.755 134.964 48.545C134.714 48.265 134.464 47.985 134.214 47.695Z"
        fill="white"
      />
      <path
        d="M131.354 44.475C131.944 45.155 132.544 45.835 133.144 46.505C132.544 45.835 131.954 45.155 131.354 44.475Z"
        fill="white"
      />
      <path
        d="M404.414 196.555C403.804 189.855 403.634 190.175 402.064 182.555C395.474 150.635 381.384 127.695 363.384 110.205C347.594 94.865 331.054 84.785 317.834 78.425C316.134 77.605 313.534 76.605 311.854 75.865C288.704 65.715 267.484 63.205 247.864 60.375C232.744 58.195 218.464 56.085 205.254 51.255C167.274 37.365 154.824 11.855 150.874 0.515018H143.824L123.254 0.50502C115.834 0.50502 108.614 1.34502 101.664 2.93502C95.5941 4.32502 89.7341 6.30502 84.1541 8.78502C47.6841 23.035 21.7041 58.275 21.7041 99.235V148.165C21.7041 162.665 30.3741 175.245 42.8341 181.125C42.8641 181.135 42.8941 181.155 42.9241 181.165C43.5441 181.455 44.1741 181.725 44.8241 181.985C47.1941 142.305 70.6941 108.005 104.284 89.995C107.904 93.505 114.084 99.195 122.744 106.115C151.214 128.865 187.404 140.105 221.894 150.585C253.754 160.275 286.324 167.405 318.594 175.615C348.544 183.235 380.214 190.575 405.224 209.545L405.034 203.175C405.094 203.215 404.424 196.565 404.424 196.565L404.414 196.555Z"
        fill="url(#paint55_linear_17472_35089)"
      />
      <path
        d="M404.744 198.935C404.134 192.235 404.014 192.035 402.434 184.415C395.844 152.495 381.374 129.965 363.374 112.475C347.584 97.135 331.044 87.055 317.824 80.695C316.124 79.875 313.524 78.875 311.844 78.135C288.694 67.985 267.474 65.475 247.854 62.645C232.734 60.465 218.454 58.355 205.244 53.525C167.264 39.635 154.814 14.125 150.864 2.78502H143.814L123.244 2.77502C115.824 2.77502 108.604 3.61502 101.654 5.20502C95.5843 6.59502 89.7243 8.57502 84.1443 11.055C47.6743 25.305 21.6943 60.545 21.6943 101.505V150.435C21.6943 164.935 30.3643 177.515 42.8243 183.395C42.8543 183.405 42.8843 183.425 42.9143 183.435C43.5343 183.725 44.1643 183.995 44.8143 184.255C47.1843 144.575 70.6843 110.275 104.274 92.265C107.894 95.775 114.074 101.465 122.734 108.385C151.204 131.135 187.394 142.375 221.884 152.855C253.744 162.545 286.314 169.675 318.584 177.885C348.534 185.505 380.204 192.845 405.214 211.815L405.024 205.445C405.084 205.485 404.744 198.945 404.744 198.945V198.935Z"
        fill="url(#paint56_linear_17472_35089)"
      />
      <path
        d="M387.095 199.285C387.095 199.285 371.165 101.805 248.395 84.265C248.395 84.265 327.865 109.625 340.795 183.475C340.795 183.475 387.105 198.695 387.105 199.285H387.095Z"
        fill="url(#paint57_linear_17472_35089)"
      />
      <path
        d="M104.174 92.365C104.174 92.365 91.6044 80.135 87.6444 63.615C87.6444 63.615 41.9644 113.565 44.8344 184.725C44.8344 184.725 46.3044 123.595 104.174 92.355V92.365Z"
        fill="url(#paint58_linear_17472_35089)"
      />
      <path
        d="M423.254 184.715C423.254 184.715 441.874 99.335 354.344 58.105C354.344 58.105 302.244 39.845 249.094 41.915C249.094 41.915 314.814 44.555 367.894 89.165C420.974 133.775 423.254 184.715 423.254 184.715Z"
        fill="url(#paint59_linear_17472_35089)"
      />
      <path
        d="M130.154 2.76503C130.154 2.76503 141.444 62.055 230.674 78.435C319.904 94.815 324.984 108.925 343.054 121.355C343.054 121.355 313.964 68.965 245.074 62.155C176.554 55.375 156.524 20.305 150.484 2.78503H130.154V2.76503Z"
        fill="url(#paint60_linear_17472_35089)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_17472_35089"
          x1="51.4145"
          y1="466.005"
          x2="428.865"
          y2="286.385"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#92D6FF" />
          <stop offset="1" stop-color="#1A77AA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_17472_35089"
          x1="79.591"
          y1="454.629"
          x2="440.214"
          y2="174.496"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_17472_35089"
          x1="32.2943"
          y1="333.025"
          x2="343.914"
          y2="91.0049"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_17472_35089"
          x1="2.93426"
          y1="355.835"
          x2="363.534"
          y2="75.7649"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_17472_35089"
          x1="104.584"
          y1="276.885"
          x2="183.664"
          y2="215.465"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_17472_35089"
          x1="105.704"
          y1="281.685"
          x2="186.304"
          y2="219.085"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_17472_35089"
          x1="35.0441"
          y1="336.565"
          x2="346.664"
          y2="94.5451"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_17472_35089"
          x1="5.6841"
          y1="359.375"
          x2="366.284"
          y2="79.3051"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_17472_35089"
          x1="107.334"
          y1="280.425"
          x2="186.414"
          y2="219.005"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_17472_35089"
          x1="105.902"
          y1="281.934"
          x2="186.492"
          y2="219.334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_17472_35089"
          x1="35.2323"
          y1="336.814"
          x2="346.852"
          y2="94.7936"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_17472_35089"
          x1="5.87229"
          y1="359.614"
          x2="366.482"
          y2="79.5536"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_17472_35089"
          x1="107.522"
          y1="280.674"
          x2="186.602"
          y2="219.254"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_17472_35089"
          x1="125.532"
          y1="266.684"
          x2="170.992"
          y2="231.374"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_17472_35089"
          x1="95.6142"
          y1="414.545"
          x2="407.234"
          y2="172.525"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_17472_35089"
          x1="66.2442"
          y1="437.355"
          x2="426.854"
          y2="157.285"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_17472_35089"
          x1="272.864"
          y1="276.885"
          x2="351.944"
          y2="215.465"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_17472_35089"
          x1="98.5543"
          y1="418.335"
          x2="410.174"
          y2="176.315"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_17472_35089"
          x1="69.1943"
          y1="441.145"
          x2="429.794"
          y2="161.075"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_17472_35089"
          x1="274.184"
          y1="281.935"
          x2="354.784"
          y2="219.335"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_17472_35089"
          x1="275.804"
          y1="280.675"
          x2="354.884"
          y2="219.255"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_17472_35089"
          x1="293.814"
          y1="266.685"
          x2="339.274"
          y2="231.375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_17472_35089"
          x1="233.034"
          y1="388.715"
          x2="223.844"
          y2="551.095"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0088CA" />
          <stop offset="0.47" stop-color="#02B6EE" />
          <stop offset="1" stop-color="#15FF72" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_17472_35089"
          x1="64.1644"
          y1="481.685"
          x2="450.654"
          y2="181.525"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_17472_35089"
          x1="126.234"
          y1="411.335"
          x2="570.484"
          y2="199.925"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.36" stop-color="#4687CE" />
          <stop offset="1" stop-color="#15FF72" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_17472_35089"
          x1="92.6741"
          y1="360.225"
          x2="380.204"
          y2="127.315"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="1" stop-color="#0EE59B" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_17472_35089"
          x1="12.4132"
          y1="210.835"
          x2="337.231"
          y2="-41.4372"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_17472_35089"
          x1="127.564"
          y1="376.405"
          x2="119.144"
          y2="182.625"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_17472_35089"
          x1="284.684"
          y1="376.395"
          x2="369.814"
          y2="215.335"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_17472_35089"
          x1="129.454"
          y1="394.155"
          x2="312.424"
          y2="128.185"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.05" stop-color="#304F96" />
          <stop offset="0.73" stop-color="#51C2C4" stop-opacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_17472_35089"
          x1="105.994"
          y1="282.055"
          x2="186.594"
          y2="219.465"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_17472_35089"
          x1="35.3344"
          y1="336.945"
          x2="346.954"
          y2="94.915"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_17472_35089"
          x1="105.924"
          y1="281.955"
          x2="186.514"
          y2="219.365"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_17472_35089"
          x1="35.2542"
          y1="336.845"
          x2="346.874"
          y2="94.815"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_17472_35089"
          x1="5.89421"
          y1="359.645"
          x2="366.504"
          y2="79.585"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_17472_35089"
          x1="98.6542"
          y1="418.465"
          x2="410.274"
          y2="176.445"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_17472_35089"
          x1="69.2842"
          y1="441.265"
          x2="429.894"
          y2="161.205"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_17472_35089"
          x1="98.5745"
          y1="418.365"
          x2="410.194"
          y2="176.345"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_17472_35089"
          x1="69.2145"
          y1="441.165"
          x2="429.814"
          y2="161.105"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_17472_35089"
          x1="274.204"
          y1="281.955"
          x2="354.804"
          y2="219.365"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_17472_35089"
          x1="98.3641"
          y1="418.085"
          x2="409.984"
          y2="176.065"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_17472_35089"
          x1="68.9941"
          y1="440.895"
          x2="429.604"
          y2="160.835"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_17472_35089"
          x1="273.994"
          y1="281.685"
          x2="354.584"
          y2="219.085"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_17472_35089"
          x1="275.614"
          y1="280.425"
          x2="354.694"
          y2="219.005"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_17472_35089"
          x1="104.695"
          y1="23.715"
          x2="106.775"
          y2="23.715"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint45_linear_17472_35089"
          x1="108.105"
          y1="28.465"
          x2="109.205"
          y2="28.465"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_17472_35089"
          x1="134.214"
          y1="62.095"
          x2="135.544"
          y2="62.095"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint47_linear_17472_35089"
          x1="131.354"
          y1="59.135"
          x2="133.144"
          y2="59.135"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint48_linear_17472_35089"
          x1="21.6846"
          y1="119.745"
          x2="405.215"
          y2="119.745"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stop-opacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint49_linear_17472_35089"
          x1="20.2837"
          y1="211.285"
          x2="405.444"
          y2="211.285"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stop-opacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint50_linear_17472_35089"
          x1="20.2842"
          y1="116.885"
          x2="405.444"
          y2="116.885"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint51_linear_17472_35089"
          x1="0.384277"
          y1="244.605"
          x2="43.8043"
          y2="244.605"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint52_linear_17472_35089"
          x1="468.684"
          y1="244.605"
          x2="425.264"
          y2="244.605"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#358ACA" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint53_linear_17472_35089"
          x1="168.425"
          y1="89.295"
          x2="445.405"
          y2="89.295"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#009CD4" />
          <stop offset="1" stop-color="#CFFFFF" />
        </linearGradient>
        <linearGradient
          id="paint54_linear_17472_35089"
          x1="187.955"
          y1="-46.925"
          x2="508.705"
          y2="165.405"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#42BAE1" />
          <stop offset="0.47" stop-color="#02B6E6" />
          <stop offset="0.79" stop-color="#5FC4B9" />
        </linearGradient>
        <linearGradient
          id="paint55_linear_17472_35089"
          x1="385.984"
          y1="264.755"
          x2="59.0141"
          y2="49.035"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#009CD4" />
          <stop offset="1" stop-color="#83DBEF" />
        </linearGradient>
        <linearGradient
          id="paint56_linear_17472_35089"
          x1="21.6843"
          y1="107.285"
          x2="405.214"
          y2="107.285"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#009CD4" />
          <stop offset="1" stop-color="#CFFFFF" />
        </linearGradient>
        <linearGradient
          id="paint57_linear_17472_35089"
          x1="432.525"
          y1="220.475"
          x2="216.725"
          y2="62.215"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FDFEFF" />
          <stop offset="1" stop-color="#CFFFFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint58_linear_17472_35089"
          x1="44.7044"
          y1="124.175"
          x2="104.174"
          y2="124.175"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FDFEFF" />
          <stop offset="1" stop-color="#CFFFFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint59_linear_17472_35089"
          x1="249.084"
          y1="113.235"
          x2="424.904"
          y2="113.235"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#009CD4" stop-opacity="0" />
          <stop offset="1" stop-color="#CFFFFF" />
        </linearGradient>
        <linearGradient
          id="paint60_linear_17472_35089"
          x1="134.024"
          y1="4.48503"
          x2="361.034"
          y2="146.225"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#009CD4" />
          <stop offset="1" stop-color="#CFFFFF" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>

    <span class="heading7" v-if="props.title">{{ props.title }}</span>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: "",
  },
  width: {
    type: String,
    required: true,
    default: 40,
  },
  height: {
    type: String,
    required: true,
    default: 40,
  },
  borderRound: {
    type: String,
    required: false,
    default: "50%",
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/componentmainstyle";

.icon-freddy-small {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: rgb(177, 168, 168);
  gap: 15px;
  &:hover {
    cursor: pointer;
  }
}
</style>
