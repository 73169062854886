import type { IAssistantResponse } from "@/Models/Response/Assistant/IAssistantResponse";
import Core from "./Core";
import type { IMessageResponse } from "@/Models/Response/Message/IMessageResponse";
import type { IMessageRequest } from "@/Models/Request/Message/IMessageRequest";
import AppConfigGlobal from "../core/config/uiSettings";
import type { IMessageRun } from "@/Models/Response/Message/IMessageRun";
import type { IMessageDataContentViewModel, IMessageRequestViewModel } from "../Models/Request/Message/IMessageRequestViewModel";
import type { IThreadRequestViewModel } from "@/Models/Response/Thread/IThreadRequestViewModel";
import type { IThreadSettingsRequest } from "@/Models/Request/Thread/IThreadSettingsRequest";

export class FreddyService extends Core {

    public async Get_Messages(skip: number, take: number = 10): Promise<Array<IMessageResponse>> {
        interface ITextMessageResponse {
            id: number,
            roleId: number,
            content: string,
            tokens: number,
            messageTypeId: number,
        }
        try {
            const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/assistants/${AppConfigGlobal.CurrentAssistantId}/threads/${AppConfigGlobal.CurrentThreadId}/messages?skip=${skip}&take=${take}`;
            const messages = await this.PerformGet<Array<ITextMessageResponse>>(requestUrl);

            const listOfMessage: IMessageResponse[] = [];

            messages.map(m => listOfMessage.push({
                id: m.id,
                roleId: m.roleId,
                content: [{
                    type: ((m.messageTypeId == 2) ? "image_url" : "text"),
                    text: { value: ((m.messageTypeId == 2) ? JSON.parse(m.content)[0] : m.content) }
                }],
                createdOn: 1,
                tokens: m.tokens,
                messageTypeId: m.messageTypeId
            }));

            return listOfMessage;
        }
        catch (error: any) {
            return [];
        }
    }

    //public async Create_Message(message: IMessageDataContentViewModel[], hasAttachments: boolean, userPrompt: string, selectedFiles: File[]): Promise<string | IMessageRun[]> {

    //    const messageRequest: IMessageRequestViewModel = {
    //        organization_id: AppConfigGlobal.CurrentOrganizationId,
    //        assistant_id: AppConfigGlobal.CurrentAssistantId,
    //        thread_id: AppConfigGlobal.CurrentThreadId,
    //        hasAttachments: hasAttachments,
    //        messages: [{
    //            role: "user",
    //            type: "text", // Assuming the role is "user", modify as needed
    //            content: userPrompt
    //        }],
    //        files: [...selectedFiles],
    //    };

    //    const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/messages/run-stream`;
    //    return this.PerformPost<string | IMessageRun[]>(requestUrl, messageRequest);
    //}

    public async Rename_Thread(id: number, newTitle: string): Promise<boolean> {
        const threadRequest: IThreadRequestViewModel = { id: id, name: newTitle, };
        const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/V2/ThreadRename`;
        return await this.PerformPost(requestUrl, threadRequest);
    }

    public async Delete_Thread(id: number): Promise<void> {
        const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/V2/ThreadDelete/${id}`;
        this.PerformDelete(requestUrl);
    }

    public async Update_Thread(id: string, name: string, instructions: string, additionsalInstructions: string): Promise<void> {
        const threadRequest: IThreadSettingsRequest = { id: id, name: name, instructions: instructions, additionalInstructions: additionsalInstructions };
        const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/V2/ThreadUpdate`;
        this.PerformPost(requestUrl, threadRequest);
    }
}
