<template>
    <div class="icon-call">
        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
            <path d="M19.554 20.6578C21.178 24.0403 23.3918 27.2104 26.1955 30.0141C28.9992 32.8178 32.1693 35.0316 35.5518 36.6556C35.8427 36.7953 35.9882 36.8652 36.1723 36.9188C36.8264 37.1095 37.6297 36.9725 38.1837 36.5759C38.3396 36.4642 38.473 36.3309 38.7397 36.0641C39.5555 35.2484 39.9633 34.8405 40.3735 34.5738C41.9202 33.5682 43.9142 33.5682 45.461 34.5738C45.8711 34.8405 46.279 35.2484 47.0948 36.0641L47.5494 36.5188C48.7895 37.7589 49.4095 38.3789 49.7463 39.0448C50.4161 40.3691 50.4161 41.933 49.7463 43.2573C49.4095 43.9232 48.7895 44.5432 47.5494 45.7833L47.1816 46.1511C45.9458 47.3869 45.328 48.0048 44.4879 48.4767C43.5557 49.0003 42.1079 49.3768 41.0387 49.3737C40.0752 49.3708 39.4166 49.1839 38.0996 48.8101C31.0217 46.8011 24.3428 43.0107 18.7709 37.4387C13.1989 31.8668 9.40848 25.1879 7.39955 18.11C7.02573 16.793 6.83882 16.1345 6.83596 15.1709C6.83278 14.1017 7.20929 12.6539 7.73293 11.7217C8.20485 10.8817 8.82274 10.2638 10.0585 9.02798L10.4263 8.66016C11.6664 7.42013 12.2864 6.80011 12.9523 6.46331C14.2766 5.79348 15.8405 5.79348 17.1648 6.46331C17.8307 6.80011 18.4508 7.42013 19.6908 8.66016L20.1455 9.11486C20.9612 9.93061 21.3691 10.3385 21.6358 10.7486C22.6414 12.2954 22.6414 14.2894 21.6358 15.8361C21.3691 16.2463 20.9612 16.6542 20.1455 17.4699C19.8788 17.7366 19.7454 17.87 19.6338 18.0259C19.2371 18.5799 19.1001 19.3832 19.2908 20.0373C19.3444 20.2214 19.4143 20.3669 19.554 20.6578Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </div>
</template>

<script setup lang="ts">

</script>

<style>
    .icon-call {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        color: rgb(177, 168, 168);
    }
</style>