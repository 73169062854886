<template>
    <div v-if="visible"
         class="toaster"
         :class="{ active: isSuccess == 'success', error: isSuccess != 'success' }">
        <div class="toaster-content">
            <div class="toaster__iconTxt-container">
                <div class="toaster__icon-container">
                    <svg v-if="isSuccess == 'success'"
                         width="32"
                         height="32"
                         viewBox="0 0 56 56"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <rect x="3.5"
                              y="3.5"
                              width="49"
                              height="49"
                              rx="24.5"
                              fill="#68C57C" />
                        <rect x="3.5"
                              y="3.5"
                              width="49"
                              height="49"
                              rx="24.5"
                              stroke="#079455"
                              stroke-width="7" />
                        <path d="M22.7487 28.0026L26.2487 31.5026L33.2487 24.5026M39.6654 28.0026C39.6654 34.4459 34.442 39.6693 27.9987 39.6693C21.5554 39.6693 16.332 34.4459 16.332 28.0026C16.332 21.5593 21.5554 16.3359 27.9987 16.3359C34.442 16.3359 39.6654 21.5593 39.6654 28.0026Z"
                              stroke="white"
                              stroke-width="2.33333"
                              stroke-linecap="round"
                              stroke-linejoin="round" />
                    </svg>
                    <svg v-if="isSuccess != 'success'"
                         width="32"
                         height="32"
                         viewBox="0 0 56 56"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <rect x="3.5"
                              y="3.5"
                              width="49"
                              height="49"
                              rx="24.5"
                              fill="#F79009" />
                        <rect x="3.5"
                              y="3.5"
                              width="49"
                              height="49"
                              rx="24.5"
                              stroke="#DC6803"
                              stroke-width="7" />
                        <path d="M27.9987 23.3359V28.0026M27.9987 32.6693H28.0104M39.6654 28.0026C39.6654 34.4459 34.442 39.6693 27.9987 39.6693C21.5554 39.6693 16.332 34.4459 16.332 28.0026C16.332 21.5593 21.5554 16.3359 27.9987 16.3359C34.442 16.3359 39.6654 21.5593 39.6654 28.0026Z"
                              stroke="white"
                              stroke-width="2.33333"
                              stroke-linecap="round"
                              stroke-linejoin="round" />
                    </svg>
                </div>
                <div>
                    <div v-if="isSuccess != 'success'">Error</div>
                    <div v-if="isSuccess == 'success'"></div>
                    <div class="error_message">{{ message }}</div>
                </div>
            </div>
            <button class="defaultText close-btn" @click="closeToast">
                <svg width="12"
                     height="12"
                     viewBox="0 0 12 12"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 1L1 11M1 1L11 11"
                          stroke="white"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                </svg>
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, defineProps } from "vue";

// Define props for the component
const props = defineProps({
  message: {
    type: String,
    default: "something went wrong , please try again. ",
  },
  isSuccess: {
    type: String,
    default: "",
  },
  isVisible: {
    type: Boolean,
    default: true, // Control from parent
  },
});

// State management
const visible = ref(true);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const emit = defineEmits(["update:showToaster"]);

// Method to close the toaster
const closeToast = () => {
  visible.value = false;
  visible.value = true;
  emit("update:showToaster", false);
};
</script>
<style scoped>
    .toaster {
        color: white;
        padding: 10px;
        position: fixed;
        bottom: 40px;
        border-radius: 24px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 70%;
        background: #031525;
    }

    .active {
        border: 2px solid #079455;
    }

    .error {
        border: 2px solid #dc6803;
    }

    .toaster-content {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .close-btn {
        background: none;
        border: none;
        color: white;
        cursor: pointer;
        margin-left: 15px;
        margin-top: -25px;
    }

    .toaster__iconTxt-container {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    @media (max-width: 1024px){
        .error_message{
            font-size: 12px;
        }
    }
    @media (max-width: 768px){
        .error_message{
            font-size: 8px;
        }
    }
    @media (min-width: 320px) and (max-width: 640px){
        .toaster{
            width: 98%;
        }
    }
</style>
