<template>
    <div class="check-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M16.6673 5.5L7.50065 14.6667L3.33398 10.5" stroke="#A7D0F8" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
    </div>
  </template>
  
  <script>
  
  </script>
  
  <style>
  .check-icon{
    display: flex;
    justify-content: flex-end;
    width: 100%;
  height: 20px;
  }
  </style>