<template>
  <section class="text-white lg:max-w-[100%] flex flex-col border border-menuSelected p-4 rounded-[10px] gap-6 w-full py-5">
    <h2 class="font-semibold text-[17px]">Where you're logged in</h2>
    <p class="text-lightGrey mt-1 font-medium text-[12px]">
      We'll alert you if there is any unusual activity on your account.
    </p>
    <ul class="list-none p-0">
      <li
        v-for="(location, index) in locations"
        :key="index"
        class="flex items-start gap-4 p-4 border-b border-white/40"
      >
        <img
          :src="'https://cdn.builder.io/api/v1/image/assets/TEMP/09b5cbce7f391eed3b10fd3368e98878264a92465c5b48649d48107b21463b85?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51'"
          :alt="location.device"
          class="w-6 h-6"
        />
        <div class="flex-1">
          <div class="flex items-center gap-2">
            <span class="text-textGrey font-medium text-[14px]">{{ location.device }}</span>
            <span v-if="true" class="flex items-center gap-1 px-1.5 py-0.5 bg-white rounded-md text-backgroundSidebar font-medium text-[12px]">
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/bb6a9bc64d9cb149150c637fa1952b4d475bd7d78ee72634c36072d6439a3283?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
                alt=""
                class="w-2 h-2"
              />
              Active now
            </span>
          </div>
          <p class="text-white/40 mt-1 font-medium text-[14px]">
            {{ location.location }} • {{ formattedDate }}
          </p>
        </div>
      </li>
    </ul>
  </section>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { Device } from "@capacitor/device";
import type { IDeviceInformation } from "../../../Models/Request/User/IUserAuthRequest";
import { UserService } from "../../../Services/UserService";
import { format } from "date-fns";

export default defineComponent({
  name: "LoginLocations",
  setup() {
    const formattedDate = format(new Date(), "d MMM  yyyy h:mma");
    const authService = new UserService();
    let locations = ref<IDeviceInformation[]>([]);
    const fetchUserDevice = async () => {
      try {
        locations.value = await authService.Get_UserDevices();
      } catch (error: any) {
        // Handle error
      }
    };
    onMounted(() => {
      fetchUserDevice();
    });

    return { locations, formattedDate };
  },
});
</script>

<style scoped>
/* .login-locations {
  color: #fff;
} */

/* .section-description {
  color: #cbd6e3;
  margin-top: 4px;
}
 */
/* .section-divider {
  background-color: rgba(255, 255, 255, 0.4);
  border: none;
  height: 1px;
  margin: 20px 0;
}
 */
/* .location-list {
  list-style-type: none;
  padding: 0;
}
 */
/* .location-item {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
} */

/* .location-icon {
  width: 24px;
  height: 24px;
} */

/* .location-details {
  flex: 1;
} */

/* .location-header {
  display: flex;
  align-items: center;
  gap: 8px;
} */

/* .device-name {
  color: rgba(255, 255, 255, 0.4);
}
 */
/* .active-badge {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 6px;
  background-color: #fff;
  border-radius: 6px;
  color: #071a2b;
} */

/* .active-icon {
  width: 8px;
  height: 8px;
} */

/* .location-info {
  color: #cbd6e3;
  margin-top: 4px;
} */

@media (max-width: 991px) {
  .login-locations {
    max-width: 100%;
  }
}
</style>
