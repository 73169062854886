<template>
  <!--    <div class="container">
        <Transition name="slide-fade-left" :class="{ left_dashboard: !isVisible }">
            <div class="box left"
                 v-if="!isSideMenuClosed || !isOpened"
                 id="settings_background">
                <slot name="left"></slot>
            </div>
        </Transition>
        <Transition name="slide-fade-right">
            <div class="box right" :class="{ 'full-view': isSideMenuClosed }">
                <slot name="right"></slot>
            </div>
        </Transition>
    </div>-->
  <div class="relative flex h-full w-full overflow-hidden">
    <Transition name="slide-fade-left" :class="{ left_dashboard: !isVisible }">
      <div
        class="w-96  bg-backgroundSidebar relative h-svh"
        v-if="!isSideMenuClosed || !isOpened"
        id="settings_background"
      >
        <slot name="left"></slot>
      </div>
    </Transition>
    <Transition name="slide-fade-right">
      <div class="h-svh max-h-svh  flex-1 overflow-hidden" :class="{ 'full-view': isSideMenuClosed }">
        <slot name="right"></slot>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: "TwoBoxLayout",
  props: {
    isSideMenuClosed: {
      default: false,
      required: false,
      type: Boolean,
    },
    isVisible: {
      required: true,
      type: Boolean,
    },
    isOpened: {
      required: true,
      type: Boolean,
    },
  },
  mounted() {
    this.updatedStyles();
  },
  watch: {
    isOpened() {
      this.updatedStyles();
    },
  },
  methods: {
    updatedStyles() {
      const mediaQuery = window.matchMedia("(max-width: 768px)");
      var settings_dashboard = document.getElementById("settings_background");
      if (mediaQuery.matches && this.isOpened) {
        settings_dashboard.style.backgroundColor = "unset";
      }
    },
  },
};
</script>

<!--<style scoped>
    .container {
        display: flex;
        width: 100vw;
        height: 100vh;
        color: black;
        position: fixed;
        top: 0;
        left: 0;
        background-color: var(&#45;&#45;backgroundPrimary);
    }

      .box {
          /*border: 1px solid #434547;*/
          /* overflow: auto; */
          /* overflow-y: auto; */
          color: white;
          padding: 20px 10px;
      }

    .left {
        position: absolute;
        background-color: var(&#45;&#45;backgroundSecondary);
        width: 400px;
        height: 100%;
        overflow: hidden;
        padding: 10px 0;
        left: 0;
    }

    .right {
        margin-left: 400px;
        transition: margin-left 0.1s ease-in;
        width: 100%;
        /*background-color: var(&#45;&#45;backgroundPrimary);*/
        overflow-y: auto;
        overflow-x: hidden;
        /*padding: 10px;*/
    }

      @media (max-width: 1440px) {
          .right {
              overflow-y: auto;
          }
      }

      @media (max-width: 1024px) {
          .right {
              height: 100%;
              overflow-y: auto;
          }
      }

      /* @media (max-width: 1040px) {
        .right {
            margin-left: 0;
            transition: margin-left 0.1s ease-in;
        }
    } */

      @media (max-width: 768px) {
          .left {
              display: none;
          }

          .left_dashboard {
              display: block;
              width: 100vw;
              position: absolute;
              z-index: 1;
          }

          .right {
              margin-left: 0px;
              overflow-y: auto;
          }
      }

      .hide {
          display: none;
      }

      .full-view {
          margin-left: 0;
          margin-top: 0%;
      }

      .slide-fade-left-enter-active,
      .slide-fade-left-leave-active,
      .slide-fade-right-enter-active,
      .slide-fade-right-leave-active {
          transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
      }

      .slide-fade-left-enter-from,
      .slide-fade-left-leave-to {
          transform: translateX(-20px);
          opacity: 0;
      }

      .slide-fade-right-enter-from,
      .slide-fade-right-leave-to {
          transform: translateX(-20px);
          margin-left: 0;
      }

      .slide-fade-right-leave-from,
      .slide-fade-right-enter-to {
          transform: translateX(-20px);
          margin-left: 400px;
      }

      /*.slide-fade-leave-to {
        transform: translateX(10px);
        opacity: 0;
        margin-left: 0;
    }

    .slide-fade-margin-enter-active {
        transition: all 0.4s ease-out;
    }

    .slide-fade-margin-leave-active {
        transition: all 0.4s ease-out;
    }*/

      /*.slide-fade-margin-enter-from {
        transform: translateX(-10px);
        opacity: 0;
    }*/

      /*.slide-fade-margin-leave-to, .slide-fade-margin-enter-from {
        transition: margin 10s ease-out;
        opacity: 0;
    }*/
</style>-->
