<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 28 21"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9987 11.3307C14.4589 11.3307 14.832 10.9576 14.832 10.4974C14.832 10.0372 14.4589 9.66406 13.9987 9.66406C13.5385 9.66406 13.1654 10.0372 13.1654 10.4974C13.1654 10.9576 13.5385 11.3307 13.9987 11.3307Z"
      stroke="white"
      stroke-width="0.9375"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.832 11.3307C20.2923 11.3307 20.6654 10.9576 20.6654 10.4974C20.6654 10.0372 20.2923 9.66406 19.832 9.66406C19.3718 9.66406 18.9987 10.0372 18.9987 10.4974C18.9987 10.9576 19.3718 11.3307 19.832 11.3307Z"
      stroke="white"
      stroke-width="0.9375"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.16536 11.3307C8.6256 11.3307 8.9987 10.9576 8.9987 10.4974C8.9987 10.0372 8.6256 9.66406 8.16536 9.66406C7.70513 9.66406 7.33203 10.0372 7.33203 10.4974C7.33203 10.9576 7.70513 11.3307 8.16536 11.3307Z"
      stroke="white"
      stroke-width="0.9375"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
