<template>
  <TwoBoxLayout
    :isSideMenuClosed="!isTextChatEnabled"
    :isVisible="!isVisible"
    :isOpened="isOpened"
  >
    <template #left>
      <!--<SideMenu @newThread="newThread" @newThreadSelected="onThreadChanged" @settingsMenuOpened="onSettingsMenuOpen" @settingsMenuClosed="onSettingsMenuClosed"></SideMenu>-->
      <DashBoard
        :isPreDefinedQuery="isPreDefinedQuery"
        :approveOrDeclineTrigger="approveOrDeclineTrigger"
        @settingsMenuOpened="onSettingsMenuOpen"
        @settingsMenuClosed="onSettingsMenuClosed"
        @settingsItemSelected="settingsItemSelected"
        @assistantDashBoardOpened="assistantDashBoardOpened"
        @assistantDashBoardClosed="assistantDashBoardClosed"
        @onSelectCheckpoints="selectCheckpoints"
        @onDeSelectCheckpoints="selectCheckpointsClosed"
        @onTicketSelected="onTicketSelected"
        @emptyThreadList="emptyThreadList"
        @assistantSelected="onAssistantSelected"
        class="pt-5"
      />
    </template>
    <template #right>
      <CheckPoints
        v-if="isSelectCheckpoints"
        :onSelectTicketId="selectedTicketId"
        @checkpointApproveOrDecline="approveOrDecline"
      />

      <Transition
        class="chat-container"
        v-if="isSettingsViewEnabled && !isSelectCheckpoints"
        name="fade"
        mode="out-in"
      >
        <component :is="selectedSettingsPageComponent.component"></component>
      </Transition>

      <div
        class="chat-container h-full relative"
        v-if="!isSettingsViewEnabled && !isSelectCheckpoints"
      >
        <div class="h-20 absolute bg-[#031525] w-full hidden "></div>
        <div
          class="container-header-big flex flex-row justify-between h-[80px] w-full items-center absolute top-0 py-3 bg-background ps-3 pl-5 hidden"
        >
          <ActionDropDown
            id="hide_assistant_dropdown"
            v-if="!isCallingProgress && isAssistantView"
            :isTextSelected="isTextChatEnabled"
            @onItemSelected="onItemSelected"
          />
          <div v-if="false" class="call-progress-btn-grp">
            <div class="call-end-btn" @click="endCall">
              <span class="h6Bold diconnect-btn-text">Disconnect</span>
            </div>
            <div class="video-chat-icon-frame" @click="openSideChat">
              <IconChat />
            </div>
          </div>
          <div class="calling-side-chat" v-if="sideChatOpen">
            <div class="chat-head">
              <div class="chat-head close-btn" @click="closeSideChat">
                <IconCross />
              </div>
              <div class="chat-head title">
                <span>Chat History</span>
                <span>chat time</span>
              </div>
            </div>
            <div class="chat-body" ref="sidechatContainer">
              <div
                class="chat-body mesage-block"
                v-for="(message, index) in messages"
                :key="index"
              >
                <div class="chat-body mesage-block icon">
                  <IconFreddySmallChat v-if="message.roleId !== 1" />
                  <IconAvatarSmallChat v-else />
                </div>
                <div
                  class="chat-body mesage-block text"
                  v-for="(content, index) in message.content"
                  :key="index"
                >
                  <div v-if="content.type == 'text'">
                    <span v-if="content.text.value !== ''">
                      {{ content.text.value }}
                    </span>
                    <IconLoadingSmall
                      v-else
                      :isLoading="
                        content.text.value === '' &&
                        messages.length - 1 === index
                      "
                    />
                  </div>
                  <div v-else-if="content.type.toUpperCase() == 'IMAGE_URL'">
                    <img :src="content.text.value" />
                  </div>
                  <div v-else-if="content.type.toUpperCase() == 'AUDIO'">
                    <audio controls :src="content.text.value" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="../assets/hamburger-res.svg"
          alt=""
          class="hamburger-menu hidden"
          @click="toggleDashboard"
          :style="hamburgerBoxStyles"
        />
        <div class="container-header-small hidden">
          <!-- <icon-menu  class="hamburger-menu"/> -->
          <ActionDropDown />
          <icon-new-chat />
        </div>
        <Transition name="slide-fade">
          <div
            id="chatMainContainer"
            class="flex flex-col gap-2.5 w-full h-full overflow-hidden"
            v-if="isTextChatEnabled"
            :style="componentStyles"
          >
            <div
              id="chat-container"
              class="flex flex-col h-[calc(100%-73px)] py-5 items-center overflow-y-auto overflow-x-hidden"
              ref="chatContainer"
              @scroll="handleScroll"
            >
              <div
                class="chat-container-empty-options max-w-[980px] flex-grow flex flex-col justify-center items-center"
                v-if="!messages.length && AppConfigGlobal.CurrentThreadId == 0"
              >
                <!-- :style="mainchatContainer" -->
                <div class="icon-chat-small">
                  <IconFreddySmall width="80" height="80" />
                </div>
                <div class="basic text-center mt-7 text-4xl">
                  How Can I Help You?
                </div>
                <div
                  class="proposal-edit-container flex text-center mt-14 justify-center flex-wrap"
                >
                  <div
                    class="proposal-edit flex-shrink-0 w-1/5 p-3 rounded-lg border border-buttonBlue m-3 w-[160px]"
                    v-for="(item, index) in listOfDefaultPrompt"
                    :key="index"
                    @click="queryPreDefinedPrompt(index)"
                  >
                    <img
                      src="../assets/pencil-line.svg"
                      class="block m-auto"
                      alt=""
                    />
                    <p
                      class="h7 proposal-content text-textGrey mt-3 font-medium"
                    >
                      {{ item }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                v-else
                ref="chatContainerMessages"
                class="chat-container-messages mt-14 h-full flex flex-col w-full gap-[20px] h-chatContainerHeight"
              >
                <div
                  v-for="(message, index) in messages"
                  :key="index"
                  class="flex flex-col items-start max-w-full message"
                  :class="{ 'user items-end': message.roleId === 1 }"
                >
                  <div
                    class="message-box flex flex-col w-full rounded-[5px]"
                    :class="{ 'user items-end': message.roleId === 1 }"
                  >
                    <div class="message-box flex flex-col w-full rounded-[5px]">
                      <div
                        class="flex flex-row items-center gap-0 px-[20px] py-[10px]"
                        :class="{
                          'user flex-row-reverse': message.roleId === 1,
                        }"
                      >
                        <img
                          v-if="message.roleId === 1"
                          :src="AppConfigGlobal.UserImage"
                          class="user-avatar w-9 h-9 rounded-full"
                        />
                        <IconFreddySmall
                          v-if="message.roleId === 2"
                          width="35"
                          height="35"
                          borderRound="50%"
                        />
                        <span
                          class="heading6 px-[10px] py-0 font-inter text-sm font-medium leading-[16.94px] text-right text-[#CBD6E3]"
                        >
                          {{
                            message.roleId !== 1
                              ? "Freddy"
                              : AppConfigGlobal.UserName
                          }}
                        </span>
                      </div>
                    </div>
                    <div
                      class="message-text flex mx-[55px] items-center rounded-[8px] break-words heading7 rounded-tr-none"
                      :class="{
                        'user p-[12px] bg-[#FFFFFF17]': message.roleId === 1,
                      }"
                    >
                      <div
                        v-for="(content, index) in message.content"
                        :key="index"
                      >
                        <div v-if="content.type == 'text'">
                          <span v-if="content.text.value !== ''">
                            <vue-markdown
                              :source="content.text.value"
                              id="editor"
                              class="font-inter text-base font-normal leading-6 tracking-[0.02em]"
                            />
                          </span>
                          <IconLoadingSmall
                            v-else
                            :isLoading="content.text.value === ''"
                          />
                        </div>
                        <div
                          v-else-if="content.type.toUpperCase() == 'IMAGE_URL'"
                        >
                          <img :src="content.text.value" />
                        </div>
                        <div v-else-if="content.type.toUpperCase() == 'AUDIO'">
                          <audio controls :src="content.text.value" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="message.roleId === 1"
                    class="message-head my-[8px] mx-[55px] mt-2"
                  >
                    <div
                      class="message-head-icons flex justify-end items-center gap-[10px]"
                    >
                      <span
                        class="message-text-edit"
                        @click="
                          copyResponse(
                            message.content.filter((x) => x.type == 'text')[0]
                          )
                        "
                      >
                        <IconCopy />
                      </span>
                      <!--<span class="message-text-edit" @click="editQuery">
                                      <IconEdit/>
                                    </span>-->
                    </div>
                  </div>
                  <div v-else class="message-head my-[8px] mx-[55px] mt-1">
                    <div
                      class="message-head-icons flex justify-end items-center gap-[10px]"
                    >
                      <!--<span class="message-text-edit" @click="reloadResponse">
                                          <icon-reload/>
                                      </span>-->
                      <span
                        class="message-text-edit"
                        @click="
                          copyResponse(
                            message.content.filter((x) => x.type == 'text')[0]
                          )
                        "
                      >
                        <IconCopy />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="input-container"
              class="relative border rounded-lg border-menuSelected w-[calc(100%-2.5rem)] mx-5 bottom-5 h[73px] mt-3"
              v-if="isAssistantView"
              :style="chatBoxStyles"
            >
              <div
                v-if="selectedFiles.length > 0"
                class="selected-input-files-box w-full max-h-[85px] overflow-hidden px-[10px]"
              >
                <ul
                  class="selected-input-files flex flex-row flex-nowrap list-none gap-1 p-0 overflow-auto"
                >
                  <li
                    class="selected-input-files-item flex gap-2 items-center py-3"
                    v-for="(file, index) in selectedFiles"
                    :key="index"
                  >
                    <div class="selected-input-files-item-icon-file">
                      <IconFileUpload />
                    </div>
                    <div class="selected-input-files-item-name">
                      {{ file.name }}
                    </div>
                    <div class="selected-input-files-item-icon-close">
                      <IconClose @click="removeSelectedFile(index, file)" />
                    </div>
                  </li>
                </ul>
              </div>
              <div
                class="relative flex items-center justify-end w-full input-text-area"
                :class="{ 'opacity-50': isFreddyRequestSent }"
              >
                <textarea
                  rows="1"
                  ref="myTextArea"
                  class="input-text-area-box resize-none inputFieldText h-[50px] text-lg w-full align-center rounded-[10px] pr-[60px] pl-[60px] pl-2 py-[12px] resize-none overflow-y-hidden border-none bg-[var(--backgroundSecondary)] text-white leading-[20px] outline-none"
                  @input="adjustTextareaHeight"
                  type="text"
                  v-model="newMessage"
                  @keyup.enter="sendQuery"
                  placeholder="Type here..."
                  :class="{ 'opacity-50': isFreddyRequestSent }"
                  :disabled="isFreddyRequestSent"
                >
                </textarea>
                <div
                  class="flex flex-col items-center text-area-send absolute right-[10px] cursor-pointer"
                >
                  <IconSend
                    title="Send Message"
                    @click="sendQuery()"
                    style="float: left"
                    v-if="!isFreddyRequestSent"
                  />
                  <Spinner v-else />
                </div>
                <input
                  type="file"
                  ref="fileInput"
                  style="display: none"
                  @change="handleFileChange"
                  multiple
                />
                <div class="text-area-FileUpload absolute left-[10px] bottom-2">
                  <IconFileUpload
                    @click="uplodFiles()"
                    :class="{ 'opacity-50': isFreddyRequestSent }"
                  />
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <Transition name="slide-fade-audio">
          <div
            id="chatMainContainer"
            class="flex flex-col gap-[10px] w-full h-full transition-all duration-100 ease-linear overflow-hidden"
            v-if="!isTextChatEnabled"
          >
            <div
              id="chat-container"
              class="call-main-height"
              ref="chatContainer"
            >
              <div
                class="chat-container-empty-options"
                v-if="!isCallingProgress"
              >
                <div v-if="!isLoading" class="icon-fred-small">
                  <IconFreddySmall :width="'80'" :height="'80'" />
                </div>
                <div v-if="!isLoading" class="basic">How Can I Help You?</div>
                <IconLoading :isLoading="isLoading" v-if="isLoading" />
              </div>
              <div v-else class="chat-voice-container-speak">
                <FreddyVoiceLoader v-if="isFreddySpeaking" />
                <UserVoiceLoader v-else />
              </div>
            </div>
            <div id="input-container" class="video-footer-frame call_options">
              <div class="video-icon">
                <IconPresent />
              </div>
              <div
                v-if="!isCallingProgress"
                class="video-icon mid"
                @click="startCall"
              >
                <IconCall />
              </div>
              <div v-else class="video-icon mid" @click="pauseCall">
                <IconPause />
              </div>
              <div
                class="video-icon"
                :class="{ 'video-icon video': isVideoEnabled }"
              >
                <IconVideo v-if="!isVideoEnabled" @click="startVideo" />
                <div
                  v-show="isVideoEnabled"
                  class="video-frame"
                  :style="{ right: !sideChatOpen ? '2%' : '22%' }"
                >
                  <div class="video-frame close">
                    <IconCross @click="closeVideo" />
                  </div>
                  <video ref="videoPlayer" :controls="false" autoplay></video>
                </div>
                <audio ref="audioPlayer" controls style="display: none"></audio>
                <canvas
                  ref="canvasHidden"
                  id="canvas"
                  style="display: none"
                ></canvas>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </template>
  </TwoBoxLayout>
</template>

<script setup lang="ts">
import IconUser from "./icons/IconUser.vue";
import IconShare from "./icons/IconShare.vue";
import IconSend from "./icons/IconSend.vue";
import IconTelephone from "./icons/IconTelephone.vue";
import IconEdit from "./icons/IconEdit.vue";
import IconNewChat from "./icons/IconNewChat.vue";
import IconCopy from "./icons/IconCopy.vue";
import IconMenu from "./icons/IconMenu.vue";
import IconReload from "./icons/IconReload.vue";
import IconCall from "./icons/IconCall.vue";
import IconPresent from "./icons/IconPresent.vue";
import IconVideo from "./icons/IconVideo.vue";
import IconPause from "./icons/IconPause.vue";
import IconChat from "./icons/IconChat.vue";
import IconCross from "./icons/IconCross.vue";
import IconFreddySmall from "./icons/IconFreddySmall.vue";
import IconFreddy from "./icons/IconFreddy.vue";
import IconCopied from "./icons/IconCopied.vue";
import IconClose from "./icons/IconClose.vue";
import IconFileUpload from "./icons/IconFileUpload.vue";
import IconVoice from "./icons/IconVoice.vue";
import IconAvatarOlivia from "./icons/IconAvatarOlivia.vue";
import IconAvatarSmallChat from "./icons/IconAvatarSmallChat.vue";
import IconLoading from "../components/common/IconLoading.vue";
import IconLoadingSmall from "../components/common/IconLoadingSmall.vue";
import IconFreddySmallChat from "./icons/IconFreddySmallChat.vue";
import OrganizationPage from "./SettingsPages/OrganizationSettings/OrganizationPage.vue";
import Spinner from "../components/common/Spinner.vue";

import TwoBoxLayout from "./TwoBoxLayout.vue";
import FreddyVideoFrame from "./FreddyVideoFrame.vue";
import ActionDropDown from "../components/common/ActionDropDown.vue";
import FreddyVoiceLoader from "../components/common/FreddyVoiceLoader.vue";
import UserVoiceLoader from "../components/common/UserVoiceLoader.vue";
import VueMarkdown from "vue-markdown-render";
import OrganisationPage from "../components/SettingsPages/OrganizationSettings/OrganizationPage.vue";
import PasswordSettings from "../components/SettingsPages/PasswordSettings/PasswordSettings.vue";
import AssistantsPage from "../components/SettingsPages/AssistantSettings/AssistantsPage.vue";
import ProfileSettings from "../components/SettingsPages/ProfileSettings/ProfileSettings.vue";
import DashBoard from "../components/Dashboards/DashBoard.vue";
import CheckPoints from "./CheckPoints/CheckPoints.vue";
import { FreddyService } from "../Services/FreddyService";

import type {
  IMessageRequestViewModel,
  IMessageDataViewModel,
  IMessageDataContentViewModel,
} from "../Models/Request/Message/IMessageRequestViewModel";
import type {
  IMessageResponse,
  IMessageContent,
} from "../Models/Response/Message/IMessageResponse";
import type { IMessageResponseViewModel } from "../Models/Response/Message/IMessageResponseViewModel";
import type { IMessageRun } from "../Models/Response/Message/IMessageRun";
import type { IComponentMap } from "@/Models/Common/IComponentMap";
import type { ISettingsMenuItem } from "@/Models/Common/ISettingsMenuItem";

import AppConfigGlobal from "../core/config/uiSettings";
import {
  defineComponent,
  ref,
  reactive,
  watch,
  onMounted,
  nextTick,
  computed,
} from "vue";
import gsap from "gsap";
import Core from "../Services/Core";
import Axios from "axios";
import type { IUserConfiguration } from "@/Models/Response/User/IUserConfiguration";
import { UserService } from "@/Services/UserService";
import { useStore } from "vuex";
import { AssistantService } from "@/Services/AssistantService";

const assistantService = new AssistantService();
interface State {
  contentDisplay: string;
  yComponentStyles: any;
  hamburgerStyles: any;
  rightSection: any;
  proposalEdit: any;
  chatBoxStyle: any;
  assistantDropdown: any;
  chatContainer: any;
}
const settingsMenuComponents = ref<IComponentMap[]>([
  {
    title: "Profile",
    component: ProfileSettings,
    isVisble: true,
    subTitle: "",
  },
  {
    title: "Password",
    component: PasswordSettings,
    isVisble: true,
    subTitle: "",
  },
  {
    title: "Assistants",
    component: AssistantsPage,
    isVisble: true,
    subTitle: "",
  },
  {
    title: "Organizations",
    component: OrganisationPage,
    isVisble: true,
    subTitle: "",
  },
]);
const isAssistantView = ref<boolean>(false);
const selectedSettingsPageComponent = ref<IComponentMap>(
  settingsMenuComponents.value[0]
);

const socket = ref<WebSocket | null>(null);
const mediaRecorder = ref<MediaRecorder | null>(null);
const freddyService = new FreddyService();
interface FreddyResponse {
  text: string;
}
// interface State {
//     contentDisplay: string
//     yDivWidth: string
//     yComponentStyles: any
//     hamburgerStyles: any
// }
const messages = ref<IMessageResponse[]>([]);
const messageStream = ref<IMessageRun[] | string>("");
const newMessage = ref<string>("");
const textResponse = ref<string>("");
const defaultAssistantMessage = ref<string>(`Sorry I can't make this time!`);
const selectedFiles = ref<File[]>([]);
const isTextChatEnabled = ref<boolean>(true);
const isCallingProgress = ref<boolean>(false);
const isVideoEnabled = ref<boolean>(false);
const selectedView = ref<IComponentMap | null>(null);
const listWishedUsers = ref<string[]>([]);
const userName = ref<string>("");
const isLoading = ref<boolean>(false);
const sideChatOpen = ref<boolean>(false);
const isFreddySpeaking = ref<boolean>(false);
const isUserSpeaking = ref<boolean>(false);
const userInterrupted = ref<boolean>(false);
const facialSearchIntervalId = ref<number>();
const isVisible = ref<boolean>(false);
const isOpened = ref<boolean>(false);
const isSettingsViewEnabled = ref<boolean>(false);
const isMessageResponseThreadIdChange = ref<boolean>(false);
const isPreDefinedQuery = ref<boolean>(false);

const audioPlayer = ref<HTMLAudioElement | null>(null);
const myTextArea = ref<HTMLTextAreaElement | null>(null);
const chatContainer = ref<HTMLInputElement | null>(null);
const sidechatContainer = ref<HTMLElement | null>(null);
const canvasHidden = ref<HTMLCanvasElement | null>(null);
const videoPlayer = ref<HTMLVideoElement | null>(null);
const fileInput = ref<HTMLInputElement | null>(null);
const streamVideo = ref<MediaStream | null>(null);
const streamAudio = ref<MediaStream | null>(null);
const fileObjectList = ref<IMessageDataContentViewModel[]>([]);
const isFreddyRequestSent = ref<boolean>(false);
const freddyResponseAvaiable = ref<boolean>(false);
const store = useStore<State>();
const componentStyles = computed(() => store?.state.yComponentStyles);
const chatBoxStyles = computed(() => store?.state.yComponentStyles);
const hamburgerBoxStyles = computed(() => store?.state.hamburgerStyles);
const selectedTicketId = ref(0);
const listOfDefaultPrompt = ref<string[]>([]);

const userConfiguration = ref<IUserConfiguration>({} as IUserConfiguration);
const userService = new UserService();
const isSelectCheckpoints = ref<boolean>(false);
// Utility function to convert a File object to a base64 string
const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader(); // Use FileReader to read the file
    reader.onloadend = () => resolve(reader.result as string); // Once the file is loaded, resolve the promise with the result (base64 string)
    reader.onerror = reject; // If there is an error, reject the promise
    reader.readAsDataURL(file); // Read the file as a data URL (base64)
  });
};

const sendQuery = async (): Promise<void> => {
  const newQuery: string = newMessage.value.trim();
  const hasAttachments: boolean = fileObjectList.value.length !== 0;
  console.log("fileobj", fileObjectList);

  newMessage.value = ""; // Reset the input
  if (newQuery !== "") {
    messages.value.push({
      id: 1,
      roleId: 1,
      content: [{ type: "text", text: { value: newQuery } }],
      createdOn: Date.now(),
      tokens: 0,
      messageTypeId: 1,
    });

    // if (hasAttachments) {
    //   let attachmentId = 2;

    //   fileObjectList.value.forEach((file) => {
    //     messages.value.push({
    //       id: attachmentId++,
    //       roleId: 1,
    //       content: [
    //         {
    //           type: "image_file",
    //           text: { value: file.toString() },
    //         },
    //       ],
    //       createdOn: Date.now(),
    //       tokens: 0,
    //       messageTypeId: 1,
    //     });
    //   });
    // }
    const assistantMessage: IMessageResponse = {
      id: 1,
      roleId: 2,
      content: [{ type: "text", text: { value: "" } }], // Initially empty
      createdOn: Date.now(),
      tokens: 0,
      messageTypeId: 1,
    };
    messages.value.push(assistantMessage);

    nextTick(() => {
      adjustTextareaHeight();
      adjustChatContainerScrollPoistion();
    });

    try {
      let token = "";
      const value = `; ${document.cookie}`;
      const parts = value.split(`; freddyUserId=`);
      if (parts && parts.length === 2) {
        token = parts.pop()?.split(";").shift() || "";
      }

      //Convert files to base64
      const base64Files = await Promise.all(
        selectedFiles.value.map((file) => convertFileToBase64(file))
      );

      // const uploadedUrls: string[] = [];
      // for (const base64String of base64Files) {
      //   if (base64String.startsWith("data:image/")) {
      //     console.log();
      //     //const fileurl = (await uploadImage(selectedFiles.value)) || "";
      //     uploadedUrls.push(fileurl);
      //   }
      // }

      clearSelectedFiles();
      const messageRequest: IMessageRequestViewModel = {
        organization_id: AppConfigGlobal.CurrentOrganizationId,
        assistant_id: AppConfigGlobal.CurrentAssistantId,
        thread_id: AppConfigGlobal.CurrentThreadId,
        has_attachments: hasAttachments,
        messages: [{ role: "user", type: "text", content: newQuery }],
        //files: [...selectedFiles.value],
        files: base64Files,
        images: [],
        tool_choice: "auto",
      };

      if (messageRequest) {
        isFreddyRequestSent.value = true;
        freddyResponseAvaiable.value = true;
      }

      const response: Response = await fetch(
        `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/messages/run-stream`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(messageRequest),
        }
      );
      //clearSelectedFiles();
      if (!response.body) throw new Error("No response body");
      //   fileObjectList.value = [];
      // selectedFiles.value = [];
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let partialContent = "";

      const readStream = async () => {
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const newChunk = decoder.decode(value, { stream: true });
          partialContent += newChunk;
          let lastValidIndex = 0;
          let parsedItems: {
            event: string;
            response: string;
            responseType: string;
            threadId: number;
          }[] = [];
          let braceCount = 0;
          let insideString = false;

          for (let i = 0; i < partialContent.length; i++) {
            const char = partialContent[i];
            if (char === '"') {
              if (i === 0 || partialContent[i - 1] !== "\\") {
                insideString = !insideString;
              }
            }

            if (!insideString) {
              if (char === "{") {
                braceCount++;
              } else if (char === "}") {
                braceCount--;
              }

              if (braceCount === 0) {
                const jsonStr = partialContent.slice(lastValidIndex, i + 1);
                lastValidIndex = i + 1;

                try {
                  const parsedItem = JSON.parse(jsonStr);
                  parsedItems.push(parsedItem);
                } catch (error) {}
              }
            }
          }

          partialContent = partialContent.slice(lastValidIndex);

          parsedItems.forEach((item) => {
            if (item.event === "thread.run.created") {
              isMessageResponseThreadIdChange.value = true;
              AppConfigGlobal.CurrentThreadId = item.threadId;
            } else if (item.event === "thread.message.delta") {
              assistantMessage.content[0].type = "text";
              assistantMessage.content[0].text.value += item.response;
            } else if (item.event == "thread.run.imageSearch") {
              assistantMessage.content[0].type = "text";
              assistantMessage.content[0].text.value = item.response;
            } else if (item.event === "thread.run.completed")
              // assistantMessage.content[0].type = "text";
              // assistantMessage.content[0].text.value = item.response;
              isFreddyRequestSent.value = false;
            if (item.responseType == "image") {
              //Image response..
              assistantMessage.content[0].type = "image_url";
              assistantMessage.content[0].text.value = JSON.parse(
                item.response
              )[0];
            }
          });
          freddyResponseAvaiable.value = false;
          isFreddyRequestSent.value = false;
          nextTick(() => {
            messages.value = [...messages.value];
            console.log("abfeh", messages.value);
            if (chatContainer.value) {
              chatContainer.value.scrollTop = chatContainer.value.scrollHeight;
            }
          });
        }
      };

      await readStream();
    } catch (error) {
      freddyResponseAvaiable.value = false;
      isFreddyRequestSent.value = false;
      console.error("Error during streaming:", error);
      assistantMessage.content[0].text.value = "Sorry!! Please try again.";
      messages.value = [...messages.value];
    }
    nextTick(() => {
      adjustChatContainerScrollPoistion();
      //clearSelectedFiles();
    });
  }
};

const uploadImage = async () => {};

const clearSelectedFiles = () => {
  selectedFiles.value.splice(0, selectedFiles.value.length);
  fileObjectList.value.splice(0, fileObjectList.value.length);
};
const queryPreDefinedPrompt = (index: number) => {
  isPreDefinedQuery.value = true;
  newMessage.value = listOfDefaultPrompt.value[index];
};

const emptyThreadList = () => {
  if (isPreDefinedQuery.value) {
    isPreDefinedQuery.value = false;
    sendQuery();
  }
};

const assistantDashBoardOpened = () => {
  isAssistantView.value = true;
};

const assistantDashBoardClosed = () => {
  isPreDefinedQuery.value = false;
  messages.value = [];
  listOfDefaultPrompt.value = [];
  isAssistantView.value = false;
};

const selectCheckpoints = () => {
  isSelectCheckpoints.value = true;
};
const selectCheckpointsClosed = () => {
  isSelectCheckpoints.value = false;
};
const onTicketSelected = (id: number) => {
  selectedTicketId.value = id;

  console.log("selectedTicketId freddy assistant", selectedTicketId.value);
};

const convertTextToSpeech = async (): Promise<void> => {
  try {
    const response = await Axios.post(
      "https://api.deepgram.com/v1/speak?model=aura-orpheus-en", // Update this with the correct Deepgram TTS endpoint
      {
        text: textResponse.value,
      },
      {
        headers: {
          Authorization: `Token 99b9b8a70c205aa3c79cf05d7f139505f72622e9`,
          "Content-Type": "application/json",
        },
        responseType: "arraybuffer",
      }
    );

    const audioBlob = new Blob([response.data], { type: "audio/wav" });
    const audioUrl = URL.createObjectURL(audioBlob);

    if (audioPlayer.value) {
      if (audioPlayer.value) {
        audioPlayer.value.pause();
        audioPlayer.value.src = "";
      }
      audioPlayer.value.src = audioUrl;

      isFreddySpeaking.value = true;
      audioPlayer.value.addEventListener("ended", () => {
        isFreddySpeaking.value = false;
        console.log(
          "Audio has finished playing. isPlaying:",
          isFreddySpeaking.value
        );
      });
      audioPlayer.value.removeEventListener("ended", () => {});
      audioPlayer.value.play();
    }
  } catch (error) {
    console.error("Error converting text to speech:", error);
  }
};

const adjustTextareaHeight = (): void => {
  if (myTextArea.value) {
    myTextArea.value.style.height = "50px"; // Reset height to auto
    myTextArea.value.style.height = myTextArea.value.scrollHeight + "px"; // Set height to scroll height
  }
};

const adjustChatContainerScrollPoistion = (): void => {
  if (chatContainer.value) {
    chatContainer.value.scrollTo(0, chatContainer.value.scrollHeight);
  }
};

const callFreddy = async (): Promise<void> => {
  navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
    //transcribeAudio(stream);

    streamAudio.value = stream;
    mediaRecorder.value = new window.MediaRecorder(stream, {
      mimeType: "audio/webm",
    });
    socket.value = new WebSocket("wss://api.deepgram.com/v1/listen", [
      "token",
      "7dda75784e82dea1565e12995039dbb274b69e6e",
    ]);

    socket.value.onopen = async () => {
      if (mediaRecorder.value) {
        mediaRecorder.value.ondataavailable = (event) => {
          if (socket.value) socket.value.send(event.data);
        };
        mediaRecorder.value.start(250);
      }
    };

    socket.value.onmessage = async (response) => {
      const result = JSON.parse(response.data);
      if (result.channel && result.channel.alternatives) {
        var transcript = result.channel.alternatives[0].transcript;
        if (transcript.length) {
          console.log(transcript);
          newMessage.value = transcript;
          await sendQuery();
          userInterrupted.value = true;
        }
      }
    };

    socket.value.onclose = () => {
      console.log("WebSocket connection closed.");
    };
    socket.value.onerror = (error) => {
      console.error("WebSocket error: ", error);
    };
  });
};

const transcribeAudio = async (stream: MediaStream) => {
  const audioContext = new AudioContext();
  const source = audioContext.createMediaStreamSource(stream);
  const processor = audioContext.createScriptProcessor(4096, 1, 1);

  source.connect(processor);
  processor.connect(audioContext.destination);

  processor.onaudioprocess = async (event) => {
    const inputData = event.inputBuffer.getChannelData(0);
    const inputDataArrayBuffer = new ArrayBuffer(inputData.length * 2);
    const inputDataView = new DataView(inputDataArrayBuffer);

    for (let i = 0; i < inputData.length; i++) {
      inputDataView.setInt16(i * 2, inputData[i] * 0x7fff, true);
    }

    try {
      const response = await Axios.post(
        "https://api.deepgram.com/v1/listen",
        inputDataArrayBuffer,
        {
          headers: {
            Authorization: `Token 7dda75784e82dea1565e12995039dbb274b69e6e`,
            "Content-Type": "audio/wav",
          },
          responseType: "json",
        }
      );

      const result = JSON.parse(response.data);
      if (result.channel && result.channel.alternatives) {
        var transcript = result.channel.alternatives[0].transcript;
        if (transcript.length) {
          console.log(transcript);
          newMessage.value = transcript;
          await sendQuery();
          userInterrupted.value = true;
        }
      }
    } catch (error) {
      console.error("Error transcribing audio:", error);
    }
  };
};

const changeChatMode = (event: Event) => {
  const htmlSelect = event.target as HTMLSelectElement;
  const selectedItem = htmlSelect.selectedIndex;
};

const newThread = () => {
  messages.value = [];
};

const onItemSelected = (item: IComponentMap) => {
  isTextChatEnabled.value = item.title === "Text" ? true : false;
};

const startCall = () => {
  isLoading.value = true; //--> Make isLoading true when call connected when it is ready.

  setTimeout(() => {
    isLoading.value = false;
    isCallingProgress.value = true;
    callFreddy();
    //recognizeFaces();
  }, 1000);
};

const pauseCall = () => {
  //--> handle pause call;
};

const onSettingsMenuOpen = () => {
  selectedSettingsPageComponent.value = settingsMenuComponents.value[0];
  isSelectCheckpoints.value = false;
  isSettingsViewEnabled.value = true;
};

const onAssistantSelected = async () => {
  try {
    let presetPrompts = await assistantService.GetAssistantPresetPrompts();

    if (
      Array.isArray(presetPrompts) &&
      presetPrompts.every((item) => typeof item === "string")
    ) {
      listOfDefaultPrompt.value = presetPrompts;
    } else {
      listOfDefaultPrompt.value = [];
    }
  } catch {
    console.log("no presets found");
  }
};

const onSettingsMenuClosed = () => {
  isSettingsViewEnabled.value = false;
};
const settingsItemSelected = (item: ISettingsMenuItem) => {
  selectedSettingsPageComponent.value = settingsMenuComponents.value.filter(
    (x) => x.title == item.text
  )[0];
  isOpened.value = !isOpened.value;
  return isOpened.value;
};

const endCall = () => {
  closeSideChat();
  closeVideo();
  isCallingProgress.value = false;

  disconnectAudio();

  if (mediaRecorder.value) mediaRecorder.value.stop();
  if (socket.value) socket.value.close();
  stoprecognizeFaces();
};

const disconnectAudio = () => {
  if (streamAudio.value) {
    const tracks = streamAudio.value.getTracks();
    tracks.forEach((track: MediaStreamTrack) => track.stop());
    if (audioPlayer.value) {
      audioPlayer.value.pause(); // Pause the audio element
      audioPlayer.value.srcObject = null; // Clear the srcObject
    }
    streamAudio.value = null; // Clear the stream reference
  }
};

const disconnectVideo = () => {
  if (streamVideo.value) {
    const tracks = streamVideo.value.getTracks();
    tracks.forEach((track: MediaStreamTrack) => track.stop());
    if (videoPlayer.value) {
      videoPlayer.value.pause();
      videoPlayer.value.srcObject = null;
    }
    streamVideo.value = null; // Clear the stream reference
  }
};

const startVideo = () => {
  isVideoEnabled.value = true;
  recognizeFaces();
  if (!isCallingProgress.value) startCall();
};

const closeVideo = () => {
  isVideoEnabled.value = false;
  disconnectVideo();
};

const editQuery = () => {
  console.log("editQuery");
  //-->> TODO  Edit the message and resend the query to generate new response
};

const copyResponse = (textToCopy: IMessageContent) => {
  navigator.clipboard
    .writeText(textToCopy.text.value)
    .then(() => {
      console.log("copyResponse");
      setTimeout(() => {}, 1000);
    })
    .catch((error) => {
      console.error("Could not copy text: ", error);
    });
};

const stoprecognizeFaces = () => {
  clearInterval(facialSearchIntervalId.value);
};

const recognizeFaces = async (): Promise<void> => {
  if (!videoPlayer.value || !canvasHidden.value) return;

  const context = canvasHidden.value.getContext("2d");

  navigator.mediaDevices
    .getUserMedia({ video: true })
    .then((stream) => {
      streamVideo.value = stream;
      if (videoPlayer.value) videoPlayer.value.srcObject = stream;
    })
    .catch((error) => {
      console.error("Error accessing camera: ", error);
    });

  facialSearchIntervalId.value = setInterval(() => {
    searchFaces(context);
  }, 5000);
};

const searchFaces = (context: CanvasRenderingContext2D | null) => {
  if (isFreddySpeaking.value || !isVideoEnabled.value) return;

  const imageDataUrl = captureImageFromVideo(context);

  fetch("https://freddy-api.aitronos.ch/v1/facial/search-faces", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ image: imageDataUrl }),
  })
    .then((response) => response.json())
    .then((data) => {
      const employeeId = data?.id;
      const employeeName = data?.name;
      if (!employeeId || employeeId == "no-face") {
        return;
      }

      if (employeeId == "no-match") {
        textResponse.value = getFreddyFacialResponse("");
        convertTextToSpeech();
      } else if (!checkCookieExists(employeeId)) {
        setFreddyCookie(employeeId);
        textResponse.value = getFreddyFacialResponse(employeeName);
        convertTextToSpeech();
      }
    })
    .catch((error) => {
      //textResponse.value = getFreddyFacialResponse('');
      //convertTextToSpeech();
    });
};

const captureImageFromVideo = (
  context: CanvasRenderingContext2D | null
): string => {
  if (!videoPlayer.value || !canvasHidden.value) {
    return "";
  }

  context?.drawImage(
    videoPlayer.value,
    0,
    0,
    canvasHidden.value.width,
    canvasHidden.value.height
  );
  const imageDataUrl = canvasHidden.value.toDataURL("image/jpeg");
  return imageDataUrl;
};

const getFreddyFacialResponse = (employeeName: string): string => {
  const text = employeeName
    ? `Hello ${employeeName}, how are you doing?` //Welcome to the party.
    : `Sorry, I couldn't recogonize you. Please contact the help desk.`;
  let message: IMessageContent[] = [
    {
      type: "text",
      text: {
        value: text,
      },
    },
  ];
  messages.value.push({
    id: 1,
    roleId: 2,
    content: message,
    createdOn: Date.now(),
    tokens: 0,
    messageTypeId: 1,
  });

  return text;
};

const setFreddyCookie = (value: string) => {
  let date = new Date();
  const expiryMinutes = 5;
  const name = "Freddy-" + value;
  date.setTime(date.getTime() + expiryMinutes * 60 * 1000);
  let expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
};

const checkCookieExists = (value: string): boolean => {
  const name = "Freddy-" + value;
  var cookieIndex = document.cookie.indexOf(name);
  return cookieIndex >= 0;
};

const reloadResponse = () => {
  console.log("reloadResponse");
  //-->> TODO  modify the response
};

const timeStampToDateTime = (timestamp: number): string => {
  const date = new Date(timestamp * 1000);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;

  return formattedDate;
};

const uplodFiles = () => {
  if (fileInput.value) fileInput.value.click();
};

const removeSelectedFile = (index: number, file: File) => {
  selectedFiles.value.splice(index, 1);
  fileObjectList.value.splice(index, 1);
};

const handleFileChange = (event: Event) => {
  const input = event.target as HTMLInputElement;
  const files = input.files;
  let filetype = "text";

  if (files) {
    selectedFiles.value = Array.from(files) as File[];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const mimeType = file.type;

      // Detect by MIME type
      if (mimeType.startsWith("image/")) filetype = "Image_url";
      if (mimeType.startsWith("video/")) filetype = "Video";
      if (mimeType === "application/pdf") filetype = "PDF";
      if (mimeType.startsWith("audio/")) filetype = "Audio";
      if (
        mimeType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
        filetype = "Excel";

      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result?.toString(); // Extract base64 content

        const fileObj = {
          id: i,
          name: file.name,
          type: filetype,
          content: base64String,
        };

        fileObjectList.value.push(fileObj);
      };

      reader.onerror = (error) => {
        console.error("Error converting file to base64:", error);
      };

      reader.readAsDataURL(file);
    }
  }

  adjustTextareaHeight();
};

const openSideChat = () => {
  sideChatOpen.value = true;
};

const closeSideChat = () => {
  sideChatOpen.value = false;
};

const handleScroll = async (event: any) => {
  if (!chatContainer.value || !messages.value.length) return;
  if (chatContainer.value.scrollTop === 0) {
    let messageList = await getMessages(messages.value.length);
    if (messageList.length === 0) {
      event.preventDefault();
      return;
    }
    messages.value.unshift(...messageList);
    // Adjust scroll position to maintain relative position
    chatContainer.value.scrollTop =
      chatContainer.value.scrollHeight - chatContainer.value.offsetHeight;
  }
};

const getMessages = async (skip: number): Promise<Array<IMessageResponse>> => {
  let freddyService = new FreddyService();
  if (!AppConfigGlobal.CurrentThreadId) return [];

  let messageResponse: Array<IMessageResponse> =
    await freddyService.Get_Messages(skip);

  return messageResponse.reverse();
};

const onThreadChanged = async () => {
  messages.value = [];
  let newMessageList = await getMessages(0);
  messages.value = await [...newMessageList];
  nextTick(() => {
    adjustChatContainerScrollPoistion();
  });

  if (isPreDefinedQuery.value) {
    isPreDefinedQuery.value = false;
    sendQuery();
  }
};

const preventBackNavigation = () => {
  window.history.pushState(null, "", window.location.href);
};

onMounted(async () => {
  messages.value = [];
  messages.value = await getMessages(0);
  window.history.pushState(null, "", window.location.href);
  window.addEventListener("popstate", preventBackNavigation);
  //const config = await userService.GetUserConfiguration();
  //sessionStorage.setItem("orgid", config.id.toString());
  //userName.value = AppConfigGlobal.UserName;
  //userConfiguration.value = await userService.GetUserConfiguration();

  //    if (!userConfiguration.value.organizations) {
  //    console.log("There is no organization for this user.");
  //    return;
  //    }

  //    AppConfigGlobal.UserId = userConfiguration.value.id;
  //    AppConfigGlobal.UserName = userConfiguration.value.name;
  //    AppConfigGlobal.UserImage = userConfiguration.value.image;
  //    AppConfigGlobal.Organizations = userConfiguration.value.organizations;
  if (AppConfigGlobal.CurrentOrganizationId) {
    assistantDashBoardOpened;
  }
});

const toggleDashboard = () => {
  isVisible.value = !isVisible.value;
  return isVisible.value;
};

const handleInterruption = (event: Event) => {
  const audioPlayer = event.target as HTMLAudioElement;
  if (audioPlayer) {
    if (isUserInterruption()) {
      audioPlayer.pause();
      callFreddy();
    } else {
      // Continue playing audio

      audioPlayer.addEventListener(
        "ended",
        () => {
          callFreddy();
        },
        { once: true }
      );
    }
  }
};
const isUserInterruption = (): boolean => {
  // Check if there was a user interruption
  return userInterrupted.value;
};
watch(
  () => messageStream.value,
  (newMessages, oldMessages) => {
    let messageObject: IMessageRun[];

    if (typeof newMessages === "string") {
      // Check if newMessages ends with ']', if not, append ']'
      const validJsonString = newMessages.trim().endsWith("]")
        ? newMessages
        : `${newMessages}]`;

      try {
        messageObject = JSON.parse(validJsonString) as IMessageRun[];
      } catch (error) {
        console.error("Invalid JSON:", error);
        return;
      }
    } else {
      messageObject = newMessages as IMessageRun[];
    }

    // Map through messageObject and concatenate text
    let concatenatedText = messageObject.map((x) => x.text).join("");

    if (messages.value.length > 0) {
      messages.value[messages.value.length - 1].content.push({
        type: "text",
        text: { value: concatenatedText },
      });
    }

    textResponse.value =
      messages.value[messages.value.length - 1].content[0].text.value;

    if (!isTextChatEnabled.value) {
      convertTextToSpeech();
    }
  }
);

watch(
  () => AppConfigGlobal.CurrentThreadId,
  (newValue: number) => {
    if (isMessageResponseThreadIdChange.value && newValue > 0) {
      isMessageResponseThreadIdChange.value = false;
      return;
    }
    if (newValue == 0) {
      newThread();
      return;
    }
    onThreadChanged();
  }
);
const approveOrDeclineTrigger = ref(false);
function approveOrDecline() {
  approveOrDeclineTrigger.value = true;
  console.log(
    "in Freddy assistant 3 --- value of approveOrDeclineTrigger ",
    approveOrDeclineTrigger.value
  );
}

//--> watch the message changes here
// watch(
//   () => messageStream.value,
//   (newMessages, oldMessages) => {
//     let messageObject =
//       typeof newMessages == "string"
//         ? (JSON.parse(
//             newMessages[-1] !== "]" ? (newMessages += "]") : (newMessages += "")
//           ) as IMessageRun[])
//         : (newMessages as IMessageRun[]);

//     // messageObject.map(x => messages.value[messages.value.length - 1].content.push({type:'text',
//     //     text:{value: x.text}
//     // }));
//     let concatenatedText = messageObject.map((x) => x.text).join("");

//     if (messages.value.length > 0) {
//       messages.value[messages.value.length - 1].content.push({
//         type: "text",
//         text: { value: concatenatedText },
//       });
//     }
//     textResponse.value =
//       messages.value[messages.value.length - 1].content[0].text.value;
//     if (!isTextChatEnabled) {
//       convertTextToSpeech();
//   });
//watch(() => textResponse.value, (newMessages, oldMessages) => {
//    if (isCallingProgress.value) convertTextToSpeech();
//})
</script>
<style>
#editor p {
  white-space: pre-wrap;
}

#editor code {
  white-space: pre-wrap;
}
</style>
