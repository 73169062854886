<template>
  <div>
    <i class="bi bi-pencil"></i>
  </div>
</template>

<script>
import 'bootstrap-icons/font/bootstrap-icons.css';
export default {
};
</script>

<style>
    .bi-pencil {
        color: rgb(177, 168, 168);
    }

    .bi-pencil:hover {
        color: white;
        cursor: pointer;
    }
</style>
