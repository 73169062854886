import type { IUserForgotPasswordRequest } from "@/Models/Common/IUserForgotPasswordRequest";
import Core from "./Core";
import AppConfigGlobal from "../core/config/uiSettings"
import { useRouter, useRoute } from "vue-router";
import Service from '../Services/Core';
import type { AxiosRequestConfig } from "axios";
import type { IPasswordResetRequest } from "../Models/Common/IPasswordResetRequest";
import type { IPasswordUpdateRequest } from "../Models/Common/IPasswordUpdateRequest";
import type { IUserAuthRequest } from "../Models/Request/User/IUserAuthRequest";
import { onMounted, ref } from "vue";

interface LoginResponse {
    token: string;
    refreshToken: RefreshToken;
    deviceId: string;
    responseMessage: string;
}

interface RefreshedTokenResponse {
    token: string;
    deviceId: string;
}

interface RefreshToken {
    token: string;
    expiry: Date;
}

interface ResetPasswordResponse {
    resetSuccess: boolean;
}
interface ForgotPasswordResponse {
    confirm: boolean;
}
interface UserAuthRequestViewModel {
    userId?: number;
    emailorusername: string;
    password: string;
    oldPassword?: string;
    newPassword?: string;
}


export interface UserResendVerificationModel {
    email: string;
    fullName: string;
    userId: number;
}
export interface UserRegisterModel {
    email: string;
    fullName: string;
    password: string;
}

export interface UserTokenVerificationViewModel {
    emailKey: string;
    token: number;
}

interface UserTokenVerificationResponse {
    isVerified: boolean;
}

export interface UserRegisterResponse {
    userId: number;
    emailKey: string;
}

export interface UserPasswordResetRequest {
    newPassword: string;
    confirmPassword: string;
}

const emailkey = ref<string>("");

onMounted(() => {
    emailkey.value = window.location.search; //route.query.emailkey;
});

class AuthService extends Core {

    public logIn(userAuthRequestViewModel: IUserAuthRequest): Promise<LoginResponse> {
        const url = `${AppConfigGlobal.FreddyAssistantApiUrl}/auth/login`;
        const data = userAuthRequestViewModel;
        console.log(data.deviceInformation);

        const deviceId = this.GetCookieValue("deviceId");
        if (deviceId) {
            const config: AxiosRequestConfig = {
                headers: {
                    'deviceId': deviceId
                }
            };
            return this.PerformPost<LoginResponse>(url, data, config);
        }
        return this.PerformPost<LoginResponse>(url, data);
    }

    public async Register(userAuthRegisterModel: UserRegisterModel): Promise<UserRegisterResponse> {
        const url = `${AppConfigGlobal.UserAuthUrl}/v1/user/register`;
        const data = userAuthRegisterModel;
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': undefined,  // Explicitly remove Authorization
            }
        }
        delete config.headers['Authorization'];
        const res = await this.PerformPost<UserRegisterResponse>(url, data, config);
        return res;
    }

    public async ResendVerificationEmail(userResendVerificationModel:UserResendVerificationModel ): Promise<UserRegisterResponse> {
        const url = `${AppConfigGlobal.UserAuthUrl}/v1/resendemail`;
        const data = userResendVerificationModel;
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': undefined,  // Explicitly remove Authorization
            }
        }
        delete config.headers['Authorization'];
        const res = await this.PerformPost<UserRegisterResponse>(url, data, config);
        console.log("sahabdjhadabd",res);
        return res;
    }

    public async VerifyUser(userTokenVerificationViewModel: UserTokenVerificationViewModel): Promise<UserTokenVerificationResponse> {
        const url = `${AppConfigGlobal.UserAuthUrl}/v1/token/verify`;
        const data = userTokenVerificationViewModel;
        const config = {

            headers: {
                'Content-Type': 'application/json',
                'Authorization': undefined,  // Explicitly remove Authorization
            }
        }
        delete config.headers['Authorization'];
        return await this.PerformPost<UserTokenVerificationResponse>(url, data, config);
    }

    public ForgotPassword(userAuthRequest: IUserForgotPasswordRequest): Promise<ResetPasswordResponse> {
        const url = `${AppConfigGlobal.UserAuthUrl}/auth/users/${userAuthRequest.Uid}/passwordReset`;
        const data = {
            uid: userAuthRequest.Uid,
            email: userAuthRequest.Email,
            password: userAuthRequest.Password,
            oldPassword: userAuthRequest.OldPassword,
            newPassword: userAuthRequest.NewPassword
        };

        return this.PerformPost<ResetPasswordResponse>(url, data);
    }

    public ResetPassword(newPassword: string, confirmPassword: string, emailkey: string): Promise<string> {
        const url = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/user/password/reset`;
        const data = {
            NewPassword: newPassword,
            ConfirmPassword: confirmPassword,
            EmailKey: emailkey
        }
        return this.PerformPut<string>(url, data);
    }

    public SendPasswordResetUrl(email: string): Promise<string> {
        const url = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/user/sendreseturl`;
        const data = { email: email };

        return this.PerformPost<string>(url, data);
    }

    public GetRefreshToken(refreshtoken: string, expiry: Date, deviceId: string): Promise<RefreshedTokenResponse> {
        const url = `${AppConfigGlobal.UserAuthUrl}/auth/refresh`;
        const refreshToken: RefreshToken = {
            token: refreshtoken,
            expiry: expiry
        }
        const data = refreshToken;
        const config: AxiosRequestConfig = {
            headers: {
                'deviceId': deviceId
            }
        };
        return this.PerformPost<RefreshedTokenResponse>(url, data, config);
    }
}

export default AuthService;