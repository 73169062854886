<template>
    <form class="flex flex-col border border-menuSelected p-4 rounded-[10px] gap-6 w-full py-5" @submit.prevent="handleSubmit">
        <h2 class="text-[17px] font-medium">Update your personal information</h2>
        <div class="flex flex-row items-center justify-between gap-2 :md:flex-col mb-2">
            <label for="current-password" class="text-white text-[14px] font-medium">Current password</label>
            <div class="flex flex-col w-full md:w-[70%] gap-1.5">
                <div class="relative">
                    <input :type="isCurrentPasswordVisible ? 'text' : 'password'"
                           id="current-password"
                           v-model="currentPassword"
                           class="w-full py-2.5 px-3.5 bg-backgroundSidebar border border-menuSelected rounded-lg"
                           />
                    <span class="absolute w-4 h-4 transform -translate-y-1/2 right-3 top-1/2">
                        <IconEyeSlash v-if="!isCurrentPasswordVisible" @click="togglePasswordVisibility('current')" :class="{'opacity-50 cursor-default': !currentPassword}"  /> 
                        <IconEye v-else  @click="togglePasswordVisibility('current')" :class="{'opacity-50 cursor-default': !currentPassword}" />                        
                    </span>
                </div>
            </div>
        </div>
        <div class="flex flex-row items-center justify-between gap-2 :md:flex-col mb-2">
            <label for="new-password" class="text-white text-[14px] font-medium">New password</label>
            <div class="flex flex-col w-full md:w-[70%] gap-1.5">
                <div class="relative">
                    <input :type="isNewPasswordVisible ? 'text' : 'password'"
                           id="new-password"
                           v-model="newPassword"
                           class="w-full py-2.5 px-3.5 bg-backgroundSidebar border border-menuSelected rounded-lg"
                           />
                    <span class="absolute w-4 h-4 transform -translate-y-1/2 right-3 top-1/2">
                        <IconEyeSlash v-if="!isNewPasswordVisible" @click="togglePasswordVisibility('new')" :class="{'opacity-50 cursor-default': !newPassword}" />
                        <IconEye v-else  @click="togglePasswordVisibility('new')" :class="{'opacity-50 cursor-default': !newPassword}" />                        
                    </span>
                </div>
                <p class="text-xs text-textGrey">Your new password must be more than 8 characters and have a special symbol.</p>
            </div>
        </div>
        <div class="flex flex-row items-center justify-between gap-2 :md:flex-col">
            <label for="confirm-password" class="text-white text-[14px] font-medium">Confirm new password</label>
            <div class="flex flex-col w-full md:w-[70%] gap-1.5">
                <div class="relative">
                    <input :type="isConfirmPasswordVisible ? 'text' : 'password'"
                           id="confirm-password"
                           v-model="confirmPassword"
                           class="w-full py-2.5 px-3.5 bg-backgroundSidebar border border-menuSelected rounded-lg"
                           />
                    <span class="absolute w-4 h-4 transform -translate-y-1/2 right-3 top-1/2">
                        <IconEyeSlash v-if="!isConfirmPasswordVisible" @click="togglePasswordVisibility('confirm')" :class="{'opacity-50 cursor-default': !confirmPassword}" />
                        <IconEye v-else @click="togglePasswordVisibility('confirm')" :class="{'opacity-50 cursor-default': !confirmPassword}" />                        
                    </span>
                </div>
            </div>
        </div>
        <div class="flex justify-end">
            <p id="error-message" class="text-xs text-red-500"></p>
        </div>
        <div class="flex justify-start gap-3">
            <button type="button" class="rounded-lg border border-buttonBlue py-2.5 px-3.5 flex items-center justify-center relative overflow-hidden" @click="clearForm" :disabled="!isFormChanged" :class="{ 'text-buttonBlue cursor-default': !isFormChanged, 'cursor-pointer':isFormChanged }">Cancel</button>
            <button type="submit" class="rounded-lg border border-buttonBlue py-2.5 px-3.5 flex items-center justify-center shadow-xs overflow-hidden bg-buttonBlue" :disabled="!isFormChanged" :class="{ 'text-background cursor-default': !isFormChanged, 'cursor-pointer':isFormChanged }">Update password</button>
        </div>
    </form>
</template>

<script setup lang="ts">
    import { ref, defineEmits ,computed  } from 'vue';
    import IconEye from '@/components/icons/IconEye.vue';
    import IconCircle from '@/components/icons/IconCircle.vue';
    import IconEyeSlash from '@/components/icons/IconEyeSlash.vue';
    import { UserService } from '../../../Services/UserService';

    const userService = new UserService;

    const currentPassword = ref('');
    const newPassword = ref('');
    const confirmPassword = ref('');

    const isFormChanged = computed(() => {
    return (
        currentPassword.value !== '' ||
        newPassword.value !== '' ||
        confirmPassword.value !== ''
    );
    });

    const togglePasswordVisibility = (type: 'current' | 'new' | 'confirm') => {
        if (type === 'current' && currentPassword.value) {
            isCurrentPasswordVisible.value = !isCurrentPasswordVisible.value;
        } else if (type === 'new' && newPassword.value) {
            isNewPasswordVisible.value = !isNewPasswordVisible.value;
        } else if (type === 'confirm' && confirmPassword.value) {
            isConfirmPasswordVisible.value = !isConfirmPasswordVisible.value;
        }
    };

    const isCurrentPasswordVisible = ref<boolean>(false);
    const isNewPasswordVisible = ref<boolean>(false);
    const isConfirmPasswordVisible = ref<boolean>(false);
    const emit = defineEmits(['password-updated']); // Define the event

    const handleSubmit = async () => {
    clearErrors();
    // Check if any of the password fields are empty
    if (!currentPassword.value || !newPassword.value || !confirmPassword.value) {
        displayError('All fields are required.');
        return;
    }

    // Validate that the new password and confirm password are the same
    if (newPassword.value !== confirmPassword.value) {
        displayError('New password and confirm password do not match.');
        return;
    }

    // Additional password strength validation (optional)
    if (!validatePasswordStrength(newPassword.value)) {
        displayError('Password must meet strength requirements (e.g., at least 8 characters, 1 uppercase letter, etc.).');
        return;
    }

    try {
        let isSuccess = await userService.UpdatePassword(currentPassword.value, newPassword.value, confirmPassword.value);
        if (!isSuccess) throw new Error("Password update failed.");
        
        clearForm();
        emit('password-updated');
    }
    catch (e:any) {
        if(e.response.data === 'Incorrect password'){
            displayError("Current password does not match");
        }
        else{
            displayError("Can't update password at this moment. Please try again.");
        } 
    }
};

    
const displayError = (message: string, elementId: string = 'error-message') => {
    const errorElement = document.getElementById(elementId);
    if (errorElement) {
        errorElement.innerText = message;

        
        setTimeout(() => {
            clearErrors(elementId);
        }, 2000);
    } else {
        console.error('Error element not found');
    }
};



const clearErrors = (elementId: string = 'error-message') => {
    const errorElement = document.getElementById(elementId);
    if (errorElement) {
        errorElement.innerText = '';
    } else {
        console.warn(`Error element with ID ${elementId} not found`);
    }
};





const validatePasswordStrength = (password: string): { isValid: boolean, message: string } => {
    if (password.length < 8) {
        return { isValid: false, message: "Password must be at least 8 characters long." };
    }

    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        return { isValid: false, message: "Password must contain at least one special character." };
    }

    return { isValid: true, message: "" };  
};

    const clearForm = () => {
        currentPassword.value = '';
        newPassword.value = '';
        confirmPassword.value = '';
    }

    // const showCurrentPassword = () => {
    //     isCurrentPasswordVisible.value = !isCurrentPasswordVisible.value;
    // };

    // const showNewPassword = () => {
    //     isNewPasswordVisible.value = !isNewPasswordVisible.value;
    // };

    // const showConfirmPassword = () => {
    //     isConfirmPasswordVisible.value = !isConfirmPasswordVisible.value;
    // };

</script>

<style scoped>
    /* .password-form {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
 */
    /* .form-group {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: space-between;
    } */

    /* .form-label {
        color: #fff;
    } */

    /* .input-wrapper-block {
        display: flex;
        flex-direction: column;
        width: 70%;
        gap: 6px;
    } */

    /* .input-wrapper {
        position: relative;
    } */

    /* .form-input {
        width: 100%;
        padding: 10px 14px;
        color: #cbd6e3;
        background-color: #11385b;
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-radius: 8px;
    } */

    /* .input-icon {
        position: absolute;
        right: 14px;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
    } */

    /* .input-hint {
        color: rgba(255, 255, 255, 0.2);
    } */

    /* .form-actions {
        display: flex;
        justify-content: flex-end;
        gap: 12px;
        margin-top: 20px;
    } */

    /* .btn {
        padding: 10px 14px;
        border-radius: 8px;
        cursor: pointer;
    }

    .btn-secondary {
        color: #071a2b;
        background-color: #fff;
        border: 1px solid rgba(255, 255, 255, 0.09);
    }

    .btn-primary {
        color: #fff;
        background-color: #7ba8ef;
        border: 1px solid rgba(255, 255, 255, 0.4);
    } */

    /* .section-divider {
        background-color: rgba(255, 255, 255, 0.40);
        border: none;
        height: 1px;
        margin: 24px 0;
    }
 */
    /* .error-message {
    color: red;
    margin-top: 10px;
    } */


    /* @media (max-width: 991px) {
        .form-group {
            flex-direction: row;
        }
    } */
</style>