<template>
    <div class="action-buttons">
        <button @click="cancel" class="cancel-button textMdSemibold">Cancel</button>
        <button @click="leave" class="leave-button textMdSemibold">Leave</button>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ActionButtons',
  methods: {
    cancel() {
      this.$emit('cancel'); // Emitting an event that can be handled by the parent component
    },
    leave() {
      this.$emit('leave'); // Emitting an event that can be handled by the parent component
    },
  },
});
</script>

<style scoped>
    .action-buttons {
        display: flex;
        gap: 12px;
    }

    .cancel-button, .leave-button {
        padding: 10px 14px;
        border-radius: 8px;
        font: 600 14px/1 Inter, sans-serif;
        cursor: pointer;
    }

    .cancel-button {
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid rgba(255, 255, 255, 0.4);
        color: #fff;
    }

    .leave-button {
        background: #f14d4d;
        border: 1px solid rgba(255, 255, 255, 0.4);
        color: #fff;
    }
</style>
