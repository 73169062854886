<template>
  <form class="paragraph search-chat-input mt-4" @submit.prevent>
    <label for="searchInput" class="hidden">Search for a Chat</label>
    <div class="search-content rounded-lg border border-[#FFFFFF17] flex">
      <img
        loading="lazy"
        src="@/assets/search-icon.svg"
        class="search-icon ml-2"
        alt=""
      />
      <input
        type="text"
        id="searchInput"
        class="search-text p-2 bg-transparent focus:outline-none"
        v-model="searchText"
        placeholder="Search for a Chat"
        aria-label="Search for a Chat"
        autocomplete="off"
        @input="searchThread"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
import { ref, defineEmits } from "vue";

const emits = defineEmits(["searchThread"]);

const searchText = ref<string>("");

const searchThread = () => {
  emits("searchThread", searchText.value);
};
</script>

<!--
<style scoped>
    .search-chat-input {
        border-radius: 12px;
        background-color: rgba(7, 26, 43, 1);
        display: flex;
        align-items: center;
        overflow: hidden;
        color: var(&#45;&#45;Text-Graph, rgba(255, 255, 255, 0.4));
        letter-spacing: 0.5px;
        padding: 9px 14px;
        border: 1px solid rgba(208, 213, 221, 1);
        margin-right: 20px;
    }

    .search-content {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 8px;
    }

    .search-icon {
        aspect-ratio: 1;
        object-fit: contain;
        object-position: center;
        width: 20px;
    }

    .search-text {
        flex: 1;
        background: transparent;
        border: none;
        color: inherit;
        font: inherit;
        outline: none;
    }

    .visually-hidden {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
    }
</style>-->
