<template>
    <div class="icon-send">
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path d="M15.7493 20.2503L31.4993 4.50026M15.9407 20.7424L19.8828 30.8794C20.2301 31.7724 20.4037 32.2189 20.654 32.3492C20.8709 32.4623 21.1292 32.4624 21.3463 32.3497C21.5966 32.2196 21.7708 31.7733 22.1191 30.8807L32.0046 5.54906C32.3191 4.74329 32.4763 4.3404 32.3903 4.08296C32.3156 3.85939 32.1402 3.68393 31.9166 3.60924C31.6591 3.52324 31.2563 3.68046 30.4505 3.99491L5.11887 13.8804C4.22626 14.2288 3.77995 14.4029 3.64989 14.6533C3.53713 14.8703 3.53729 15.1287 3.65029 15.3456C3.78065 15.5958 4.22716 15.7694 5.12018 16.1167L15.2572 20.0589C15.4385 20.1294 15.5291 20.1646 15.6054 20.2191C15.6731 20.2673 15.7322 20.3265 15.7805 20.3941C15.8349 20.4704 15.8702 20.5611 15.9407 20.7424Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
    .icon-send {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        color: rgb(177, 168, 168);

        &:hover{
            cursor: pointer;
        }
    }
</style>