<template>
    <aside class="bg-backgroundSidebar flex flex-col h-full w-full gap-4 pl-0 pr-1 pt-2 pb-1 m-0">
        <header class="flex w-full items-center justify-between">
            <h1 class="text-[23px] text-white px-3 py-0">Automation Checkpoints</h1>
            <img src="../../../assets/arrow-left.svg"
                 alt="Assistant icon"
                 class="hover:cursor-pointer w-7"
                 @click="closeView" />
        </header>
        <header class="h7Regular grid grid-cols-[1fr] text-white">
            <section class="bodySmall text-white text-center text-xl not-italic font-bold leading-[normal] h-[76vh] grid items-center py-0">
                <div class="h-full grid items-center justify-center grid-rows-[97%_10%]" v-if="tickets.length === 0">
                    <div>
                        No tickets open for Checkpoints processing <br />
                    </div>
                    <div class="text-[17px] font-medium leading-[20.574px]">
                        <span> No tickets currently processing </span>
                    </div>
                </div>
                <div class="h-full grid content-center grid-rows-[10%_90%] gap-y-0.5" v-else>
                    <div class="flex bg-[rgb(33,48,64)] items-baseline flex-col px-1.5 py-px rounded-xl h-[40px]">
                        <span class="text-[#9c9c9c] text-[1.2em]"> </span>
                        <input v-model="searchQuery" placeholder="Search" class="flex-1 text-[white] bg-[rgb(33,48,64)] ml-2 border-[none] pl-4 outline-none"/>
                    </div>
                    <div class="h-fit overflow-y-auto text-left grid gap-y-[13px] cursor-pointer">
                        <div class="grid bg-[#031525] h-fit grid-cols-[auto_auto] gap-2 text-[medium] p-3 rounded-xl justify-start" v-for="ticket in tickets" :class="{ ' bg-[rgb(17,56,91)]': selectedTicketId === ticket.id }" :key="ticket.id" @click="selectTicket(ticket)">                            <div>
                                <img src="../../../assets/ticket-icon.svg" alt="" srcset="">
                            </div>
                            <div>
                                {{ ticket.title }}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </header>
    </aside>
</template>

<script setup lang="ts">
    import { ref, onMounted, defineEmits, watch, computed } from "vue";
    import { CheckPointsService } from "@/Services/CheckPointsService";
    import type { ITickets } from "@/Models/CheckPoints/ITickets";
    const emits = defineEmits(["onCheckPointDashBoardClosed", "onTicketSelected"]);
    const tickets = ref<ITickets[]>([]);
    const service = new CheckPointsService();
    const selectedTicketId = ref(0);
    const props = defineProps({
       approveOrDeclineTrigger: {
         type: Boolean,
         default: false,
         required: false
       }
   })
    const closeView = () => {
        emits("onCheckPointDashBoardClosed");
    };

    const searchQuery = ref("");
    onMounted(() => {
        GetallTickets();
    });

const selectTicket = (ticket: ITickets) => {
  emits("onTicketSelected", ticket.id);
  selectedTicketId.value = ticket.id;
}
const GetallTickets = async () => {
  tickets.value = await service.getAllCheckPointsTickets();
  if (tickets.value.length > 0) {
    selectTicket(tickets.value[0]);
  } 
};

const isApproveOrDecline = ref<boolean>(false);

watch(() => props.approveOrDeclineTrigger, () => {
    console.log("watching changes of CheckpointsDashboard ----- end", isApproveOrDecline.value);
    GetallTickets();
})
</script>

<!-- <style scoped lang="postcss">

    .side-menu {
        @apply bg-[#071a2b] flex flex-col h-full w-full gap-4 pl-0 pr-1 pt-2 pb-1 m-0;
    }

    .dashboard-header {
        @apply flex w-full items-center justify-between pr-5;
    }

    .dashboard-title {
        @apply text-white px-3 py-0;
    }

    .organization-icon:hover {
        @apply cursor-pointer;
    }

    .user-header {
        @apply grid grid-cols-[1fr] text-white pr-5;
    }

    .supporting-text2 {
        @apply text-white text-center text-xl not-italic font-bold leading-[normal] h-[76vh] grid items-center px-3 py-0;
        /* Heading 6 */
        font-family: Inter;
    }

    .run-ticketsBtn {
        @apply bg-transparent text-[#a7d0f8] cursor-pointer rounded-[5px] border-[none];
    }

    .no-open-tickets {
        @apply h-full grid items-center justify-center grid-rows-[97%_10%];
    }

    .no-tickets {
        @apply text-[17px] font-medium leading-[20.574px];
    }

    .open-tickets {
        @apply h-full grid content-center grid-rows-[10%_90%] gap-y-0.5;
    }

    .tickets-list {
        @apply h-fit overflow-y-auto text-left grid gap-y-[13px] cursor-pointer;
    }

    .ticket {
        @apply grid bg-[#031525] h-fit grid-cols-[auto_auto] gap-2 text-[medium] p-3 rounded-xl;
        justify-content: start;
    }

    .search-bar {
        @apply flex bg-[rgb(33,48,64)] items-baseline flex-col mb-2.5 px-1.5 py-px rounded-xl;
    }

        .search-bar input {
            @apply flex-1 text-[white] ml-2 border-[none];
            background: none;
            outline: none;
        }

    .search-icon {
        @apply text-[#9c9c9c] text-[1.2em];
    }

    .ticket-active {
        @apply bg-[rgb(17,56,91)];
    }
</style> -->
