<template>
    <div class="bg-custom-bg bg-cover bg-center w-screen h-screen flex justify-center items-center">
        <div class="bg-[var(--color-background)] w-[22.75rem] flex justify-center items-center p-8 rounded-[2.5rem] border-[0.188rem] border-lightBlue">
            <div class=" flex justify-center items-center gap-5">
                <div class="flex flex-col">
                    <LoadingSpinner :is-spin-loading="isSpinLoading" v-if="isSpinLoading" />
                <div class="mt-5">
                    <h4 class="text-center text-2xl font-bold">Hold onto your socks!</h4>
                    <div class="mt-2 alertTextRegular text-center text-base font-normal">
                        We're busily knitting together your account. Be right back!
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref } from "vue";
    import LoadingSpinner from "./LoadingSpinner.vue";
    const isSpinLoading = ref<boolean>(true);

    // setTimeout(() => {
    //   isSpinLoading.value = true;
    // }, 500);
</script>