<template>
    <main class="flex flex-col items-start gap-6 flex-1 self-stretch bg-background">
        <section class="flex flex-col items-start gap-6 self-stretch">
            <div class="flex flex-col items-start gap-6 self-stretch px-8 py-4">
                <div class="flex flex-col items-start gap-5 self-stretch">
                    <div class="flex items-start content-start gap-x-5 gap-y-4 self-stretch flex-wrap">
                        <div class="flex min-w-[320px] flex-col items-start gap-1 flex-1">
                            <h3 class="text-white self-stretch h3">Assistants</h3>
                        </div>
                        <SearchBox @updateSearchQuery="updateSearchQuery"/>
                    </div>
                </div>
            </div>
        </section>
        <section class="flex flex-col items-start gap-6 self-stretch">
            <div class="flex flex-col items-start gap-6 self-stretch px-8 py-3">
                <div class="flex flex-col items-start gap-5 self-stretch">
                    <div class="flex items-start gap-4 self-stretch">
                        <div class="flex flex-col justify-center items-start gap-1 flex-1 self-stretch">
                            <h6 class="text-white self-stretch text-base">Activate any assistant you need.</h6>
                            <h9 class="overflow-hidden text-ellipsis text-lightGrey self-stretch line-clamp-1 text-base leading-relaxed text-white leading-normal">You can search for assistants in the search bar. You can add all assistants needed by your departments</h9>
                        </div>
                        <div class="flex items-center gap-3">
                            <button class="flex p-2.5 justify-center items-center gap-1 rounded-md border border-textGrey bg-buttonBlue cursor-pointer shadow-[0_1px_2px_0_var(background)]" @click="showModal = true">
                                <IconRequest />
                                <span class="flex p-0 px-0.5 justify-center items-center text-white">Request Assistant</span>
                            </button>
                        </div>
                        <div v-if="showModal" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                            <LoginFrame @close="showModal = false" @submit="handleSubmit" />
                        </div>
                        <div class="drop-down">
                            <IconDropdown />
                        </div>
                    </div>
                </div>
                <div class="flex flex-col items-start gap-2 self-stretch border-b border-textGrey">
                    <nav class="flex items-start gap-3">
                        <ol class="flex list-none gap-3 mb-1">
                            <li class="flex h-8 p-1 gap-2 justify-center items-center"><a class="text-buttonBlue border-b-2 border-buttonBlue first:border-b-2 first:border-buttonBlue active:border-b-2 active:border-[#A7D0F8]">View all</a></li>
                            <li class="flex h-8 p-1 gap-2 justify-center items-center"><a class="text-buttonBlue hover:border-b-2 hover:border-buttonBlue">Developer tools</a></li>
                            <li class="flex h-8 p-1 gap-2 justify-center items-center"><a class="text-buttonBlue hover:border-b-2 hover:border-buttonBlue">Communication</a></li>
                            <li class="flex h-8 p-1 gap-2 justify-center items-center"><a class="text-buttonBlue hover:border-b-2 hover:border-buttonBlue">Productivity</a></li>
                            <li class="flex h-8 p-1 gap-2 justify-center items-center"><a class="text-buttonBlue hover:border-b-2 hover:border-buttonBlue">Browser tools</a></li>
                            <li class="flex h-8 p-1 gap-2 justify-center items-center"><a class="text-buttonBlue hover:border-b-2 hover:border-buttonBlue">Marketplace</a></li>
                        </ol>
                    </nav>
                </div>
                <div class="flex flex-wrap items-start gap-6 overflow-y-auto scrollbar-none">
                    <AssistantCard v-for="assist in filteredAssistants"
                                   :key="assist.name"
                                   :assistant="assist" />
                </div>
            </div>
        </section>
    </main>
</template>

<script setup lang="ts">
import { ref, computed,onMounted } from 'vue';
import IconSearch from '../../icons/IconSearch.vue'
import IconRequest from '../../icons/IconPlus.vue'
import IconDropdown from '../../icons/IconVerticalDots.vue'
import SearchBox from '../SearchInput.vue'
import LoginFrame from './RequestForm.vue'
import AssistantCard from './AssistantCard.vue'
import type { IAssistant } from '../../../Models/Common/IAssistant'
import { AssistantService } from '../../../Services/AssistantService'

const showModal = ref(false);
const handleSubmit = (formData: any) => {
  // Handle form submission here
  console.log('Form submitted:', formData);
  showModal.value = false; // Close modal after submission
};

const searchQuery = ref('');
const assistants = ref<IAssistant[]>([]);

onMounted(async () => {
  try {
    const service = new AssistantService();
    assistants.value = await service.getAllAssistants();
  } catch (error) {
    console.error('Error fetching assistants:', error);
  }
});
const updateSearchQuery = (query: string) => {
  searchQuery.value = query.toLowerCase();
};
const filteredAssistants = computed(() => {
  if (!searchQuery.value) {
    return assistants.value;
  }
  return assistants.value.filter(assist => 
    assist.name.toLowerCase().includes(searchQuery.value)
  );
});
</script>

<!-- <style scoped>

     .main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-3xl, 24px);
        flex: 1 0 0;
        align-self: stretch;
        background: var(--Background-primary, #031525);
    } 

     .section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-3xl, 24px);
        align-self: stretch;
    } 

     .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-3xl, 24px);
        align-self: stretch;
    }

    .header {
        padding: 0px 32px;
    }
 
     .body {
        padding: 0px var(--container-padding-desktop, 32px);
    } 

     .section-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-2xl, 20px);
        align-self: stretch;
    }
 
     .heading {
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        gap: var(--spacing-2xl, 20px) var(--spacing-xl, 16px);
        align-self: stretch;
        flex-wrap: wrap;
    } 

     .page-heading {
        display: flex;
        min-width: 320px;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-xs, 4px);
        flex: 1 0 0;
    }
 

     .head-text {
        color: var(--White, #FFF);
        align-self: stretch;
    } 

     .section-heading {
        display: flex;
        align-items: flex-start;
        gap: var(--spacing-xl, 16px);
        align-self: stretch;
    }
 
     .section-heading-text-label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: var(--spacing-xs, 4px);
        flex: 1 0 0;
        align-self: stretch;
    }
 

     .section-heading-text-main {
        color: var(--Colors-Text-text-primary_on-brand, #FFF);
        align-self: stretch;
    } 

     .section-heading-text-sub {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        align-self: stretch;
        overflow: hidden;
        color: var(--Flow-Arrow, rgba(255, 255, 255, 0.40));
        text-overflow: ellipsis;
    } 


     .request-btn-container {
        display: flex;
        align-items: center;
        gap: var(--spacing-lg, 12px);
    }
 
     .request-btn {
        display: flex;
        padding: 10px 14px;
        justify-content: center;
        align-items: center;
        gap: var(--spacing-xs, 4px);
        border-radius: var(--radius-md, 8px);
        border: 1px solid var(--Seperator-semi-transparent, rgba(255, 255, 255, 0.09));
        background: var(--Button, #7BA8EF);
        cursor: pointer;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    } 


     .request-btn-text {
        display: flex;
        padding: var(--spacing-none, 0px) var(--spacing-xxs, 2px);
        justify-content: center;
        align-items: center;
        color: var(--White, #FFF);
    } 


     .drop-down {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        cursor: pointer;
    } 

     .nav-tab-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-md, 8px);
        align-self: stretch;
        border-bottom: 1px solid var(--Text-Graph, rgba(255, 255, 255, 0.40));
    }

   .nav-tabs {
        display: flex;
        align-items: flex-start;
        gap: var(--spacing-lg, 12px);
        ol

    {
        display: contents;
        list-style: none;
    }

    } 
 
     .nav-tab {
        display: flex;
        height: 32px;
        padding: 0px var(--spacing-xs, 4px) var(--spacing-lg, 12px) var(--spacing-xs, 4px);
        justify-content: center;
        align-items: center;
        gap: var(--spacing-md, 8px);
    } 

     .nav-tab-text {
        color: var(--LoginLines, #A7D0F8);
    } 

    
 

  nav ol li a:active, nav ol li:first-child a {
        border-bottom: 2px solid var(--LoginLines, #A7D0F8);
    }  

     .assistants-container {
        scrollbar-width: none;
        display: flex;
        align-content: flex-start;
        gap: 24px var(--spacing-3xl, 24px);
        align-self: stretch;
        flex-wrap: wrap;
        height: calc(100vh - 210px);
        overflow-y: auto;
    }
 



     .modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }
 
     .modal-content {
        background-color: #fff;
        border-radius: 8px;
        padding: 20px;
        position: relative;
        width: 400px;
        max-width: 90%;
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 20px;
        cursor: pointer;
    } 
</style> -->