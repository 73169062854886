<template>
  <div>
    <i class="bi bi-trash"></i>
  </div>
</template>

<script>
import 'bootstrap-icons/font/bootstrap-icons.css';
export default {
};
</script>

<style>
.bi-trash {
    color: rgb(124, 12, 12);
}
.bi-trash:hover {
    color:rgb(201, 46, 46);
    cursor: pointer;
}
</style>
