<template>
    <main class="flex flex-col overflow-auto font-inter h-full">
        <header class="py-[15px] px-[10px]">
            <h1 class="text-white text-[24px] font-bold">Password</h1>
        </header>
        <div class="flex flex-col gap-1 items-start justify-center self-stretch w-full bg-menuSelected p-4 overflow-y-auto">
                <h2 class="text-white text-[20px] font-bold">Change your password</h2>
                <p class="text-textGrey mt-1 text-[12px] font-medium">Here you can change your current password if necessary.</p>
            </div>

        <section class="mt-6 px-4">
            <PasswordForm @password-updated="showToaster" />
        </section>
        <section class="mt-6 px-4">
            <LoginLocations />
        </section>
        <div class="fixed bottom-[100px] left-[40%] w-full max-w-[320px] p-2.5 px-4 md:p-0">
            <PasswordUpdateToaster v-if="showPasswordUpdateToaster" />
        </div>
    </main>
</template>

<script lang="ts">
    import { ref } from 'vue';
    import PasswordForm from './PasswordForm.vue';
    import LoginLocations from './LoginLocations.vue';
    import PasswordUpdateToaster from '@/components/PasswordUpdateToaster.vue';

    export default {
        name: 'PasswordSettings',
        components: {
            PasswordForm,
            LoginLocations,
            PasswordUpdateToaster
        },
        setup() {
            const showPasswordUpdateToaster = ref(false);

            const showToaster = () => {
                showPasswordUpdateToaster.value = true;

                setTimeout(() => {
                    showPasswordUpdateToaster.value = false;
                }, 10000);
            };

            return { showPasswordUpdateToaster, showToaster };
        }
    };
</script>

<!-- <style scoped>
     .password-settings {
        display: flex;
        flex-direction: column;
        overflow: auto;
        font-family: Inter, sans-serif;
        padding: 32px 0 48px;
        height: 100%;
    } 

     .page-header {
        padding: 0 32px;
    }
 
     .page-title {
        color: #fff;
    } 

     .change-password-section,
    .login-locations-section {
        margin-top: 24px;
        padding: 0 32px;
    } 

     .section-content {
        min-height: 45px;
    }
 
     .section-title {
        color: #fff;
    } 

     .section-description {
        color: rgba(255, 255, 255, 0.4);
        margin-top: 4px;
    } 

     .section-divider {
        background-color: rgba(255, 255, 255, 0.4);
        border: none;
        height: 1px;
        margin: 24px 0;
    } 

     .password-update-toaster {
        position: fixed;
        bottom: 100px;
        left: 40%;
        width: -webkit-fill-available;
    } 

     .login-locations {
        color: #fff;
        margin-top: 32px;
    } 

    /* Ensure toaster responsiveness */
     @media (max-width: 991px) {
        .password-update-toaster {
            max-width: 320px;
            padding: 10px 16px;
        }
    } 
</style> -->