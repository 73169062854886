<template>
  <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.0//EN" "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
  <div class="icon-key">
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.3333 11.9999C25.3333 11.1469 25.0079 10.2938 24.357 9.64298C23.7061 8.9921 22.8531 8.66667 22 8.66667M22 22C27.5228 22 32 17.5228 32 12C32 6.47715 27.5228 2 22 2C16.4772 2 12 6.47715 12 12C12 12.4561 12.0305 12.9051 12.0897 13.3451C12.187 14.0686 12.2356 14.4304 12.2029 14.6593C12.1688 14.8978 12.1253 15.0262 12.0078 15.2365C11.895 15.4383 11.6962 15.6371 11.2986 16.0348L2.78105 24.5523C2.4928 24.8405 2.34867 24.9847 2.2456 25.1529C2.15422 25.302 2.08688 25.4646 2.04605 25.6346C2 25.8264 2 26.0303 2 26.4379V29.3333C2 30.2668 2 30.7335 2.18166 31.09C2.34144 31.4036 2.59641 31.6586 2.91002 31.8183C3.26654 32 3.73325 32 4.66667 32H8.66667V28.6667H12V25.3333H15.3333L17.9652 22.7014C18.3629 22.3038 18.5617 22.105 18.7635 21.9922C18.9738 21.8747 19.1022 21.8312 19.3407 21.7971C19.5696 21.7644 19.9314 21.813 20.6549 21.9103C21.0949 21.9695 21.5439 22 22 22Z"
        stroke="#A7D0F8" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style scoped></style>