<template>
    <div class="icon-pause">
        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
            <path d="M18.6667 44.3307C21.2333 44.3307 23.3333 42.2307 23.3333 39.6641V16.3307C23.3333 13.7641 21.2333 11.6641 18.6667 11.6641C16.1 11.6641 14 13.7641 14 16.3307V39.6641C14 42.2307 16.1 44.3307 18.6667 44.3307ZM32.6667 16.3307V39.6641C32.6667 42.2307 34.7667 44.3307 37.3333 44.3307C39.9 44.3307 42 42.2307 42 39.6641V16.3307C42 13.7641 39.9 11.6641 37.3333 11.6641C34.7667 11.6641 32.6667 13.7641 32.6667 16.3307Z" fill="white" />
        </svg>
    </div>
</template>

<script setup lang="ts">

</script>

<style>
    .icon-pause {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        color: rgb(177, 168, 168);
    }
</style>