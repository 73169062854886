<template>
    <div v-if="assistant" class=" flex max-w-[476px] min-w-[320px] flex-grow flex-col justify-start p-6 rounded-xl border border-menuSelected
            bg-backgroundSidebar min-w-[320px] max-w-[476px] sm:min-w-[calc(100%-24px)] md:min-w-[calc(50%-24px)] lg:min-w-[calc(33.33%-24px)]">
        <div class="flex flex-col items-start gap-6 p-6 self-stretch">
            <div class="flex items-start gap-2 self-stretch">
                <div class="flex items-center gap-3 flex-1">
                    <div class="flex items-center p-1 border border-lightGrey bg-white rounded-md">
                        <div class="flex justify-center items-center w-12 h-12">
                            <img :src="assistant.image"
                                 :alt="`${assistant.name} logo`"
                                 v-if="assistant.image" />
                        </div>
                    </div>
                    <span class="flex-1 text-white">{{assistant.name}}</span>
                </div>
            </div>
            <label class="self-stretch text-lightGrey">{{assistant.descriptionShort}}</label>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import type { IAssistant } from '@/Models/Common/IAssistant';

const props = defineProps({
  assistant: {
    type: Object as PropType<IAssistant>,
    required: false,
  },
});

console.log(props.assistant);
</script>

<style scoped>

  /* .assistants-card {
        display: flex;
        max-width: 476px;
        min-width: 320px;
        flex-basis: calc(50% - 24px);
        flex-grow: 1;
        flex-direction: column;
        justify-content: start;
        padding: 24px;
        border-radius: var(--radius-xl, 12px);
        border: 1px solid var(--Seperator-semi-transparent, rgba(255, 255, 255, 0.09));
        background: var(--Background-secondary, #071A2B);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    } 
 */
    /* @media (max-width: 768px) {
        .assistants-card {
            flex-basis: calc(100% - 24px); 
        }
    }

    @media (min-width: 769px) and (max-width: 1200px) {
        .assistants-card {
            flex-basis: calc(50% - 24px); 
        }
    }

    @media (min-width: 1201px) {
        .assistants-card {
            flex-basis: calc(33.33% - 24px); 
        }
    }  */
    


    /* .card-container {
        display: flex;
        padding: var(--spacing-3xl, 24px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-3xl, 24px);
        align-self: stretch;
    }

 */
    /* .card-header-container {
        display: flex;
        align-items: flex-start;
        gap: var(--spacing-md, 8px);
        align-self: stretch;
    } */

    /* .card-header {
        display: flex;
        align-items: center;
        gap: var(--spacing-lg, 12px);
        flex: 1 0 0;
    } */

     /* .assistant-icon-wrap {
        display: flex;
        padding: var(--spacing-xxs, 2px);
        align-items: center;
        border-radius: var(--radius-md, 8px);
        border: 1px solid var(--Colors-Border-border-secondary, #EAECF0);
        background: var(--Colors-Base-white, #FFF);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    } 
 */
    /* .assistant-icon {
        display: flex;
        width: 48px;
        height: 48px;
        justify-content: center;
        align-items: center;
    }
 */
    /* .assistant-name {
        flex: 1 0 0;
        color: var(--White, #FFF);
    } */

    /* .assistant-details {
        align-self: stretch;
        color: var(--Text-Tetriary, #CBD6E3);
    } */
</style>