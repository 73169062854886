<template>
    <div class="bg-cover bg-no-repeat w-screen h-screen flex items-center justify-center bg-custom-bg">
        <div class="bg-[var(--color-background)] border-[3px] border-[#a7d0f8] p-6 rounded-[40px] w-[364px]">
            <div class="flex flex-col items-center">
                <div class="flex items-center justify-center mb-4">
                    <svg v-if="isSuccess" width="56" height="56" viewBox="0 0 56 56" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect x="3.5" y="3.5" width="49" height="49" rx="24.5" fill="#68C57C" />
                        <rect x="3.5" y="3.5" width="49" height="49" rx="24.5" stroke="#079455" stroke-width="7" />
                        <path
                            d="M22.7487 28.0026L26.2487 31.5026L33.2487 24.5026M39.6654 28.0026C39.6654 34.4459 34.442 39.6693 27.9987 39.6693C21.5554 39.6693 16.332 34.4459 16.332 28.0026C16.332 21.5593 21.5554 16.3359 27.9987 16.3359C34.442 16.3359 39.6654 21.5593 39.6654 28.0026Z"
                            stroke="white" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <svg v-if="!isSuccess" width="56" height="56" viewBox="0 0 56 56" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect x="3.5" y="3.5" width="49" height="49" rx="24.5" fill="#F79009" />
                        <rect x="3.5" y="3.5" width="49" height="49" rx="24.5" stroke="#DC6803" stroke-width="7" />
                        <path
                            d="M27.9987 23.3359V28.0026M27.9987 32.6693H28.0104M39.6654 28.0026C39.6654 34.4459 34.442 39.6693 27.9987 39.6693C21.5554 39.6693 16.332 34.4459 16.332 28.0026C16.332 21.5593 21.5554 16.3359 27.9987 16.3359C34.442 16.3359 39.6654 21.5593 39.6654 28.0026Z"
                            stroke="white" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <h1 class="text-white text-center mt-1 text-2xl font-bold leading-7" v-if="isSuccess">
                    Your password has been reset successfully.
                </h1>
                <h1 class="text-white text-center mt-1 text-2xl font-bold leading-7" v-if="!isSuccess">
                    Oops!
                </h1>
                <p class="text-[15px] font-normal leading-5 tracking-[-0.01em] text-white text-center mt-3 mb-4"
                    v-if="isSuccess">
                    Please click the button below to continue to the interface.
                </p>
                <p class="success-failure__description text-white text-center mt-2 mb-4" v-if="!isSuccess">
                    An error happened while resetting your password!
                </p>
                <button type="submit"
                    class="w-full h-14 rounded-[16px] mb-4 bg-[var(--ButtonBackground,#ffffff)] text-black focus:outline-none focus:shadow-none text-[17px] font-medium"
                    v-if="isSuccess" @click="goBackToLogin">
                    Continue
                </button>
                <button type="submit"
                    class="w-full h-14 rounded-[16px] mb-4 bg-[var(--ButtonBackground,#ffffff)] text-black focus:outline-none focus:shadow-none text-[17px] font-medium"
                    v-if="!isSuccess">
                    Try again
                </button>
            </div>
        </div>
    </div>
</template>


<script setup lang="ts">
import router from "@/router";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

// const isSuccess = ref<string>("");
const isSuccess = ref(true);

const goBackToLogin = () => {
    router.push("/auth/login");
};

onMounted(() => {
    //   if (route.state && route.state.isSuccess !== undefined) {
    //     isSuccess.value = route.state.isSuccess;
    //   } else {
    //     console.log("ahvdja", route);
    //     // Handle the case where state is not passed or is undefined
    //     console.log("State is missing or undefined.");
    //   }
});
</script>
