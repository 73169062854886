<template>
    <div class="flex text-red-500 mb-2.5 items-start">
      <span class="mr-2.5">
        <IconCircleVue />
      </span>
      <p class="text-sm error-message text-[0.813rem] font-medium mt-0.5">{{ message }}</p>
    </div>
  </template>
  
  <script>
  import IconCircleVue from "../icons/IconCircle.vue";
  
  export default {
    name: "ErrorMessage",
    components: {
      IconCircleVue,
    },
    props: {
      message: {
        type: String,
        required: true,
      },
    },
  };
  </script>
  
  <style scoped>
  
  </style>
  