import { jwtDecode } from "jwt-decode";
import AuthService from './AuthService'; // Adjust the path to your AuthService
import { useRoute,useRouter } from "vue-router"; 
class TokenManager {
    private static instance: TokenManager | null = null;
    private authService = new AuthService();
    router  = useRouter();
    route = useRoute();
    private constructor() {
      // Private constructor to enforce singleton pattern
    }
  
    public static getInstance(): TokenManager {
      if (!TokenManager.instance) {
        TokenManager.instance = new TokenManager();
      }
      return TokenManager.instance;
    }
  
    public getTokenExpiration(): Date | null {
      const token = this.authService.GetCookieValue("freddyUserId");
      if (token) {
        const decodedToken = jwtDecode(token || "");
        const expiryInSeconds = decodedToken.exp || 0;
        const expiryDate = new Date(expiryInSeconds * 1000);
        return expiryDate;
      } else {
        return null;
      }
    }
    private getRefreshTokenExpiration(): Date | null {
      const expiration = this.authService.GetCookieValue("tokenExpiration");
      return expiration ? new Date(expiration) : null;
    }
    public async refreshToken(): Promise<string | null> {
      try {
        const refreshToken = this.authService.GetCookieValue("refreshToken") || "";
        const deviceId = this.authService.GetCookieValue("deviceId") || "";
        const refreshTokenExpiry = this.getRefreshTokenExpiration() || new Date();
        if(refreshToken&&refreshTokenExpiry){
            const response = await this.authService.GetRefreshToken(
                refreshToken,
                refreshTokenExpiry,
                deviceId
              );
              if (!response.token) {
                throw new Error("Invalid response from refresh token endpoint");
              }
              this.authService.CreateCookie("freddyUserId", response.token);
              this.authService.CreateCookie("deviceId", response.deviceId);
              return response.token;
        }
        else{
            throw new Error("no refresh token available in client")
        }
      } catch (error) {
        console.error("Failed to refresh access token:", error);
        this.authService.ClearCookie("freddyUserId");
        this.authService.ClearCookie("refreshToken");
        this.authService.ClearCookie("tokenExpiration");
        this.router.push("/auth/login"); // Redirect to login on failure
        return null;
      }
    }
    public startBackgroundTokenRefresh() {
      setInterval(async () => {
        const tokenExpiration = this.getTokenExpiration();
  
        if (tokenExpiration) {
          const now = Date.now();
  
          // Check if the access token is about to expire
          if (tokenExpiration.getTime() - now <= 5 * 60 * 1000) {
            console.log("Access token is about to expire, refreshing...");
            await this.refreshToken();
          }
        }
      }, 3 * 60 * 1000);
    }
  
    public clearCookies() {
      this.authService.ClearCookie("freddyUserId");
      this.authService.ClearCookie("refreshToken");
      this.authService.ClearCookie("tokenExpiration");
    }
  }
  export default TokenManager;