<template>
  <div class="rounded-lg bg-menuSelected flex w-[300px] max-w-[300px] p-3.5 border border-white/40 relative" ref="dropdown" @click="flag = true">
    <div class="flex items-center gap-2">
      <img loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/528c676025f0311f38ef8230e325c94af0388c5ba4fd0a18e33f537ca0456ef5?placeholderIfAbsent=true&apiKey=72c4cd610bec4ff5b13a5eba75c4b267"
        class="w-5 h-5 object-contain" alt="Search icon" />
      <div class="flex items-center gap-1.5 flex-wrap">
        <div v-for="(tag, index) in tags" :key="index"
          class="flex items-center gap-[3px] px-[5px] py-[2px] rounded-[6px] bg-[#071A2B] border border-white/4 !z-30">
          <div class="flex items-center gap-[5px]">
            <div class="relative w-[16px] h-[16px] overflow-hidden">
              <img :src="tag.iconSrc" :alt="tag.name + ' icon'" class="absolute inset-0 w-full h-full object-cover" />
              <div class="relative rounded-full border border-white h-4"></div>
            </div>
            <span class="text-lightBlue bodySmall">{{ tag.name }}</span>
          </div>
          <button class="flex justify-center items-center w-4 h-4 p-0 bg-none border-0 cursor-pointer"
            @click="removeTag(index)" aria-label="Remove tag">
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/18394b797dc8313c27e917884c10f77d9a157657fcf299c56b1b6d6cf9e34781?placeholderIfAbsent=true&apiKey=72c4cd610bec4ff5b13a5eba75c4b267"
              class="w-3 h-3 object-contain" alt="" />
          </button>
        </div>
        <input type="text" class="flex-1 bg-transparent border-none text-lightBlue outline-none bodySmall absolute left-10 top-0 w-full h-full"
          v-model="inputValue" @focus="flag = true" @input="handleInput" 
          @keydown.down.prevent="moveDown" @keydown.up.prevent="moveUp" @keydown.enter.prevent="selectHighlighted"
          :placeholder="tags.length ? '' : 'Search for a fitting department'" />
        <div v-show="flag">
          <ul
            class="absolute top-full left-0 right-0 bg-menuSelected border border-textGrey rounded-lg mt-1 z-10 max-h-[150px] overflow-y-auto pl-0 scrollbar-thin">
            <li v-for="(suggestion, index) in filteredSuggestions" :key="index"
              :class="['flex items-center gap-2 p-2.5 px-3 cursor-pointer hover:bg-backgroundSidebar rounded-sm', { highlighted: index === highlightedIndex || suggestion.isSelected == true }]"
              @click="selectSuggestion(index)" @mouseover="highlightedIndex = index">
              <img :src="suggestion.iconSrc"
                class="flex items-center justify-center w-6 h-6 rounded-full bg-cover bg-center z-30" alt="" />
              <span class="body">{{ suggestion.name }}</span>
              <checkIcon v-if="suggestion.isSelected" />
            </li>
          </ul>

        </div>
      </div>

    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import checkIcon from '../../icons/IconTick.vue'

interface Tag {
  name: string;
  iconSrc: string;
  isSelected: boolean;
}

const tags = ref<Tag[]>([
  {
    name: 'Dev...',
    iconSrc:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/47280bb5f5a864cf9a0e2a37ccadeff73e7eed309f71334eaf404eca7dc59d23?placeholderIfAbsent=true&apiKey=72c4cd610bec4ff5b13a5eba75c4b267',
    isSelected: false
  },
  {
    name: 'Comm...',
    iconSrc:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/f8eaaec8188a677d1cb46df59504fda8b26cbe8fc421f306c403955f487fb431?placeholderIfAbsent=true&apiKey=72c4cd610bec4ff5b13a5eba75c4b267',
    isSelected: false
  }
]);

const suggestions = ref<Tag[]>([
  { name: 'Design', iconSrc: '', isSelected: false },
  { name: 'Marketing', iconSrc: '', isSelected: false },
  { name: 'HR', iconSrc: '', isSelected: false }
  // Add more suggestions as needed
]);

const inputValue = ref('');
const highlightedIndex = ref(-1);
const flag = ref(false);
const dropdown = ref<HTMLElement | null>(null);

const filteredSuggestions = computed(() =>
  suggestions.value.filter((suggestion) =>
    suggestion.name.toLowerCase().includes(inputValue.value.toLowerCase())
  )
);

const handleClickOutside = (event: MouseEvent) => {
  if (dropdown.value && !dropdown.value.contains(event.target as Node)) {
    flag.value = false;
  }
};
const handleEscapeKey = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    flag.value = false;
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside); 
  document.addEventListener('keydown', handleEscapeKey);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside); 
  document.removeEventListener('keydown', handleEscapeKey);
})

const removeTag = (index: number) => {
  const removedTag = tags.value[index];
  tags.value.splice(index, 1);
  const suggestionIndex = suggestions.value.findIndex(
    (suggestion) => suggestion.name === removedTag.name
  );
  if (suggestionIndex !== -1) {
    suggestions.value[suggestionIndex].isSelected = false;
  }
};

const handleInput = () => {
  highlightedIndex.value = -1; 
};

const selectSuggestion = (index: number) => {
  if (!filteredSuggestions.value[index].isSelected) {
    tags.value.push(filteredSuggestions.value[index]);
  } else {
    tags.value = tags.value.filter((tag) => tag.name !== filteredSuggestions.value[index].name);
  }
  filteredSuggestions.value[index].isSelected = !filteredSuggestions.value[index].isSelected;
  inputValue.value = '';
};

const moveDown = () => {
  if (highlightedIndex.value < filteredSuggestions.value.length - 1) {
    highlightedIndex.value++;
  }
};

const moveUp = () => {
  if (highlightedIndex.value > 0) {
    highlightedIndex.value--;
  }
};

const selectHighlighted = () => {
  if (highlightedIndex.value >= 0) {
    selectSuggestion(highlightedIndex.value);
  }
};
</script>


<!-- <style scoped>
    .input-container {
        border-radius: 12px;
        background-color: rgba(17, 56, 91, 1);
        display: flex;
        width: 300px;
        max-width: 300px;
        padding: 14px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        position: relative;
    }

    .input-content {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .search-icon {
        width: 20px;
        height: 20px;
        object-fit: contain;
    }

    .tag-list {
        display: flex;
        align-items: center;
        gap: 6px;
        flex-wrap: wrap;
    }

    .tag {
        border-radius: 6px;
        background-color: rgba(7, 26, 43, 1);
        display: flex;
        align-items: center;
        gap: 3px;
        padding: 2px 4px 2px 5px;
        border: 1px solid rgba(255, 255, 255, 0.4);
    }

    .tag-content {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .tag-icon-wrapper {
        position: relative;
        width: 16px;
        height: 16px;
        overflow: hidden;
    }

    .tag-icon {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .tag-icon-border {
        position: relative;
        border-radius: 133px;
        border: 1px solid rgba(255, 255, 255, 1);
        height: 16px;
    }

    .tag-name {
        color: var(--Text-Tetriary, #cbd6e3);
    }

    .tag-close-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        padding: 2px;
        background: none;
        border: none;
        cursor: pointer;
    }

    .close-icon {
        width: 12px;
        height: 12px;
        object-fit: contain;
    }

    .input-field {
        flex: 1;
        background: none;
        border: none;
        color: var(--Text-Tetriary, #cbd6e3);
        outline: none;
    }

        .input-field::placeholder {
            color: var(--Text-Tetriary, #cbd6e3);
            opacity: 0.7;
        }

    .dropdown-list {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: rgba(17, 56, 91, 1);
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-radius: 6px;
        margin-top: 4px;
        z-index: 10;
        max-height: 150px;
        overflow-y: auto;
        padding-inline-start: 0px;
        scrollbar-width: thin;
    }

    .dropdown-item {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px 12px;
        cursor: pointer;
    }

        .dropdown-item.highlighted {
            border-radius: var(--radius-sm, 6px);
            background: var(--Background-secondary, #071A2B);
        }

    .dropdown-item-icon {
        display: flex;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
        border-radius: 200px;
        background: var(--avatar-user-square-phoenix-baker-color-background, url(<path-to-image>) lightgray 50% / cover no-repeat, #D6CFB7);
    }
</style> -->