import { createStore } from 'vuex'

interface State {
    contentDisplay: string
    yDivWidth: string
    yComponentStyles: any,
    hamburgerStyles: any
}

export const store = createStore<State>({
    state:{
        contentDisplay: '',
        yDivWidth: '100%',
        yComponentStyles: {},
        hamburgerStyles: {}
    },
    mutations:{
        hideDivVisibility(state,display){
            state.contentDisplay = display;
        },
        setDivWidth(state,width){
            state.yDivWidth = width;
        },
        setComponentStyles(state,styles){
            state.yComponentStyles = {...state.yComponentStyles, ...styles}
        },
        setHamburgerStyles(state,styles){
            state.hamburgerStyles = {...state.hamburgerStyles, ...styles}
        }
    },
    actions:{
        hideDiv({ commit }, display) {
            commit('hideDivVisibility', display)
        },
        updateDivWidth({ commit }, width){
            commit('setDivWidth', width)
        },
        updateComponentStyles({ commit }, styles){
            commit('setComponentStyles',styles)
        },
        updateHamburgerStyles({ commit }, styles){
            commit('setHamburgerStyles',styles)
        }
    },
    getters:{
        getComponentStyles: state => state.yComponentStyles,
        getHamburgerStyles: state => state.hamburgerStyles
    }
})