<template>
  <div class="dashboard">
    <header class="dashboard-header">
      <h1
        class="text-2xl mt-2 font-bold"
        :class="{ collapseStyle: isCollapseView }"
      >
        Organizations
      </h1>
    </header>
    <main class="dashboard-main" v-if="!isLoading">
      <section class="supporting-text2 text-sm text-gray-300">
        Please select an organization.
      </section>
      <section
        class="chat-section mt-3"
        v-if="AppConfigGlobal.Organizations.length"
      >
        <ChatItem
          v-for="(organization, index) in AppConfigGlobal.Organizations"
          :key="index"
          :name="organization.name"
          :avatar="organization.image"
          @click="onOrganizationSelect(organization.id)"
        />
      </section>
      <section class="bodySmall supporting-text2" v-else>
        No organizations found.
      </section>
      <hr class="divider border-t border-[rgba(255,255,255,0.09)] my-4" />
      <!--<section class="tools-section">
        <h2 class="tools-title" :class="{ collapseStyle: isCollapseView }">
          Tools
        </h2>
        <div class="tools-list">
          <ToolItem
            @selectcheckpoints="onSelectCheckpoints"
            name="Automation Checkpoints"
            icon="https://cdn.builder.io/api/v1/image/assets/TEMP/08734a1474d3f65fd3fcc6a4bdd9f672ec5ac108de182fb28c46847ad3908099?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
          />-->
      <!-- <ToolItem name="Exit" icon="https://cdn.builder.io/api/v1/image/assets/TEMP/65208bc37406139e66c28daccacea0f10264fa084c82c7d15f4641ab63e5c452?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51" :notificationCount="22" />
                <ToolItem name="Support" icon=""  class="support_item"/> -->
      <!--</div>
      </section>-->
    </main>
    <main class="dashboard-main loading" v-else>
      <IconLoading :isLoading="isLoading" />
    </main>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import ChatItem from "./ChatItem.vue";
import ToolItem from "./ToolItem.vue";
import AppConfigGlobal from "../../../core/config/uiSettings";
import IconLoading from "@/components/common/IconLoading.vue";
import AuthService from "@/Services/AuthService";

const emits = defineEmits(["onOrganizationSelect", "selectCheckpoints"]);
const authService = new AuthService();
const props = defineProps({
  isCollapseView: {
    type: Boolean,
    required: true,
    default: false,
  },
  isLoading: {
    type: Boolean,
    required: true,
    default: false,
  },
});

const onOrganizationSelect = (organizationId: number) => {
  AppConfigGlobal.CurrentOrganizationId = organizationId;
  authService.CreateCookie(
    "CurrentOrgId",
    AppConfigGlobal.CurrentOrganizationId.toString()
  );
  emits("onOrganizationSelect");
};
const onSelectCheckpoints = () => {
  emits("selectCheckpoints");
};
</script>

<style scoped lang="scss">
.tools-section {
  @apply flex max-w-[100%] w-[370px] flex-col;

  color: #fff;
}

.tools-title {
  @apply text-2xl font-bold mb-5;
}

.tools-list {
  @apply flex max-w-[100%] w-[370px] flex-col;
}
</style>
<!--<style scoped lang="scss">
    .dashboard {
        background-color: #071a2b;
        display: flex;
        max-width: 480px;
        width: 100%;
        height: 100%;
        flex-direction: column;
        overflow: hidden;
        justify-content: space-between;
        margin: 0 auto;
        padding: 8px 25px 5px;
        gap: 16px;
    }

    .support_item {
        margin-top: 62px;
    }

    .dashboard-header {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .dashboard-title {
        color: #fff;
        padding: 0px 12px;
    }

    .organization-icon {
        aspect-ratio: 0.97;
        object-fit: contain;
        width: 28px;
    }

    .organization-icon:hover {
        cursor: pointer;
    }

    .dashboard-main {
        display: flex;
        flex-direction: column;
        /*gap: 29px;*/
        flex-grow: 1;
        margin-bottom: 10px;

        &.loading {
            align-items: center;
            justify-content: center;
        }
    }

    .chat-section {
        border-radius: 15px;
        display: flex;
        margin-top: 4px;
        width: 100%;
        max-width: 370px;
        flex-direction: column;
        gap: 10px;
    }

    .chat-section:hover {
        cursor: pointer;
    }

    .divider {
        border-top: 1px solid rgba(255, 255, 255, 0.09);
        margin: 0;
        opacity: 0.3;
    }

    .tools-section {
        display: flex;
        max-width: 100%;
        width: 370px;
        flex-direction: column;
        color: #fff;
        margin-top: -26px;
    }

    .tools-title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .tools-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: -20px;
    }

    .collapseStyle {
        display: flex;
        flex-direction: column;
        font-size: 10px;
        width: 15%;
        align-items: center;
    }

    @media (max-width: 1440px) {
        .dashboard {
            padding: 8px 25px 5px;
        }

        .support_item {
            margin-top: 138px;
        }
    }

    .supporting-text2 {
        color: var(&#45;&#45;text-tetriary, #cbd6e3);
        text-align: left;
        position: relative;
        align-self: stretch;
        padding: 0px 12px 12px 12px;
    }
</style>-->
