<template>
  <div>
    <i class="bi bi-exclamation-circle"></i>
  </div>
</template>

<script>
import "bootstrap-icons/font/bootstrap-icons.css";
export default {};
</script>

<style>
.bi-exclamation-circle {
  color: rgb(177, 168, 168);
}
.bi-exclamation-circle {
  color: red;
}
.bi-exclamation-circle::before{
  width: 16px;
  height: 16px;
}
</style>
