import { ref } from "vue";

export const useEmailStore = () => {
    const email = ref<string>("");

    const setEmail = (newEmail: string) => {
        console.log("setemail", newEmail);
        email.value = newEmail;
    };

    return {
        email,
        setEmail,
    };
};