<template>
    <div class="icon-radio-fill">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <circle cx="7" cy="7" r="7" fill="white" />
        </svg>
    </div>
</template>

<script setup lang="ts">

</script>

<style>
    .icon-radio-fill {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        color: rgb(177, 168, 168);
    }
</style>