<template>
    <div class="icon-new-chat">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M14.667 5.33624H9.06699C6.82678 5.33624 5.70668 5.33624 4.85103 5.77222C4.09838 6.15571 3.48646 6.76763 3.10297 7.52028C2.66699 8.37593 2.66699 9.49603 2.66699 11.7362V22.9362C2.66699 25.1765 2.66699 26.2966 3.10297 27.1522C3.48646 27.9049 4.09838 28.5168 4.85103 28.9003C5.70668 29.3362 6.82678 29.3362 9.06699 29.3362H20.267C22.5072 29.3362 23.6273 29.3362 24.483 28.9003C25.2356 28.5168 25.8475 27.9049 26.231 27.1522C26.667 26.2966 26.667 25.1765 26.667 22.9362V17.3362M10.667 21.3362H12.8997C13.5519 21.3362 13.878 21.3362 14.1849 21.2626C14.457 21.1972 14.7172 21.0895 14.9558 20.9433C15.2249 20.7784 15.4555 20.5478 15.9167 20.0866L28.667 7.33624C29.7716 6.23167 29.7716 4.44081 28.667 3.33624C27.5624 2.23167 25.7716 2.23167 24.667 3.33624L11.9166 16.0866C11.4554 16.5478 11.2248 16.7784 11.0599 17.0475C10.9137 17.2861 10.806 17.5462 10.7406 17.8183C10.667 18.1252 10.667 18.4513 10.667 19.1036V21.3362Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
    .icon-new-chat {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        color: rgb(177, 168, 168);

        &:hover {
            cursor: pointer;
        }
    }
</style>