<template>
    <main class="organizations-page flex flex-col bg-[#031525] py-[32px] pb-[48px]">
        <header class="page-header">
            <div class="container px-[32px] py-0">
                <div class="header-content flex items-start gap-x-[16px] gap-y-[20px] flex-wrap">
                    <h1 class="page-title text-white flex-1 min-w-[320px] h3">Organizations</h1>
                    <SearchInput @updateSearchQuery="updateSearchQuery" />
                </div>
            </div>
        </header>
        <section class="organizations-section mt-[24px]">
            <div class="px-[32px] py-0">
                <SectionHeader />
                <div class="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 mt-3">
                    <OrganizationCard v-for="org in filteredAssistants"
                                      :key="org.organizationId"
                                      :organization="org" />
                </div>
            </div>
        </section>
    </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SearchInput from '../SearchInput.vue';
import SectionHeader from './SectionHeader.vue';
import OrganizationCard from './OrganizationCard.vue';
import type { IOrganization } from '@/Models/Common/IOrganization';
import { OrganisationService } from '../../../Services/OrganisationService'

export default defineComponent({
  name: 'OrganizationsPage',
  components: {
    SearchInput,
    SectionHeader,
    OrganizationCard,
  },
  data() {
    return {
      organizations: [] as IOrganization[],
      searchQuery: ''
    };
  },
  computed: {
  filteredAssistants() {
    if (!this.searchQuery) {
      return this.organizations;
    }
    return this.organizations.filter(assist =>
      assist.organizationName.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }
},
  async created() {
    try {
      const service = new OrganisationService();
      this.organizations = await service.getAllOrganisation(); // Fetch and set organizations
    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  },
  methods:{
   updateSearchQuery(query: string){
       this.searchQuery = query.toLowerCase();
   }
 }
});
</script>

<style scoped>
    /* .organizations-page {
        display: flex;
        background-color: #031525;
        padding: 32px 0 48px;
        flex-direction: column;
    } */

    .page-header {
        font-family: Inter, sans-serif;
    }

    /* .container {
        padding: 0 32px;
    } */

    /* .header-content {
        display: flex;
        align-items: flex-start;
        gap: 20px 16px;
        flex-wrap: wrap;
    } */

    /* .page-title {
        color: #fff;
        flex: 1;
        min-width: 320px;
    } */

    /* .organizations-section {
        margin-top: 24px;
    } */

    /* .organization-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        margin-top: 24px;
        overflow-y: auto;
        scrollbar-width: none;
        height: calc(100vh - 210px);
        align-content: flex-start;
        align-self: stretch;
    } */

    @media (max-width: 991px) {
        .container {
            padding: 0 20px;
        }
    }
</style>