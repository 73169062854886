<template>
  <div>
    <i class="bi bi-x"></i>
  </div>
</template>

<script>
import 'bootstrap-icons/font/bootstrap-icons.css';
export default {
};
</script>

<style>
.bi-x {
    color: rgb(177, 168, 168);
    font-size: 36px;
}
.bi-x:hover {
    color:white;
    cursor: pointer;
}
</style>
