<template>
  <div
    class="bg-custom-bg fixed inset-0 flex justify-center items-center w-screen min-h-screen bg-no-repeat bg-cover"
  >
    <!-- <IconLoginSmallCircle />
        <IconLoginCurve /> -->
    <!-- <img class="image-19" src="../assets/Login/Login/image19.png" /> -->
    <div class="w-[358px]">
      <div
        class="rounded-[40px] border-[3px] border-[#a7d0f8] bg-[var(--bg-login-reg)] py-6 px-5 gap-3 relative shadow-[var(--sds-size-depth-0)_var(--sds-size-depth-400)_var(--sds-size-depth-800)_var(--sds-size-depth-negative-200)_var(--sds-color-black-400)]"
      >
        <div class="">
          <div class="flex justify-center mb-4">
            <div
              class="rounded-[20px] border border-[#a7d0f8] flex flex-row gap-3 items-center justify-center flex-shrink-0 w-16 h-16 relative overflow-hidden"
            >
              <div
                class="flex items-center justify-center rounded-[10px] flex-shrink-0 w-[44px] h-12 relative"
              >
                <svg
                  width="38"
                  height="40"
                  viewBox="0 0 38 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.0004 3.33594V10.0026M19.0004 30.0026V36.6693M9.83377 20.0026H3.72266M34.2782 20.0026H28.1671M29.8147 31.8L25.4935 27.0859M29.8147 8.33584L25.4935 13.0499M8.18617 31.8L12.5074 27.0859M8.18617 8.33584L12.5074 13.0499"
                    stroke="#A7D0F8"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="frame-2">
            <div class="text-[29px] font-semibold leading-9 text-center mb-2">
              Create an account
            </div>
            <div class="text-sm font-medium leading-4 text-center mb-5">
              You'll automatically be directed to your organisation
            </div>
          </div>
          <div class="">
            <div class="">
              <div class="">
                <ErrorMessage v-if="serverError" :message="serverError" />
                <div
                  class="flex flex-row gap-[6px] items-center justify-start self-stretch flex-shrink-0 relative"
                >
                  <div
                    class="flex flex-row gap-3 items-start justify-start flex-1 relative"
                  >
                    <input
                      class="w-full h-12 px-4 py-3 pl-4 pr-9 mb-3 rounded-lg border border-[var(--color-menu-selected)] bg-[var(--color-menu-selected)] text-white font-semibold text-sm focus:outline outline-1 outline-white placeholder:text-[var(--color-text-grey)] placeholder:font-semibold placeholder:text-sm"
                      v-model="userRegisterModel.FullName"
                      placeholder="Full Name"
                      @focus="clearError('FullName')"
                      @input="serverError = ''"
                    />
                  </div>
                </div>
                <ErrorMessage
                  v-if="errors.FullName"
                  :message="errors.FullName"
                />
              </div>
              <div class="">
                <div
                  class="flex flex-row gap-[6px] items-center justify-start self-stretch flex-shrink-0 relative"
                >
                  <div
                    class="flex flex-row gap-3 items-start justify-start flex-1 relative"
                  >
                    <input
                      class="w-full h-12 px-4 py-3 pl-4 pr-9 mb-3 rounded-lg border border-[var(--color-menu-selected)] bg-[var(--color-menu-selected)] text-white font-semibold text-sm focus:outline outline-1 outline-white placeholder:text-[var(--color-text-grey)] placeholder:font-semibold placeholder:text-sm"
                      v-model="userRegisterModel.Email"
                      placeholder="Enter your email"
                      type="email"
                      @focus="clearError('Email')"
                      @input="serverError = ''"
                    />
                  </div>
                </div>
                <ErrorMessage v-if="errors.Email" :message="errors.Email" />
              </div>
              <div
                class="bg-[#11385b] rounded-lg p-[0px_12px_0px_16px] flex flex-col gap-3 items-start justify-center flex-shrink-0 h-12 relative backdrop-blur-[4px] text-white focus-within:outline outline-1 outline-white mb-4"
              >
                <div
                  class="flex flex-row gap-[6px] items-center justify-start self-stretch flex-shrink-0 relative"
                >
                  <div
                    class="flex flex-row gap-3 items-start justify-start flex-1 relative"
                  >
                    <input
                      @input="checkPassword"
                      :type="showPassword ? 'text' : 'password'"
                      class="text-sm font-medium leading-4 text-white text-left opacity-100 relative flex-1 flex items-center justify-start bg-transparent outline-none border-none placeholder:text-[var(--color-text-grey)] placeholder:font-semibold placeholder:text-sm"
                      v-model="userRegisterModel.Password"
                      placeholder="Create Password"
                      @focus="clearError('Password')"
                    />
                  </div>
                  <div
                    class="flex-shrink-0 w-6 h-6 relative cursor-pointer hover:cursor-pointer"
                    @click="togglePasswordVisibility()"
                    v-show="showPassword"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.42012 12.7132C2.28394 12.4975 2.21584 12.3897 2.17772 12.2234C2.14909 12.0985 2.14909 11.9015 2.17772 11.7766C2.21584 11.6103 2.28394 11.5025 2.42012 11.2868C3.54553 9.50484 6.8954 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7766C21.8517 11.9015 21.8517 12.0985 21.8231 12.2234C21.785 12.3897 21.7169 12.4975 21.5807 12.7132C20.4553 14.4952 17.1054 19 12.0004 19C6.8954 19 3.54553 14.4952 2.42012 12.7132Z"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="square"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.0004 15C13.6573 15 15.0004 13.6569 15.0004 12C15.0004 10.3431 13.6573 9 12.0004 9C10.3435 9 9.0004 10.3431 9.0004 12C9.0004 13.6569 10.3435 15 12.0004 15Z"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="square"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div
                    class="flex-shrink-0 w-6 h-6 relative cursor-pointer hover:cursor-pointer"
                    @click="togglePasswordVisibility()"
                    v-show="!showPassword"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.7429 5.09232C11.1494 5.03223 11.5686 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7767C21.8518 11.9016 21.8517 12.0987 21.8231 12.2236C21.7849 12.3899 21.7164 12.4985 21.5792 12.7156C21.2793 13.1901 20.8222 13.8571 20.2165 14.5805M6.72432 6.71504C4.56225 8.1817 3.09445 10.2194 2.42111 11.2853C2.28428 11.5019 2.21587 11.6102 2.17774 11.7765C2.1491 11.9014 2.14909 12.0984 2.17771 12.2234C2.21583 12.3897 2.28393 12.4975 2.42013 12.7132C3.54554 14.4952 6.89541 19 12.0004 19C14.0588 19 15.8319 18.2676 17.2888 17.2766M3.00042 3L21.0004 21M9.8791 9.87868C9.3362 10.4216 9.00042 11.1716 9.00042 12C9.00042 13.6569 10.3436 15 12.0004 15C12.8288 15 13.5788 14.6642 14.1217 14.1213"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <ErrorMessage v-if="errors.Password" :message="errors.Password" />
              <div
                class="flex flex-col gap-3 items-start justify-start self-stretch flex-shrink-0 relative mb-4"
              >
                <div
                  class="flex flex-row gap-2 items-start justify-start self-stretch flex-shrink-0 relative"
                  v-if="isPasswordLongEnough"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="20" height="20" rx="10" fill="#A7D0F8" />
                    <path
                      d="M6.25 10L8.75 12.5L13.75 7.5"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div
                    class="text-sm font-medium leading-4 text-[#cbd6e3] text-left relative self-stretch flex-1 flex items-center justify-start"
                  >
                    Must be at least 8 characters
                  </div>
                </div>
                <div
                  class="flex flex-row gap-2 items-start justify-start self-stretch flex-shrink-0 relative"
                  v-if="isValidPassword"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="20" height="20" rx="10" fill="#A7D0F8" />
                    <path
                      d="M6.25 10L8.75 12.5L13.75 7.5"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div
                    class="text-sm font-medium leading-4 text-[#cbd6e3] text-left relative self-stretch flex-1 flex items-center justify-start bodySmall"
                  >
                    Must contain one special character
                  </div>
                </div>
                <div
                  class="flex flex-row gap-2 items-start justify-start self-stretch flex-shrink-0 relative"
                  v-if="!isPasswordLongEnough"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <rect
                      width="20"
                      height="20"
                      rx="10"
                      fill="white"
                      fill-opacity="0.4"
                    />
                    <path
                      d="M6.25 10L8.75 12.5L13.75 7.5"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div
                    class="text-sm font-medium leading-4 text-[#cbd6e3] text-left relative self-stretch flex-1 flex items-center justify-start bodySmall"
                  >
                    Must be at least 8 characters
                  </div>
                </div>
                <div
                  class="flex flex-row gap-2 items-start justify-start self-stretch flex-shrink-0 relative"
                  v-if="!isValidPassword"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <rect
                      width="20"
                      height="20"
                      rx="10"
                      fill="white"
                      fill-opacity="0.4"
                    />
                    <path
                      d="M6.25 10L8.75 12.5L13.75 7.5"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div
                    class="text-sm font-medium leading-4 text-[#cbd6e3] text-left relative self-stretch flex-1 flex items-center justify-start"
                  >
                    Must contain one special character
                  </div>
                </div>
              </div>
            </div>
            <div
              class="bg-white rounded-2xl p-4 flex flex-row gap-3 items-center justify-center flex-shrink-0 w-[19.625rem] h-[52px] relative shadow-[0_0_16px_rgba(0,0,0,0.1)] mb-4"
              @click="Register"
            >
              <ButtonLoader v-if="loading" />
              <button
                class="text-[17px] font-medium text-[var(--color-background)] text-center relative flex-1 flex items-center justify-center bg-transparent border-none cursor-pointer hover:cursor-pointer"
                v-else
              >
                Create Account
              </button>
            </div>
          </div>
          <div
            class="flex flex-row gap-1 items-center justify-center self-stretch flex-shrink-0 relative"
          >
            <div
              class="text-sm font-medium leading-4 text-[#cbd6e3] text-left relative"
            >
              Already have an account?
            </div>
            <div
              class="flex flex-row gap-[6px] items-center justify-center flex-shrink-0 relative overflow-hidden hover:bg-[var(--background-primary)]"
            >
              <a
                href="/auth/login"
                @click="BackToLogin()"
                class="text-sm font-medium leading-4 text-[#a7d0f8] text-left relative hover:bg-transparent cursor-pointer hover:underline"
                >Log in</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <MessageToaster v-show="showToaster" :message="toasterMessage" isSuccess="error"
      @update:showToaster="showToaster = $event" /> -->
  </div>
</template>
<!--  -->
<script>
import { defineComponent } from "vue";
import AuthService from "@/Services/AuthService";
import UserRegisterResponse from "../Services/AuthService";
import { useRouter } from "vue-router";
// import IconLoginSmallCircle from "./icons/IconLoginSmallCircle.vue";
// import IconLoginCurve from "./icons/IconLoginCurve.vue";
// import IconLoginCross from "./icons/IconLoginCross.vue";
// import IconLoginCircle from "./icons/IconLoginCircle.vue";
import MessageToaster from "../components/MessageToaster.vue";
import ButtonLoader from "./common/ButtonLoader.vue";
import IconCircleVue from "./icons/IconCircle.vue";
import ErrorMessage from "../components/common/ErrorMessage.vue";
import { useElementBounding } from "@vueuse/core";
export default defineComponent({
  components: {
    ButtonLoader,
    ErrorMessage,
  },
  data() {
    return {
      userRegisterModel: {
        FullName: "",
        // username: "",
        Email: "",
        Password: "",
      },
      errors: {},
      serverError: "",
      showPassword: false,
      password: "",
      svgFillColor1: "var(--textGraph)",
      svgFillColor2: "var(--textGraph)",
      svgStrokeColor: "white",
      isPasswordLongEnough: false,
      isValidPassword: false,
      validEmailKey: "",
      showToaster: false,
      // toasterMessage: "",
      loading: false,
      router: useRouter(),
      // knownErrorMessages: [
      //   "Full name is required.",
      //   "Email is required.",
      //   "Enter a valid email.",
      //   "Password is required.",
      //   "Please enter a valid email address.",
      //   "Password must be at least 8 characters long.",
      //   "Password must contain at least one special character.",
      // ],
    };
  },
  methods: {
    saveToSession() {
      const email = this.userRegisterModel.Email; // Example value
      sessionStorage.setItem("userEmail", email);
      const username = email.split("@")[0];
      sessionStorage.setItem("userName", username);
      const fullName = this.userRegisterModel.FullName;
      sessionStorage.setItem("fullName", fullName);
    },
    checkPassword() {
      this.serverError = '';
      const password = this.userRegisterModel.Password;

      // Check if the password is at least 8 characters long
      if (password.trim().length >= 8) {
        this.isPasswordLongEnough = true;
        this.svgFillColor1 = "#A7D0F8";
      } else {
        this.svgFillColor1 = "var(--textGraph)";
        this.isPasswordLongEnough = false;
      }
      // Check if the password contains at least one special character
      //const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(password);
      const hasSpecialCharacter =
        // eslint-disable-next-line no-useless-escape
        /[!@#$%^&*(),.?":{} |<>`\[\]~_+=\/;\\\'\-]/.test(password.trim());
      if (hasSpecialCharacter) {
        this.isValidPassword = true;
        this.svgFillColor2 = "#A7D0F8";
      } else {
        this.svgFillColor2 = "var(--textGraph)";
        this.isValidPassword = false;
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },

    // onInvalidUsername(event) {
    //   const uname = event.target.value;
    //   if (!this.validateUserName(uname)) {
    //     this.toasterMessage = "Invalid username format";
    //   } else {
    //     this.toasterMessage = "";
    //   }
    // },

    // onInvalidEmail(event) {
    //   const email = event.target.value;
    //   if (!this.validateEmailaddress(email)) {
    //     this.toasterMessage = "Enter a valid email.";
    //   } else {
    //     this.toasterMessage = "";
    //   }
    // },

    // async Register() {
    //   this.showToaster = false;
    //   this.loading = true;
    //   this.errors = this.validateForm();
    //   if (Object.keys(this.errors).length > 0) {
    //     this.showToaster = true;
    //     this.toasterMessage = Object.values(this.errors).join(",");
    //   }
    //   ////////
    //   if (this.isValidPassword) {
    //     if (Object.keys(this.errors).length === 0) {
    //       try {
    //         const email = this.userRegisterModel.Email; // Example value
    //         const value = sessionStorage.setItem("userEmail", email);
    //         const storedEmail = sessionStorage.getItem("userEmail");
    //         //////
    //         const authService = new AuthService();

    //         try {
    //           const response = await authService.Register(
    //             this.userRegisterModel
    //           );
    //         }
    //         catch (error) {
    //           {
    //             if (
    //               error.response &&
    //               error.response.data &&
    //               error.response.data.message
    //             ) {
    //               this.showToaster = true;
    //               this.toasterMessage = error.response.data.message;
    //             } else {
    //               this.showToaster = true;
    //               this.toasterMessage =
    //                 "An error occurred during registration.";
    //             }
    //             this.loading = false;
    //           }
    //         }

    //         this.loading = false;
    //         this.validEmailKey = response.emailKey;
    //         if (response.verificationResponse.userId) {
    //           sessionStorage.setItem(
    //             "userId",
    //             response.verificationResponse.userId
    //           );
    //           this.loading = false;
    //           this.router.push({
    //             path: "/user/enter-code",
    //             query: { emailKey: response.verificationResponse.emailKey },
    //           });
    //         } else {
    //           this.serverError = response.message || "Registration failed.";
    //           this.loading = false;
    //           this.showToaster = true;
    //           this.toasterMessage = "Error while registering";

    //           alert("Something wrong");
    //         }
    //       } catch (error) {
    //         this.serverError = error.message || "An error occurred.";
    //         this.loading = false;
    //       }
    //     }
    //   }
    // },

    async Register() {
      this.showToaster = false;
      this.loading = true;

      this.errors = this.validateForm();

      if (Object.keys(this.errors).length > 0) {
        this.showToaster = true;
        this.toasterMessage = Object.values(this.errors).join(", ");
        this.loading = false;
        return;
      }

      // if (!this.isValidPassword) {
      //   this.showToaster = true;
      //   this.toasterMessage = "Password is invalid.";
      //   this.loading = false;
      //   return;
      // }

      try {
        this.saveToSession();
        // sessionStorage.setItem("userEmail", email);

        const authService = new AuthService();

        const response = await authService.Register(this.userRegisterModel);

        this.validEmailKey = response.emailKey;

        if (
          response.verificationResponse &&
          response.verificationResponse.userId
        ) {
          sessionStorage.setItem(
            "userId",
            response.verificationResponse.userId
          );
          const username = this.userRegisterModel.Email.split("@")[0];
          sessionStorage.setItem("userName", username);
          this.loading = false;

          this.router.push({
            path: "/user/enter-code",
            query: { emailKey: response.verificationResponse.emailKey },
          });
        } else {
          this.serverError = response.message || "Registration failed.";
          this.showToaster = true;
          // this.toasterMessage = this.serverError;
          this.loading = false;
        }
      } catch (error) {
        // console.log("catch", error.response.data.message);
        // if (
        //   error.response.data.message ==
        //   "Your email address is already registered with Freddy. Please log in."
        // ) {
        //   this.loading = false;
        //   // this.router.push({
        //   //   path: "/user/enter-code",
        //   //   query: { emailKey: response.verificationResponse.emailKey },
        //   // });
        //   this.router.push("/user/enter-code");

        //   return;
        // }
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          this.showToaster = true;
          this.serverError = error.response.data.message;
        } else {
          this.showToaster = true;
          this.serverError = "An error occurred during registration.";
        }
        this.loading = false;
      }
    },
    BackToLogin() {
      this.$router.push("/auth/login");
    },
    clearError(field) {
      if (this.errors[field]) {
        this.errors[field] = "";
      }
    },
    validateForm() {
      const errors = {};

      if (this.userRegisterModel.FullName.trim() == "") {
        errors.FullName = "Full name is required.";
        this.loading = false;
      }

      if (this.userRegisterModel.Email.trim() == "") {
        errors.Email = "Email is required.";
        this.loading = false;
      }

      // if (!this.validateUserName(this.userRegisterModel.FullName)) {
      //   errors.FullName = "Invalid username format";
      //   this.loading = false;
      // }

      if(!this.validateUserName(this.userRegisterModel.FullName) &&
      this.userRegisterModel.FullName.trim() != ""){
        errors.FullName = "Invalid username format";
        this.loading = false;
      }

      if (
        !this.validateEmailaddress(this.userRegisterModel.Email) &&
        this.userRegisterModel.Email.trim() != ""
      ) {
        errors.Email = "Invalid email";
        this.loading = false;
      }

      if (!this.validatePassword(this.userRegisterModel.Password)) {
        errors.Password =
          "Does not contain at least 8 characters or one special character.";
        this.loading = false;
      }

      if (this.userRegisterModel.Password.trim() == "") {
        errors.Password = "Password is required.";
        this.loading = false;
      } else if (
        this.userRegisterModel.Password.length < 8 ||
        !this.validatePassword(this.userRegisterModel.Password)
      ) {
        errors.Password =
          "Does not contain at least 8 characters or one special character.";
        this.loading = false;
      }
      return errors;
    },
    validateUserName(username) {
      const usernamePattern = /^[a-zA-Z\s]*$/;
      if (!usernamePattern.test(username)) {
        return false;
      } else return true;
    },
    validatePassword(password) {
      //const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]`~=/;
      // eslint-disable-next-line no-useless-escape
      const specialCharPattern = /[!@#$%^&*(),.?":{} |<>`\[\]~_+=\/;\\\'\-]/;
      return specialCharPattern.test(password);
    },

    validateEmailaddress(email) {
      const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (!emailPattern.test(email)) {
        return false;
      } else return true;
    },
  },
});
</script>
