<template>
  <div class="dropdown-container">
    <div class="dropdown-container head">
      <div class="dropdown-container head item">
        <div class="dropdown-container head item title">
          <component :is="selectedItem.component"></component>
          <div class="h6Bold heading6">{{ selectedItem.title }}</div>
        </div>
        <IconChevronDown @click="openOptions" />
      </div>
    </div>
    <!--<Transition name="slide-fade">-->
    <ul v-if="isOptionVisible" class="modal mt-1">
      <li
        class="modal option"
        v-for="(item, index) in dropDownItems"
        :key="index"
        @click="selectItem(item)"
      >
        <div class="option-item">
          <component :is="item.component"></component>
          <div class="option-item text">
            <span class="bodyXSmall text-body-small">{{ item.title }}</span>
            <span class="bodyXSmall text-body-small-light">{{
              item.subTitle
            }}</span>
          </div>
        </div>
        <div class="select-btn">
          <IconRadioFill v-if="selectedItem.title === item.title" />
          <IconRadio v-else />
        </div>
      </li>
    </ul>
    <!--</Transition>-->
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, markRaw } from "vue";
import type { IComponentMap } from "@/Models/Common/IComponentMap";

import IconVoice from "@/components/icons/IconVoice.vue";
import IconText from "@/components/icons/IconText.vue";
import IconRadioFill from "@/components/icons/IconRadioFill.vue";
import IconRadio from "@/components/icons/IconRadio.vue";
import IconChevronDown from "@/components/icons/IconChevronDown.vue";

const isOptionVisible = ref<boolean>(false);
const selectedItem = ref<IComponentMap>({} as IComponentMap);
const dropDownItems = ref<IComponentMap[]>([
  {
    title: "Text",
    subTitle: "Interact with Freddy through text-based inputs",
    component: markRaw(IconText),
    isVisble: true,
  },
  /*{ title: 'Audio & Video', subTitle: 'Interact with Freddy through speech based inputs.', component: markRaw(IconVoice), isVisble: true }*/
]);

const emits = defineEmits(["onItemSelected"]);
const props = defineProps({
  isTextSelected: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const openOptions = () => {
  isOptionVisible.value = !isOptionVisible.value;
};

const selectItem = (item: IComponentMap) => {
  isOptionVisible.value = false;
  selectedItem.value = item;
  emits("onItemSelected", item);
};

onMounted(() => {
  selectedItem.value = props.isTextSelected
    ? dropDownItems.value[0]
    : dropDownItems.value[1];
});
</script>

<style scoped lang="scss">
$drpdown-back-clr: #11385b;
$drpdown-item-width: fit-content;

.dropdown-container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  &.head {
    width: $drpdown-item-width;
    height: 100%;
    background: $drpdown-back-clr;
    border-radius: 15px;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    display: inline-flex;
    padding: 5px;

    &.item {
      /*padding: 0px 13px 0px 10px;*/
      flex-direction: row;

      &.title {
        gap: 10px;
        justify-content: flex-start;
      }
    }
  }
}

.modal {
  display: flex;
  flex-direction: column;
  background: $drpdown-back-clr;
  border-radius: 15px;
  width: $drpdown-item-width;
  height: fit-content;
  position: absolute;
  padding: 10px;
  top: 52px;
  gap: 10px;
  list-style: none;

  &.option {
    display: flex;
    height: 40px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    width: fit-content;
    width: 100%;

    .option-item {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 15px;

      &.text {
        flex-direction: column;
        gap: 0;
      }
    }

    &:hover {
      cursor: pointer;
      @extend .active;
    }
  }
}

.heading6 {
  color: white;
  word-wrap: break-word;
}

.active {
  background: #13385a;
  border-radius: 10px;
  background-image: linear-gradient(to left, #2d567d, #2d567d);
}

.text-body-small {
  color: var(--White, #fff);
}

.text-body-small-light {
  @extend .text-body-small;
  color: rgba(255, 255, 255, 0.4);
}

@media (max-width: 600px) {
  .modal {
    gap: 46px;
    padding: 16px 6px 6px 4px;
    width: 240px;
    height: 170px;
  }
}
</style>
