import AppConfigGlobal from '@/core/config/uiSettings';
import Axios from 'axios';
import type { AxiosRequestConfig } from 'axios';

export default abstract class Service {
  private defaultConfig: AxiosRequestConfig = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: this.GetBearerToken(),
    },
  };

  protected PerformGet<T>(
    url: string,
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    const mergedConfig = { ...this.defaultConfig, ...config };
    return Axios.get(url, mergedConfig)
        .then((response) => response.data)
        .catch((res) => { throw res });
  }

  protected PerformDelete<T>(
    url: string,
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    const mergedConfig = { ...this.defaultConfig, ...config };
    return Axios.delete(url, mergedConfig).then((response) => response.data);
  }

  protected PerformPut<T>(
    url: string,
    data: object,
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    const mergedConfig = { ...this.defaultConfig, ...config };
    return Axios.put(url, data, mergedConfig).then((response) => response.data);
  }

  protected PerformPost<T>(
    url: string,
    data: object,
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    const mergedConfig = { ...this.defaultConfig, ...config };
    return Axios.post(url, data, mergedConfig).then(
      (response) => response.data
    );
  }

  protected PerformPostV3<T>(
    url: string,
    data: string,
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    const mergedConfig = { ...this.defaultConfig, ...config };
    return Axios.post(url, data, mergedConfig).then(
      (response) => response.data
    );
  }

  protected PerformPostV2<T>(
    url: string,
    data: object,
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    const mergedConfig = { ...this.defaultConfig, ...config };
    return Axios.post(url, data, mergedConfig);
  }

  protected PerformGetSample<T>(
    url: string,
    id: string,
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    const mergedConfig = { ...this.defaultConfig, ...config };
    return Axios.get(`${url}/${id}`, mergedConfig);
  }

    public CreateCookie(name: string, value: any) {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        document.cookie = `${name}=${value}; path=/; secure; samesite=strict; expires=${date.toUTCString()}; domain=${AppConfigGlobal.Domain}`;
    }

    public ClearCookie(name: string) {
        document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; samesite=strict; domain=${AppConfigGlobal.Domain}`;
    }

  private GetBearerToken() {
    const token = this.GetCookieValue("freddyUserId");
    if (token == undefined || token == null) {
      return "";
    }
    return `Bearer ${token}`;
  }

  public GetCookieValue(name: string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts && parts.length === 2) {
      return parts.pop()?.split(";").shift();
    }
  }
}
