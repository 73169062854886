<template>
    <div v-if="ticketDetails.length === 0" class="overflow-hidden flex flex-col items-center flex-nowrap gap-5 relative w-full mx-auto my-0 flex justify-center items-center h-screen">
        <!-- <div class="pic"></div> -->
        <div class="">
            <TicketLoader />
        </div>
        <span class="flex items-start justify-center shrink-0 basis-auto relative w-[451px] h-[29px] text-white text-2xl font-bold leading-[29px] text-center whitespace-nowrap z-[1]" v-if="onSelectTicketId == 0">It seems like there are no more tickets to review.</span>
        <span class="flex items-start justify-center shrink-0 basis-auto relative w-[451px] h-[29px] text-white text-2xl font-bold leading-[29px] text-center whitespace-nowrap z-[1]" v-else>Loading...</span>
    </div>
    <div class=" min-h-[500px] h-screen overflow-scroll w-full"
         v-else>
        <TicketDetails :ticketDetails="ticketDetails" :selectedTicketId="onSelectTicketId" :isLoadingTicket="isLoadingTicket" @onTicketSelected="selectTicket" />
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref } from "vue";
    import type { IConversation, ITicketDetails, ITickets } from "@/Models/CheckPoints/ITickets";
    import TicketLoader from '../../components/spinners/TicketLoader.vue';
    import { CheckPointsService } from "@/Services/CheckPointsService";
    import TicketDetails from "./TicketDetails.vue";
    import { watch } from "vue";
    const props = defineProps({
        onSelectTicketId: {
            type: Number,
            default: 0,
        },
    });

    const ticketDetails = ref<ITicketDetails[]>([]);
    const isModalOpen = ref(false);
    const isLoadingTicket = ref(false);
    const service = new CheckPointsService();
    const emits = defineEmits(["checkpointApproveOrDecline"]);

    const selectTicket = async ({ ticketId, isDeclined }: { ticketId: number; isDeclined: boolean }) => {
ticketDetails.value = [];
        if (!isDeclined) {
            console.log("not declined in checkpoint")
            ticketDetails.value = [];
            ticketDetails.value = await service.getCheckPointsTicketsById(ticketId);
           
        }
        else {
            console.log("declined in checkpoint")
            emits("checkpointApproveOrDecline");
        }
    }
    watch(props, async () => {
        if (props.onSelectTicketId) {
            isLoadingTicket.value = true;
            
            ticketDetails.value =[];
            ticketDetails.value = await service.getCheckPointsTicketsById(props.onSelectTicketId);
        }
    });
</script>

<style scoped lang="scss">
    :root {
        --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC", "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei", "Source Han Sans CN", sans-serif;
    }

    .main-container {
        @apply overflow-hidden flex flex-col items-center flex-nowrap gap-5 relative w-full mx-auto my-0;
    }

    .main-container,
    .main-container * {
        @apply box-border;
    }

    input,
    select,
    textarea,
    button {
        outline: 0;
    }

    .pic {
        @apply shrink-0 relative w-[439px] h-[454.695px] bg-cover;
    background: url(../../assets/nomorecheckpoint.svg) no-repeat center;
    }

    .text {
        @apply flex items-start justify-center shrink-0 basis-auto relative w-[451px] h-[29px] text-white text-2xl font-bold leading-[29px] text-center whitespace-nowrap z-[1];
    font-family: Inter, var(--default-font-family);
    }

    .wrapper {
        @apply flex items-center justify-center flex-nowrap shrink-0 relative w-[136px] h-10 z-[2] overflow-hidden px-2.5 py-0.5 rounded-md;
    background: #7aa7ee;
    }

    .text-2 {
        @apply flex items-center justify-center shrink-0 basis-auto relative w-[116px] h-6 text-[#031525] text-xl font-bold leading-6 text-center whitespace-nowrap z-[3];
    font-family: Inter, var(--default-font-family);
    }

    .btn {
        @apply cursor-pointer bg-transparent border-[none];
    outline: none;
    }
</style>
