<template>
  <div v-if="isVisible" class="modal paragraph">
    <div class="modal-container">
      <!-- <div class="modal-container-actions">
        <div class="modal-container-user-actions">
          <div class="modal-container-user-actions-item">
            <span>Legal</span>
            <span><IconCaretRightFill /></span>
          </div>
          <div class="modal-container-user-actions-item">
            <span>Support</span>
            <span><IconCaretRightFill /></span>
          </div>
          <div class="modal-container-user-actions-item">
            <span>HR</span>
            <span><IconCaretRightFill /></span>
          </div>
        </div>
        <div class="modal-container-user-actions">
          <div class="modal-container-account-actions-item">
            <span><IconPlusSquare /></span>
            <span>Add New</span>
          </div>
          <div class="modal-container-account-actions-item">
            <span><IconPerson /></span>
            <span>Personal</span>
          </div>
        </div>
      </div> -->
      <div class="modal-container-actions-content">
        <div>
          <span class="rename-heading">Name</span>
          <textarea
            ref="myTextArea"
            class="input-content input-title"
            type="text"
            v-model="title"
            placeholder="Type here..."
          ></textarea>
        </div>
        <div class="modal-container-actions-footer">
          <button
            class="modal-container-actions-btn"
            :class="{ active: !title && !instructions }"
            @click="closeModal()"
          >
            Cancel
          </button>
          <button
            class="modal-container-actions-btn"
            :class="{ active: title && instructions }"
            @click="saveModal()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
    <div class="modal-container-close"><IconClose @click="closeModal()" /></div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import IconRename from "./icons/IconRename.vue";
import IconDelete from "./icons/IconDelete.vue";
import IconSettings from "./icons/IconSettings.vue";
import IconCaretRightFill from "./icons/IconCaretRightFill.vue";
import IconPlusSquare from "./icons/IconPlusSquare.vue";
import IconPerson from "./icons/IconPerson.vue";
import IconClose from "./icons/IconClose.vue";
import { FreddyService } from "@/Services/FreddyService";
//import { threadId } from "worker_threads";
//import { threadId } from "worker_threads";
//import { threadId } from "worker_threads";

const emit = defineEmits(["closeSettingsActionModal", "updateThreadTitle"]);
const props = defineProps({
  isVisible: { type: Boolean, required: true, default: false },
  threadId: { type: Number, required: true, default: 0 },
});

const title = ref("");
const description = ref("");
const instructions = ref("");

const closeModal = () => {
  emit("closeSettingsActionModal");
};

const saveModal = async () => {
  const freddyService = new FreddyService();
  try {
    const response = await freddyService.Rename_Thread(
      props.threadId,
      title.value
    );
    emit("updateThreadTitle", {
      threadId: props.threadId,
      newTitle: title.value,
    });
  } catch (error) {
    console.log();
  }

  emit("closeSettingsActionModal");
};

// const updateThread = async () => {
//   console.log("asdkashda", title.value, threadId);
//   const freddyService = new FreddyService();
//   try {
//     console.log("asdkashda", title.value, threadId);
//     const response = await freddyService.Update_Thread(threadId, title.value);
//   } catch (error) {
//     console.log();
//   }
// };
</script>

<style scoped>
.modal {
  display: flex;
  align-items: center;
  position: fixed;
  width: 500px;
  height: auto;
  z-index: 2;
  bottom: 212px;
  left: 332px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  background: rgb(17 56 91);
  color: white;
  font-family: "Inter";
}

.modal-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 10px;
}

.modal-container-actions {
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100%;
  border-right: 1px solid #434547;
  padding: 10px;
  justify-content: space-between;
}

.modal-container-actions span {
  padding: 5px;
}

.modal-container-user-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input-content {
  border-radius: 5px;
  padding: 10px;
  resize: none;
  border-color: #2f3e4c;
  background-color: #22303f;
  color: white;
  width: 100%;
  font-family: "Inter";
  font-size: 15px;
}

.input-title {
  height: 40px;
  margin: 10px 0px;
}

.input-description {
  height: 100px;
}

.input-instructions {
  height: 110px;
}

.modal-container-actions-content {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
  padding: 10px;
  justify-content: space-between;
  width: inherit;
}

.rename-heading{
  font-family: Inter;
  font-size: 20px;
  position: relative;
  bottom: 9px;
}

.modal-container-user-actions-item {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
}

.modal-container-account-actions-item {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  background-color: #282832;
  border-radius: 5px;
  align-items: center;
}

.modal-container-account-actions-item:hover,
.modal-container-user-actions-item:hover {
  cursor: pointer;
  background-color: #1a1a36;
}

.modal-container-actions-footer {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.modal-container-actions-btn {
  background-color: #a2c5e4;
  border-radius: 5px;
  padding: 10px 30px;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
}

.modal-container-actions-btn:hover {
  cursor: pointer;
  background-color: #bfd5e8;
}

.active {
  background-color: #bfd5e8;
}

.modal-container-close {
  font-size: 20px;
  padding: 3px;
  position: absolute;
  right: 5px;
  top: 0px;
}

.input-content::-webkit-scrollbar {
  width: 2px;
}

.input-content::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

/* @media (max-width: 1040px) {
  .modal {
    display: none;
  }
} */
</style>
