<template>
    <div>
        <i class="bi bi-plus-square"></i>
    </div>
</template>

<script>
import 'bootstrap-icons/font/bootstrap-icons.css';
export default {
};
</script>

<style>
    .bi-plus-square {
        color: rgb(177, 168, 168);
    }

    .bi-plus-squarehover {
        color: white;
        cursor: pointer;
    }
</style>
