import type { IUserUserNameUpdateRequest } from "@/Models/Request/User/IUserUserNameUpdateRequest";
import type { IOrganization } from "../Models/Common/IOrganization";
import type { IPasswordUpdateRequest } from "../Models/Common/IPasswordUpdateRequest";
import type { IUserBasicDetails } from "../Models/Response/User/IUserDetails";
import AppConfigGlobal from "../core/config/uiSettings";
import Core from "./Core";
import type { IUserAssistant } from "../Models/Common/IAssistant";
import type { IUserThreadGroup } from "../Models/Common/IUserThread";
import type { IDeviceInformation } from "@/Models/Request/User/IUserAuthRequest";
import type { IUserConfiguration } from "@/Models/Response/User/IUserConfiguration";

export class UserService extends Core {
    public async UpdatePassword(currentPassword: string, newPassword: string, confirmPassword: string): Promise<boolean> {
        const passwordUpdateRequest: IPasswordUpdateRequest = {
            currentPassword: currentPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword
        }
        const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/auth/users/password/update`;
        return await this.PerformPut<boolean>(requestUrl, passwordUpdateRequest);
    }

    public async Get_UserDevices(): Promise<IDeviceInformation[]> {
        try {
            const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/user/getuserdevicebyid`;
            // const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/user/${AppConfigGlobal.ThreadId}/messages/${AppConfigGlobal.UserId}?after=${after}`;
            return await this.PerformGet<IDeviceInformation[]>(requestUrl);
        }
        catch (err: any) {
            console.log("An unexpected error occured", err);
            return [];
        }
    }

    public async GetAllOrganizationByUserId(): Promise<IOrganization[]> {
        try {
            const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/user/organizations`;
            return await this.PerformGet<IOrganization[]>(requestUrl);
        }
        catch (err: any) {
            console.log("An unexpected error occured", err);
            return [];
        }
    }

    public async GetUserConfiguration(): Promise<IUserConfiguration> {
        try {
            const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/user/configurations`;
            return await this.PerformGet<IUserConfiguration>(requestUrl);
        }
        catch (err: any) {
            console.log("An unexpected error occured", err);
            return err;
        }
    }

    public async GetUserAssistantsByOrganizationId(): Promise<IUserAssistant[]> {
        try {
            const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/user/organizations/${AppConfigGlobal.CurrentOrganizationId}/assistants`;
            return await this.PerformGet<IUserAssistant[]>(requestUrl);
        }
        catch (err: any) {
            console.log("An unexpected error occured", err);
            return err;
        }
    }

    public async GetUserThreadsByAssistantId(): Promise<IUserThreadGroup> {
        try {
            const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/assistants/${AppConfigGlobal.CurrentAssistantId}/threads`;
            return await this.PerformGet<IUserThreadGroup>(requestUrl);
        }
        catch (err: any) {
            console.log("An unexpected error occured", err);
            return err;
        }
    }

    public async GetUserBasicDetails(): Promise<IUserBasicDetails> {
        try {
            const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/user`;
            return await this.PerformGet<IUserBasicDetails>(requestUrl);
        }
        catch (err: any) {
            console.log("An unexpected error occured", err);
            return err;
        }
    }

    public async GetUserBasicDetailsById(userId: number): Promise<IUserBasicDetails> {
        try {
            const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/users/${userId}`;
            return await this.PerformGet<IUserBasicDetails>(requestUrl);
        }
        catch (err: any) {
            console.log("An unexpected error occured", err);
            return err;
        }
    }

    public async GetUserCompanyNameById(userId: number): Promise<string> {
        const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/user/companyname`;
        const data = {
            userId: userId
        }
        return await this.PerformPost<string>(requestUrl, data);
    }

    public async UpdateUserName(UserNameUpdateRequest: IUserUserNameUpdateRequest): Promise<boolean> {

        const userId = UserNameUpdateRequest.userId;
        const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/user/${userId}/username/update`;
        const data: IUserUserNameUpdateRequest = {
            userId: userId,
            userName: UserNameUpdateRequest.userName
        }
        return await this.PerformPut<boolean>(requestUrl, data);
    }

    public async CheckDuplicateUsername(userNameUpdateRequest: IUserUserNameUpdateRequest): Promise<boolean> {
        const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/user/username/checkforduplicate`;
        return await this.PerformPost<boolean>(requestUrl, userNameUpdateRequest);
    }
}
