<template>
  <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.0//EN" "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
  <div class="dropdown-icon">
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M9.99935 10.8359C10.4596 10.8359 10.8327 10.4628 10.8327 10.0026C10.8327 9.54237 10.4596 9.16927 9.99935 9.16927C9.53911 9.16927 9.16602 9.54237 9.16602 10.0026C9.16602 10.4628 9.53911 10.8359 9.99935 10.8359Z" stroke="#CBD6E3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.99935 5.0026C10.4596 5.0026 10.8327 4.62951 10.8327 4.16927C10.8327 3.70903 10.4596 3.33594 9.99935 3.33594C9.53911 3.33594 9.16602 3.70903 9.16602 4.16927C9.16602 4.62951 9.53911 5.0026 9.99935 5.0026Z" stroke="#CBD6E3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.99935 16.6693C10.4596 16.6693 10.8327 16.2962 10.8327 15.8359C10.8327 15.3757 10.4596 15.0026 9.99935 15.0026C9.53911 15.0026 9.16602 15.3757 9.16602 15.8359C9.16602 16.2962 9.53911 16.6693 9.99935 16.6693Z" stroke="#CBD6E3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style scoped lang="scss">

</style>