<template>
    <form class="flex flex-col items-center rounded-2xl bg-background shadow-[0_0_10px_4px_lightGrey] relative font-inter text-white p-6" @submit.prevent="handleSubmit">
        <div class="flex flex-col items-center gap-4">
            <header class="flex flex-col justify-center items-center gap-2 self-stretch">
                <div class="flex w-16 h-16 justify-center items-center gap-2 border-2 border-dashed border-buttonBlue rounded-lg">
                    <UserIcon />
                </div>
                <div class="flex justify-center items-end gap-3 self-stretch">
                    <button @click="hh">
                        <span class="text-white hover:text-buttonBlue active:text-buttonBlue">Generate</span>
                    </button>
                    <button @click="hh">
                        <span class="text-white hover:text-buttonBlue active:text-buttonBlue">Upload</span>
                    </button>
                </div>
            </header>
            <main class="self-center z-0 w-full flex flex-col gap-4">
                <div class="flex flex-col w-full mb-4">
                    <input id="name"
                           v-model="name"
                           type="text"
                           class="rounded-lg bg-menuSelected min-h-[48px] w-full p-4 border border-white/10 text-white"
                           placeholder="Name"
                           required />
                </div>
                <div class="flex flex-col w-full mb-3">
                    <textarea id="description"
                              v-model="description"
                              class="rounded-lg bg-menuSelected min-h-[48px] w-full p-4 border border-white/10 text-white sm:min-h-[100px] sm:p-[12px] sm:pr-4"
                              placeholder="Description of what is should be able to do and with what area of expertise."
                              required></textarea>
                </div>

                <down />
                <button type="submit" class="self-stretch rounded-lg min-h-[44px] w-full text-center p-[13px] pr-4 border border-white text-white cursor-pointer">Request</button>
            </main>
        </div>
        <button class="flex absolute right-2 top-[6.5px] w-[36px] h-[36px] justify-center p-0" @click="closeModal">
            <IconClose />
        </button>
    </form>
</template>

<script lang="ts" setup>
  import { ref } from 'vue';
  import UserIcon from '../../icons/IconUserProfile.vue'
  import IconClose from '../../icons/IconCrossClose.vue'
  import down from './MultiSelectDropdown.vue'

  const name = ref('');
  const description = ref('');
  const emit = defineEmits(['close']);

  const closeModal = () => {
    emit('close');
  };
  const hh = () => {
    emit('close');
  };

  const handleSubmit = () => {
    // Handle form submission logic here
    console.log('Form submitted', { name: name.value, description: description.value });
  };
</script>


<!-- <style scoped>
     .placeholder-text {
        flex: 1 0 0;
        color: var(--White, #FFF);
        /* Body Bold */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        opacity: 0.5;
    } 

    /* .login {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-2xl, 20px);
    } */
    /* .login-frame {
        align-items: center;
        border-radius: 20px;
        background: var(--Background-primary, #031525);
        box-shadow: 0 0 10px 4px rgba(255, 255, 255, 0.25);
        position: relative;
        display: flex;
        flex-direction: column;
        font-family: Inter, sans-serif;
        color: var(--White, #fff);
        padding: 25px;
    } */


    /* .icon-close {
        display: flex;
        position: absolute;
        right: 7px;
        top: 6.5px;
        width: 36px;
        height: 36px;
        justify-content: center;
        background: none;
        border: none;
        padding: 0;
        box-shadow: none;
        cursor: pointer;
    }
 */
    /* .login-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
    }
 */
    /* .profile-frame {
        display: flex;
        width: 64px;
        height: 64px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: var(--radius-lg, 10px);
        border: 2px dashed var(--LoginLines, #A7D0F8);
    }
 */
    /* .btn-container {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        gap: var(--spacing-2xl, 20px);
        align-self: stretch;
    }
 */
    /* .btn-upload {
        display: flex;
        align-items: center;
        gap: var(--spacing-sm, 6px);
        flex: 1 0 0;
        background: none;
        border: none;
        padding: 0;
        box-shadow: none;
        cursor: pointer;
    }

    .btn-generate {
        justify-content: flex-end;
    } */

    /* .txt-generate {
        color: var(--LoginLines, #A7D0F8);
    } */

    /* .txt-upload {
        color: var(--White, #FFF);
    } */

    /* .login-main {
        align-self: center;
        z-index: 0;
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: var(--spacing-xl, 16px);
        align-self: stretch;
    } */

    /* .input-group {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-bottom: 12px;
    } */

    /* .input-field {
        border-radius: 12px;
        background-color: rgba(17, 56, 91, 1);
        min-height: 48px;
        width: 100%;
        padding: 16px;
        border: 1px solid rgba(255, 255, 255, 0.09);
        color: #fff;
    }
 */
    /* .input-field--large {
        min-height: 100px;
        padding: 12px 16px;
    } */

    /* .input-field--select {
        appearance: none;
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
        background-repeat: no-repeat;
        background-position: right 12px top 50%;
        background-size: 12px auto;
        padding-right: 30px;
    } */

    /* .submit-button {
        align-self: stretch;
        border-radius: 12px;
        min-height: 44px;
        width: 100%;
        text-align: center;
        padding: 13px 16px;
        border: 1px solid rgba(255, 255, 255, 1);
        background: transparent;
        color: #fff;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }
 */
        /* .submit-button:hover,
        .submit-button:focus {
            background-color: rgba(255, 255, 255, 0.1);
        } */

    /* .decorative-logo {
        position: absolute;
        z-index: 0;
        right: 7px;
        top: 6px;
        width: 36px;
        height: 36px;
    }

    .visually-hidden {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
    } */
</style> -->