<template>
    <div class="success-failure">
        <div class="success-failure__container">
            <div class="success-failure__card">
                <div class="success-failure__icon-container">
                    <svg v-if="!isSuccess"
                         width="56"
                         height="56"
                         viewBox="0 0 56 56"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <rect x="3.5"
                              y="3.5"
                              width="49"
                              height="49"
                              rx="24.5"
                              fill="#F79009" />
                        <rect x="3.5"
                              y="3.5"
                              width="49"
                              height="49"
                              rx="24.5"
                              stroke="#DC6803"
                              stroke-width="7" />
                        <path d="M27.9987 23.3359V28.0026M27.9987 32.6693H28.0104M39.6654 28.0026C39.6654 34.4459 34.442 39.6693 27.9987 39.6693C21.5554 39.6693 16.332 34.4459 16.332 28.0026C16.332 21.5593 21.5554 16.3359 27.9987 16.3359C34.442 16.3359 39.6654 21.5593 39.6654 28.0026Z"
                              stroke="white"
                              stroke-width="2.33333"
                              stroke-linecap="round"
                              stroke-linejoin="round" />
                    </svg>
                </div>
                <h1 class="success-failure__title h5Bold.;=-">Oops!</h1>

                <p class="success-failure__description alertText">
                    An error happened while resetting your password!
                </p>
                <button type="submit" class="success-failure__button h7Semibold">Try again</button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import router from "@/router";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

const isSuccess = ref<string>("");
// const isSuccess = ref(false);

onMounted(() => {
  //   if (route.state && route.state.isSuccess !== undefined) {
  //     isSuccess.value = route.state.isSuccess;
  //   } else {
  //     console.log("ahvdja", route);
  //     // Handle the case where state is not passed or is undefined
  //     console.log("State is missing or undefined.");
  //   }
});
</script>

<style scoped lang="scss">
    $background-primary: var(--Background-primary, #031525);
    $login-lines: var(--LoginLines, #a7d0f8);
    $selected: var(--Selected, #11385b);
    $button-background: var(--ButtonBackground, #ffffff);
    $white: #fff;
    $black: #000;

    @mixin flex-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @mixin container-size($width, $height) {
        width: $width;
        height: $height;
    }

    @mixin border-radius($radius) {
        border-radius: $radius;
    }

    @mixin full-width-height {
        width: 100vw;
        height: 100vh;
    }

    .success-failure {
        background-image: url(/src/assets/Background.svg);
        background-repeat: no-repeat;
        background-size: cover;
        @include full-width-height;
        @include flex-center;

        &__container {
            background: $background-primary;
            border: 3px solid $login-lines;
            @include container-size(364px, auto);
            padding: 25px;
            @include border-radius(40px);
        }

        &__icon-container {
            @include flex-center;
        }

        &__title {
            color: $white;
            text-align: center;
            margin-top: 20px;
        }

        &__description {
            color: $white;
            margin-top: 10px;
            margin-bottom: 15px;
            text-align: center;
        }

        &__button {
            @include container-size(100%, 52px);
            @include border-radius(16px);
            margin-bottom: 15px;
            background: $button-background;
            color: $black;
            border: none;
            cursor: pointer;

            &:focus,
            &:focus-visible {
                outline: 0 !important;
                box-shadow: none !important;
            }
        }
    }
</style>
