import type { IAssistant } from "../Models/Common/IAssistant";
import Core from "./Core";
import AppConfigGlobal from "../core/config/uiSettings";
import axios from "axios";

export class AssistantService extends Core {
  public async getAllAssistants(): Promise<IAssistant[]> {
    try {
      const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organizations/${AppConfigGlobal.CurrentOrganizationId}/assistants`;
      const response = await axios.get<IAssistant[]>(requestUrl);
      return response.data ?? [];
    } catch (error: any) {
      console.error("An unexpected error occurred:", error);
      return [];
    }
  }

  public async GetAssistantPresetPrompts(): Promise<string[]> {
    try {
      const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/${AppConfigGlobal.CurrentAssistantId}/preset-prompts`;
      const response = await this.PerformGet<string[]>(requestUrl);
      return response ?? [];
    } catch (error: any) {
      console.error("An unexpected error occurred:", error);
      return [];
    }
  }
}
