<template>
    <nav class="settings-nav pt-3">
        <div class="settings-content">
            <div class="settings-content-head flex justify-between ">
                <h1 class="settings-title h6Bold">Settings</h1>
                <img class="cursor-pointer" src="../../assets/chevron-left.svg" alt="" @click="closeView">
            </div>
            <ul class="settings-menu mt-3">
                <li v-for="(item, index) in menuItems" :key="index" class="menu-item hover:bg-menuSelected rounded-lg p-3" :class="{ active: selectedItem.text == item.text }" @click="itemSelected(item)">
                    <a :href="item.href" class="menu-link flex content-between">
                        <img :src="item.icon" :alt="item.text" class="menu-icon mr-3" />
                        <span class="menu-text h7">{{ item.text }}</span>
                    </a>
                </li>
            </ul>
        </div>
        <!--<div class="account-info">
            <div class="user-details">
                <h2 class="user-name">Olivia Rhye</h2>
                <p class="user-email">olivia@untitledui.com</p>
            </div>
            <button class="account-button" aria-label="Account options">
                <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/f7baf370aaefb596584e590a7ebf2c59e9e617fea35dd40d1e81ba28f9edb26f?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51" alt="" class="account-icon" />
            </button>
        </div>-->
    </nav>
</template>

<script setup lang="ts">
    import { ref, defineEmits } from 'vue';
    import type { ISettingsMenuItem } from "@/Models/Common/ISettingsMenuItem";

    const emits = defineEmits(['closeSettings', 'settingsItemSelected']);

    const menuItems = ref<ISettingsMenuItem[]>([
        { text: 'Profile', icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/4f850ad6de9a91591861eab8e5a6cffa6e5619420bf8ec5f2a89192ad4e467c5?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51', href: '#profile', active: false },
        { text: 'Password', icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/abe282621c99c2668b106b3b873714068aa01907bf90df5f9669a3fd965c3dd6?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51', href: '#password', active: false },
        { text: 'Notifications', icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/f650c1d7aa3c010ef13b7d59fa1d2364d16beda5da196be68cd73fd0c67eebd5?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51', href: '#notifications', active: false },
        { text: 'Assistants', icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/6c7978a643d462e4ffe743f181d4e6bc6e6d4ced78ea9062e8ede2f9996f6a46?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51', href: '#assistants', active: false },
        { text: 'Organizations', icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/e3a9725860641554a3af02e3286489597dae6371954c447c33604804c10a1086?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51', href: '#organisations', active: false },
    ]);

    const selectedItem = ref<ISettingsMenuItem>({ ...menuItems.value[0]});

    const closeView = () => {
        emits('closeSettings');
    };

    const itemSelected = (item: ISettingsMenuItem) => {
        selectedItem.value = { ...item };
        emits('settingsItemSelected', item);
        item.active = true;
    };
</script>

<!--
<style scoped lang="scss">
    .settings-nav {
        background-color: #071a2b;
        display: flex;
        // max-width: 480px;
        // width: 100%;
        margin-left: -25%;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        // margin: 0 auto;
        font-family: Inter, sans-serif;
    }

    .settings-content {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 36px 16px 0;
        gap: 16px;
    }

    .settings-content-head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .settings-title {
        color: #fff;
    }

    .settings-menu {
        list-style-type: none;
        padding: 0;
        margin: 0;
        color: #cbd6e3;
    }

    .menu-item {
        border-radius: 6px;
        margin-bottom: 4px;
    }

    .menu-item.active {
        background-color: #11385b;
    }

    .menu-link {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        text-decoration: none;
        color: inherit;
    }

    .menu-icon {
        width: 24px;
        height: 24px;
        margin-right: 12px;
    }

    .account-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 20px;
        margin-top: auto;
    }

    .user-details {
        font-size: 14px;
    }

    .user-name {
        color: #101828;
        font-weight: 600;
        margin: 0;
    }

    .user-email {
        color: #475467;
        margin: 0;
    }

    .account-button {
        background: none;
        border: none;
        cursor: pointer;
        padding: 8px;
    }

    .account-icon {
        width: 20px;
        height: 20px;
    }

    .visually-hidden {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
    }

    @media (max-width:768px) {
        .settings-nav {
            margin-left: -37px;
            width: 90%;
        }
    }

    @media (max-width: 600px) {
        .settings-nav {
            width: auto;
            margin-left: -78px;
        }
    }
</style>-->
