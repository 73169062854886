<template>
    <header class="section-header">
        <div class="header-content">
            <div class="title-container">
                <h2 class="section-title h6Bold">Join your Organization/s</h2>
                <p class="section-description bodySmall">
                    You can search for organizations in the search bar. You can also join multiple organizations.
                </p>
            </div>
            <div class="actions">
                <button class="request-button" @click="requestOrganization">
                    <IconPlus class="button-icon"/>
                    <span class="bodySmallSemibold">Request Organization</span>
                </button>
            </div>
            <div class="dropdown">
                <span class="dropdown-icon"><IconVerticalDots /></span>
            </div>
        </div>
    </header>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import IconPlus from '../../icons/IconPlus.vue';
    import IconPlusSquare from '../../icons/IconPlusSquare.vue';
    import IconVerticalDots from '../../icons/IconVerticalDots.vue';



export default defineComponent({
  name: 'SectionHeader',
  components: {
    IconPlus,
    IconPlusSquare,
    IconVerticalDots
  },
  methods: {
    requestOrganization() {
      // Implement request organization logic
      console.log('Request organization clicked');
    },
  },
});
</script>

<style scoped>
    .section-header {
        font-family: Inter, sans-serif;
    }

    .header-content {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        flex-wrap: wrap;
    }

    .title-container {
        flex: 1;
        min-width: 240px;
    }

    .section-title {
        color: #fff;
    }

    .section-description {
        color: rgba(255, 255, 255, 0.4);
        margin-top: 4px;
    }

    .actions {
        display: flex;
        align-items: center;
    }

    .request-button {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 10px 14px;
        background-color: #7ba8ef;
        border: 1px solid #a7d0f8;
        border-radius: 8px;
        color: #fff;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
    }

    .button-icon {
        width: 20px;
        height: 20px;
    }

    .dropdown {
        display: flex;
        align-items: center;
    }

    .dropdown-icon {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
</style>