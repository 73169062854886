<template>
    <div class="spinner_container">
        <span class="loader"></span>
    </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    isSpinLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    console.log(props.isSpinLoading);
  },
});
</script>

<style scoped>
    .spinner_container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loader {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        border: 5px solid #fff;
        border-bottom-color: transparent;
        animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
</style>
