<template>
    <div class="loader-container">
        <div class="loader-container outer">
            <div class="loader-container outer inner">
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
    .loader-container {
        display: flex;
        width: 300px;
        height: 300px;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &.outer {
            background: white;
            width: 70%;
            height: 70%;
            /*position: absolute;*/
            clip-path: circle(50%);
            animation: move 1s alternate infinite;
        }
    }

    @keyframes move {
        0% {
            clip-path: circle(35%);
        }

        25% {
            clip-path: circle(45%);
        }

        50% {
            clip-path: circle(50%);
        }

        75% {
            clip-path: circle(45%);
        }

        100% {
            clip-path: circle(35%);
        }
    }
</style>