<template>
  <!-- <div class="Loading__Container"> -->
  <div class="Loading__Container Loader"></div>
  <!-- </div> -->
</template>

<script></script>

<style lang="scss">
// .Loading__Container {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 200px;
//   max-width: 200px;
//   overflow: hidden;
//   height: 320px;

.Loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 1px solid transparent;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}
// }

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
