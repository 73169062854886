<template>
    <div v-if="visible" class="toaster active"
         :class="{ active: isSuccess == 'success', error: isSuccess != 'success' }">
        <div class="toaster-content">
            <img loading="lazy"
                 src="https://cdn.builder.io/api/v1/image/assets/TEMP/0288f0db0ef6fb60b6713f357c8e3e0f54ef40febaa1ee9fea9aca04f4487594"
                 class="icon-check"
                 alt="Check mark icon" />
            <span class="h7 notification-message">{{ message }}</span>
            <button class="close-btn" @click="closeNotification">
                <img loading="lazy"
                     src="https://cdn.builder.io/api/v1/image/assets/TEMP/2800f53242bf25cc2443f87ca37941688806038894c794528f8578b4b55bdeb8"
                     class="icon-close"
                     alt="Close icon" />
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref, defineProps } from "vue";

    const props = defineProps({
        message: {
            type: String,
            default: "You have successfully updated the password",
        },
        isSuccess: {
            type: String,
            default: "",
        },
        isVisible: {
            type: Boolean,
            default: true,
        },
    });

    const visible = ref(props.isVisible);

    const closeNotification = () => {
        visible.value = false;
    };
</script>

<style scoped>
    .toaster {
        display: flex;
        padding: 10px;
        align-items: center;
        gap: 10px;
        position: absolute;
        left: 50%;
        border-radius: var(--radius-lg, 10px);
        background: var(--Colors-Green-600, #099250);
        min-width: 450px;
    }

    .toaster-content {
        display: flex;
        justify-content: space-between; 
        width: 100%;
    }

    .icon-check {
        width: 24px;
        height: 25px;
    }

    .notification-message {
        color: var(--White, #FFF);
    }

    .close-btn {
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
    }

    .icon-close {
        width: 16px;
        height: 16px;
    }

    @media (max-width: 1024px) {
        .toaster {
        left: 30%;
        min-width: 427px;
    }
    }
    @media (max-width: 768px) {
        .toaster {
            left: 30%;
            min-width: 352px;
    }
    }
</style>
