<template>
    <div class="bg-custom-bg bg-cover bg-center w-screen h-screen flex justify-center items-center">
        <div class="bg-[var(--color-background)] border-[3px] border-[#a7d0f8] w-[350px] min-h-[430px] p-[25px] rounded-[40px]">
            <h1 class="text-[3.125rem] font-medium text-center mb-3 font-inter leading-[60.51px] text-white">
                Freddy
            </h1>
            <h2 class="font-inter mb-[16px] font-medium text-center text-[1.813rem] leading-[2.194rem] text-white">
                Welcome
            </h2>
            <div v-if="passwordError || usernameError"
                 class="flex text-red-500 mb-4 items-center">
                <span class="mr-2.5">
                    <IconCircleVue />
                </span>
                <p class="error-message text-sm">{{ responseMessage }}</p>
            </div>
            <form @submit.prevent="handleSubmit">
                <div class="mb-2">
                    <input class="w-full h-12 px-4 py-3 pl-4 pr-9 mb-2 rounded-lg border border-[var(--color-menu-selected)] bg-[var(--color-menu-selected)] font-inter text-white font-medium text-sm focus:outline outline-1 outline-white placeholder:text-[var(--color-text-grey)] placeholder:font-medium placeholder:text-sm placeholder:font-inter"
                           v-model.trim="authRequest.emailorusername"
                           type="text"
                           id="email"
                           name="email"
                           @focus="clearerrors"
                           placeholder="User name or email"
                           autocomplete="off" />
                    <div v-if="usernameEmpty"
                         class="flex text-red-500 mb-2.5 items-center">
                        <span class="mr-2.5">
                            <IconCircleVue />
                        </span>
                        <p class="error-message text-sm">{{ responseMessage }}</p>
                    </div>
                    <!-- <div v-if="emailError" class="flex text-red-500 mb-2.5 items-center">
                      <span class="mr-2.5">
                        <IconCircleVue />
                      </span>
                      <p class="error-message text-[13px]">{{ emailError }}</p>
                    </div> -->
                </div>
                <div class="mb-2 relative">
                    <input v-model="authRequest.password"
                           :type="passwordVisible ? 'password' : 'text'"
                           id="password"
                           name="password"
                           placeholder="Password"
                           @focus="clearerrors"
                           class="w-full h-12 px-4 py-3 pl-4 pr-9 mb-2 rounded-lg border border-[var(--color-menu-selected)] bg-[var(--color-menu-selected)] font-inter text-white font-medium text-sm focus:outline outline-1 outline-white placeholder:text-[var(--color-text-grey)] placeholder:font-medium placeholder:text-sm placeholder:font-inter"
                           autocomplete="off" />
                    <span class="absolute top-[15px] right-2 cursor-pointer">
                        <span v-if="!passwordVisible">
                            <IconEyeSlash @click="togglePasswordVisibility" />
                        </span>
                        <span v-else>
                            <IconEye @click="togglePasswordVisibility" />
                        </span>
                    </span>
                    <div v-if="passwordEmpty"
                         class="flex text-red-500 mb-2.5 items-center">
                        <span class="mr-2.5">
                            <IconCircleVue />
                        </span>
                        <p class="error-message text-sm">{{ responseMessage }}</p>
                    </div>
                </div>
                <button type="submit"
                        class="w-full h-[52px] mb-4 text-[17px] font-semibold bg-[var(--color-white)] text-[#000000] border-none rounded-2xl cursor-pointer"
                        :disabled="loading"
                        @click="handleSubmit">
                    <ButtonLoader v-if="loading" />
                    <span class="text-[17px] font-medium font-inter leading-[1.063rem]"
                          v-else>Login</span>
                </button>

                <div class="flex justify-center items-center text-white font-semibold mb-2">
                    <div class="mr-2 text-[var(--White,#FFF)] font-inter text-sm font-medium leading-normal">
                        No account?
                    </div>
                    <a href="/user/register"
                       @click.prevent="navigateToRegister"
                       class="text-[var(--LoginLines,#a7d0f8)] bg-transparent font-inter text-sm font-medium leading-normal hover:underline transition duration-300 ease-in-out">Request</a>
                </div>

                <a href="/auth/reset"
                   @click.prevent="navigateToForgotPassword"
                   class="text-center block text-[var(--LoginLines,#a7d0f8)] bg-transparent text-sm font-medium hover:underline transition duration-300 ease-in-out">
                    Forgot Password
                </a>
            </form>
        </div>
    </div>
</template>

<script setup lang="ts">
    import IconKey from "./icons/IconKey.vue";
    import { onMounted, ref } from "vue";
    import { useRouter, useRoute } from "vue-router";
    import IconEyeSlash from "./icons/IconEyeSlash.vue";
    import IconEye from "./icons/IconEye.vue";
    import IconCircleVue from "./icons/IconCircle.vue";
    //import IconLoginSmallCircle from "./icons/IconLoginSmallCircle.vue";
    //  import IconEyeSvg from "./icons/IconEyeSvg.vue";
    //import UserLoggedIn from "./UserLoggedIn.vue";
    //import UserEmailVerification from "./UserEmailVerification.vue";
    import AppConfigGlobal from "../core/config/uiSettings";
    import AuthService from "@/Services/AuthService";
    import type { IUserAuthRequest } from "../Models/Request/User/IUserAuthRequest";
    import type { IComponentMap } from "@/Models/Common/IComponentMap";
    import axios from "axios";
    import ButtonLoader from "./common/ButtonLoader.vue";
    import { Device } from "@capacitor/device";
    import { UserService } from "@/Services/UserService";

    const loading = ref<boolean>(false);

    //import { create } from "domain";
    const authRequest = ref<IUserAuthRequest>({
        emailorusername: "",
        password: "",
        deviceInformation: {
            device: "",
            location: "",
            latitude: "",
            longitude: "",
            deviceId: "",
            operatingSystem: "",
            platform: "",
        },
    });
    const emailIdorusername = ref<string>("");
    const passwordError = ref<boolean>(false);
    const passwordEmpty = ref<boolean>(false);
    const usernameEmpty = ref<boolean>(false);
    const usernameError = ref<boolean>(false);
    const emailError = ref<string>("");
    const serverError = ref<string>("");
    const isLoggedIn = ref<boolean>(false);
    const isRegistering = ref<boolean>(false);
    const passwordVisible = ref<boolean>(true);
    const showBottom = ref<boolean>(false);
    const responseMessage = ref<string>("");

    const userService = new UserService();
    const router = useRouter();
    const route = useRoute();
    const handleSubmit = async () => {
        if (isRegistering.value) {
            await register();
        } else {
            await logIn();
        }
    };
    onMounted(async () => {
        const authService = new AuthService();

        const token = authService.GetCookieValue("freddyUserId");
        if (token) {
            if (route.query.returnUrl == "/auth/login") {
                router.push("/freddy/assistant");
            }
            const returnUrl = (route.query.returnUrl as string) || "/logged";
            //const returnUrl = route.query.returnUrl as string;
            router.push(returnUrl);
        }
        getUserLocation();
        //---> Set all the config value to default
        AppConfigGlobal.CurrentOrganizationId = 3;
        AppConfigGlobal.CurrentAssistantId = 1;
        AppConfigGlobal.CurrentThreadId = 0;
        AppConfigGlobal.Organizations = [];
        AppConfigGlobal.UserId = 40;
        AppConfigGlobal.UserName = "";
        AppConfigGlobal.UserImage = "";
    });
    const register = () => { };
    const isSuccess = ref<boolean>(false);
    const logIn = async () => {
        try {
            if (authRequest.value.emailorusername.trim() == "") {
                passwordEmpty.value = false;
                usernameEmpty.value = true;
                responseMessage.value = "Enter a valid user name or email";
                return;
            }
            if (authRequest.value.password.trim() == "") {
                passwordEmpty.value = true;
                responseMessage.value = "Enter a valid password";
                return;
            }

            loading.value = true;
            const authService = new AuthService();
            const authResponse = await authService.logIn(authRequest.value);
            if (authResponse && authResponse.token) {
                authService.CreateCookie("freddyUserId", authResponse.token);
                authService.CreateCookie("refreshToken", authResponse.refreshToken.token);
                authService.CreateCookie(
                    "tokenExpiration",
                    authResponse.refreshToken.expiry
                );
                isSuccess.value = true;
                authService.CreateCookie("deviceId", authResponse.deviceId);
                isLoggedIn.value = true;
                loading.value = false;
                // const config = await userService.GetUserConfiguration();
                // sessionStorage.setItem("orgid", config.id.toString());
                if (route.query.returnUrl == "/auth/login") {
                    router.push("/freddy/assistant");
                } else {
                    if (isValidReturnUrl(route.query.returnUrl as string)) {
                        window.location.href = route.query.returnUrl as string;
                    }
                    const returnUrl = (route.query.returnUrl as string) || "/logged";
                    router.push(returnUrl);
                }
            }
            // if (authResponse.responseMessage == "User name not found") {
            //   usernameError.value = true;
            //   responseMessage.value = authResponse.responseMessage;
            // }
            // if (authResponse.responseMessage == "Incorrect Password") {
            //   passwordError.value = true;
            //   responseMessage.value = authResponse.responseMessage;
            // }
        } catch (error: any) {
            if (error.response.data?.message == "User name not found") {
                usernameError.value = true;
                responseMessage.value = "Unknown user name or password";
            }
            if (error.response.data.message == "Incorrect password") {
                passwordError.value = true;
                responseMessage.value = "Unknown user name or password";
            }
        } finally {
            loading.value = false; // Stop the loader
        }
    };
    const isValidReturnUrl = (url: string) => {
        try {
            const parsedUrl = new URL(url);
            const allowedDomains = AppConfigGlobal.AllowedDomains;
            if (allowedDomains.includes(parsedUrl.hostname)) {
                return !!parsedUrl.hostname;
            } else {
                return false;
            }
        } catch (e) {
            return false; // If the URL is invalid, return false
        }
    };

    const clearerrors = () => {
        clearUsernameError();
        clearPasswordError();
    };
    const clearUsernameError = () => {
        usernameError.value = false;
        usernameEmpty.value = false;
    };

    const clearPasswordError = () => {
        passwordError.value = false;
        passwordEmpty.value = false;
    };

    const logOut = () => {
        const authService = new AuthService();
        authService.ClearCookie("freddyUserId");
        authService.ClearCookie("refreshToken");
        authService.ClearCookie("tokenExpiration");
        authService.ClearCookie("freddyUserId");
        router.push("auth/login");
    };

    // const handleError = (error: Error) => {
    //   // Clear previous error messages
    //   // // Set the error message based on the error code
    //   // switch (error.message) {
    //   //     case "auth/invalid-credential":
    //   //         passwordError.value = "Password invalid";
    //   //         break;
    //   //     case "Internal Server Error":
    //   //         showBottom.value = true;
    //   //         serverError.value =
    //   //             "There was an error that is very bad and has to be changed asap and that is terrible i am so sorry";
    //   //     default:
    //   //         emailError.value = "User name not found";
    //   // }
    //   emailError.value = "";
    //   passwordError.value = "";

    //   // Set the error message based on the error code
    //   switch (error.message) {
    //     case "auth/invalid-credential":

    //       break;
    //     case "Internal Server Error":
    //       showBottom.value = true;
    //       serverError.value =
    //         "There was an error that is very bad and has to be changed asap and that is terrible i am so sorry";
    //       break;
    //     default:

    //   }
    // };

    const loggingIn = () => {
        isLoggedIn.value = true;
    };

    const toggleForm = () => {
        isRegistering.value = !isRegistering.value;
    };

    const togglePasswordVisibility = () => {
        passwordVisible.value = !passwordVisible.value;
    };

    const proceed = () => {
        router.push("/logged");
    };

    const navigateToForgotPassword = () => {
        router.push("/auth/reset");
    };

    const navigateToRegister = () => {
        router.push("/user/register");
    };

    // Callback to set the location
    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(setLocation, showError);

        } else {
            alert('Geolocation is not supported by this browser.');
        }
        getDeviceDetails();
    };

    const getDeviceDetails = async () => {
        const deviceId = await Device.getId();
        const deviceinfo = await Device.getInfo();
        authRequest.value.deviceInformation.deviceId = deviceId.identifier;
        authRequest.value.deviceInformation.device = deviceinfo.model;
        authRequest.value.deviceInformation.operatingSystem = deviceinfo.operatingSystem;
        authRequest.value.deviceInformation.platform = deviceinfo.platform;
    };
    // Callback to set the location
    const setLocation = async (position: { coords: { latitude: number; longitude: number; }; }) => {

        authRequest.value.deviceInformation.latitude = position.coords.latitude.toString();
        authRequest.value.deviceInformation.longitude = position.coords.longitude.toString();

        await getLocationName(position.coords.latitude.toString(), position.coords.longitude.toString());
    };
    const showError = (error: { message: string; }) => {
        console.error(error.message);
    };
    const getLocationName = async (latitude: string, longitude: string) => {
        try {
            const response = await axios.get(
                `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=10&addressdetails=1`
            );
            const address = response.data.address;

            // Construct a readable location name (e.g., city, state, country)
            const locname = `${address.city || address.town || address.village || ""
                }, ${address.state || ""}, ${address.country || ""}`;

            authRequest.value.deviceInformation.location = locname;
        } catch (error) {
            console.error("Error fetching location name:", error);
        }
    };
</script>
