<template>
  <div
    class="bg-custom-bg bg-cover bg-center w-screen h-screen flex justify-center items-center"
  >
    <div
      class="bg-[var(--color-background)] w-[21.875rem] relative rounded-[2.5rem] border-[0.188rem] border-lightBlue p-[1.563rem]"
    >
      <div class="flex flex-col items-center justify-items-start gap-5">
        <div
          class="border border-lightBlue w-16 h-16 rounded-[1.25rem] flex justify-center items-center"
        >
          <svg
            width="34"
            height="37"
            viewBox="0 0 34 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.9985 1.83594V8.5026M16.9985 28.5026V35.1693M7.83181 18.5026H1.7207M32.2763 18.5026H26.1651M27.8127 30.3L23.4915 25.5859M27.8127 6.83584L23.4915 11.5499M6.18422 30.3L10.5054 25.5859M6.18422 6.83584L10.5054 11.5499"
              stroke="#A7D0F8"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="flex flex-col items-center gap-2 justify-items-start">
          <div class="text-[1.813rem] font-semibold">Create a Username</div>
          <div class="bodySmall text-center text-sm font-medium">
            Please add a username you'll automatically be directed to your
            organisation after.
          </div>
        </div>
        <div class="flex flex-col items-center gap-4 justify-items-start">
          <div
            class="bg-menuSelected rounded-lg pt-3 pr-3 pb-3 pl-4 flex flex-col gap-2.5 items-start w-[18.75rem] h-12"
          >
            <input
              class="bodySmallSemibold text-white text-left opacity-100 flex items-center justify-items-start gap-1.5 border-none outline-none bg-menuSelected focus:border-none focus:outline-none"
              placeholder="Username"
              @input="showDuplicateMessage"
              v-model="username"
            />
          </div>

          <div
            class="bg-white rounded-2xl p-4 flex flex-row justify-center items-center w-[18.75rem] h-[3.25rem] gap-3 cursor-pointer"
            @click="setUserName"
          >
            <ButtonLoader v-if="loading" />
            <div
              class="h7 text-black text-center flex justify-center items-center text-[1.063rem] font-medium"
              v-else
            >
              Continue
            </div>
          </div>
        </div>
        <div
          class="flex flex-row items-center justify-center gap-1.5 overflow-hidden"
        >
          <div>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.8327 6.9974H1.16602M1.16602 6.9974L6.99935 12.8307M1.16602 6.9974L6.99935 1.16406"
                stroke="white"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div
            class="bodySmallSemibold text-white text-left hover: cursor-pointer hover:underline text-sm font-semibold"
            @click="backToLogin"
          >
            Back to log in
          </div>
        </div>
      </div>
    </div>
    <MessageToaster
      v-if="showToaster"
      :message="toasterMessage"
      isSuccess="error"
      @update:showToaster="showToaster = $event"
    />
  </div>
</template>
<script setup lang="ts">
import type { IUserUserNameUpdateRequest } from "@/Models/Request/User/IUserUserNameUpdateRequest";
import { UserService } from "@/Services/UserService";
import { handleError, onMounted, ref } from "vue";
import MessageToaster from "./MessageToaster.vue";
import { useRouter } from "vue-router";
import ButtonLoader from "./common/ButtonLoader.vue";

let username = ref<string>("");
let isDuplicate = ref<boolean>(false);
let showWarningForDuplicate = ref<boolean>(false);
let isEditing = ref<boolean>(true);
let userIdstring = ref<string>(sessionStorage.getItem("userId") || "0");
let userId = ref<number>(0);
let showToaster = ref<boolean>(false);
let toasterMessage = ref<string>("");
let loading = ref<boolean>(false);

const router = useRouter();
const backToLogin = () => {
  router.push("auth/login");
};

const setUserName = async () => {
  if (username.value == "") {
    showToaster.value = true;
    toasterMessage.value = "Enter a valid username";
  }
  loading.value = true;
  userId.value = parseInt(userIdstring.value);
  const data: IUserUserNameUpdateRequest = {
    userId: userId.value,
    userName: username.value,
  };
  await CheckUsernameDuplicate(data);
  if (!isDuplicate.value) {
    try {
      const userService = new UserService();
      const userResponse = await userService.UpdateUserName(data);
      loading.value = false;
      if (userResponse == true) {
        router.push("/registration/welcome");
      }
      if (userResponse == false) {
        loading.value = false;
        // showToaster.value = true;
        router.push("/registrationaccessdenied");
      }
    } catch (error: any) {
      //router.push("/");
      loading.value = false;
    }
  } else {
    showWarningForDuplicate.value = true;
  }
};

const CheckUsernameDuplicate = async (
  userUserNameUpdateRequest: IUserUserNameUpdateRequest
) => {
  try {
    const userService = new UserService();
    const hasUsername = await userService.CheckDuplicateUsername(
      userUserNameUpdateRequest
    );
    if (hasUsername == true) {
      isDuplicate.value = true;
      showToaster.value = true;
      toasterMessage.value =
        "This username is already taken.Please try another one";
      loading.value = false;
    } else {
      loading.value = false;
      isDuplicate.value = false;
    }
  } catch (error) {
    loading.value = false;
    console.error();
  }
};

const fetchUsername = async () => {
  try {
    username.value= sessionStorage.getItem("userName")||""
    // const userService = new UserService();
    // userId.value = parseInt(userIdstring.value);
    // const data = await userService.GetUserBasicDetailsById(userId.value);
    // username.value = data.userName; 
  } catch (error) {
    console.error("Error fetching username:", error);
  }
};

const showDuplicateMessage = () => {
  showWarningForDuplicate.value = false;
};
// Fetch the username when the component is mounted
onMounted(() => {
  fetchUsername();
});
</script>
