<template>
    <div class="flex flex-row items-center justify-end text-[14px] hover:cursor-pointer text-white group" @click="toggleHomeIcon">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path d="M9.375 21.8731V14.1647C9.375 13.5813 9.375 13.2896 9.48853 13.0668C9.5884 12.8708 9.74776 12.7115 9.94376 12.6116C10.1666 12.4981 10.4583 12.4981 11.0417 12.4981H13.9583C14.5417 12.4981 14.8334 12.4981 15.0562 12.6116C15.2522 12.7115 15.4116 12.8708 15.5115 13.0668C15.625 13.2896 15.625 13.5813 15.625 14.1647V21.8731M11.4768 2.87724L4.41186 8.37217C3.93961 8.73948 3.70348 8.92313 3.53336 9.15314C3.38267 9.35687 3.27042 9.58639 3.20211 9.83042C3.125 10.1059 3.125 10.4051 3.125 11.0033V18.5397C3.125 19.7065 3.125 20.2899 3.35207 20.7355C3.55181 21.1275 3.87052 21.4463 4.26252 21.646C4.70817 21.8731 5.29156 21.8731 6.45833 21.8731H18.5417C19.7084 21.8731 20.2918 21.8731 20.7375 21.646C21.1295 21.4463 21.4482 21.1275 21.6479 20.7355C21.875 20.2899 21.875 19.7065 21.875 18.5397V11.0033C21.875 10.4051 21.875 10.1059 21.7979 9.83042C21.7296 9.58639 21.6173 9.35687 21.4666 9.15314C21.2965 8.92313 21.0604 8.73948 20.5881 8.37217L13.5232 2.87724C13.1573 2.5926 12.9743 2.45029 12.7722 2.39558C12.594 2.34731 12.406 2.34731 12.2278 2.39558C12.0257 2.45029 11.8427 2.5926 11.4768 2.87724Z" :stroke="isClicked ? 'white' : 'rgba(255, 255, 255, 0.4)'" class="group-hover:stroke-white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const isClicked = ref(false);

const toggleHomeIcon = () => {
    isClicked.value = !isClicked.value; 
};
</script>

<style scoped lang="scss">
    // .icon-home {
    //     display: flex;
    //     flex-direction: row;
    //     align-items: center;
    //     justify-content: flex-end;
    //     font-size: 14px;
    //     color: rgb(177, 168, 168);

    //     &:hover {
    //         cursor: pointer;
    //     }
    // }
</style>