<template>
    <div class="cross-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
  <path d="M25.5 10.5L10.5 25.5M10.5 10.5L25.5 25.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
    </div>
  </template>
  
  <script>
  
  </script>
  
  <style>
  .cross-icon{
      position: absolute;
  right: 7px;
  top: 6.5px;
  }
  </style>