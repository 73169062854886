<template>
    <div class="loader-container">
        <div class="item1"></div>
        <div class="item1"></div>
        <div class="item1"></div>
        <div class="item1"></div>
        <div class="item1"></div>
    </div>
</template>

<script lang="ts" setup>

</script>

<style scoped lang="scss">
    .loader-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
    }

    .item1 {
        @extend .item-style;
        width: 60px;
        height: 90px;
        animation: move 1s ease-in-out infinite;

        &:nth-child(1) {
            animation-delay: 0.25s;
        }

        &:nth-child(2) {
            animation-delay: 0.35s;
        }

        &:nth-child(3) {
            animation-delay: 0.45s;
        }

        &:nth-child(4) {
            animation-delay: 0.65s;
        }

        &:nth-child(5) {
            animation-delay: 0.75s;
        }
    }

    .item-style {
        border-radius: 999999px;
        background: #FFF;
    }

    @keyframes move {
        0%, 100% {
            height: 10px;
        }

        50% {
            height: 150px;
        }
    }
</style>