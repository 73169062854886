<template>
    <div class="icon-video">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M29.3337 11.9059C29.3337 11.0981 29.3337 10.6943 29.1739 10.5072C29.0353 10.345 28.8274 10.2589 28.6147 10.2756C28.3695 10.2949 28.0839 10.5805 27.5127 11.1516L22.667 15.9974L27.5127 20.8432C28.0839 21.4143 28.3695 21.6999 28.6147 21.7192C28.8274 21.7359 29.0353 21.6498 29.1739 21.4876C29.3337 21.3005 29.3337 20.8967 29.3337 20.0889V11.9059Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2.66699 13.0641C2.66699 10.8239 2.66699 9.70375 3.10297 8.8481C3.48646 8.09545 4.09838 7.48353 4.85103 7.10004C5.70668 6.66406 6.82678 6.66406 9.06699 6.66406H16.267C18.5072 6.66406 19.6273 6.66406 20.483 7.10004C21.2356 7.48353 21.8475 8.09545 22.231 8.8481C22.667 9.70375 22.667 10.8239 22.667 13.0641V18.9307C22.667 21.1709 22.667 22.291 22.231 23.1467C21.8475 23.8993 21.2356 24.5113 20.483 24.8948C19.6273 25.3307 18.5072 25.3307 16.267 25.3307H9.06699C6.82678 25.3307 5.70668 25.3307 4.85103 24.8948C4.09838 24.5113 3.48646 23.8993 3.10297 23.1467C2.66699 22.291 2.66699 21.1709 2.66699 18.9307V13.0641Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </div>
</template>

<script setup lang="ts">

</script>

<style>
    .icon-video {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        color: rgb(177, 168, 168);
    }
</style>