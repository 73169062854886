<template>
    <div class="bg-custom-bg bg-cover bg-center w-screen h-screen flex justify-center items-center">
        <div class="bg-[var(--color-background)] border-[0.188rem] border-lightBlue w-[21.875rem] rounded-[2.5rem] p-[1.563rem]">
     
                <div class="flex justify-center items-center">
                    <svg width="56"
                         v-if="isSuccess"
                         height="56"
                         viewBox="0 0 56 56"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <rect x="3.5"
                              y="3.5"
                              width="49"
                              height="49"
                              rx="24.5"
                              fill="#68C57C" />
                        <rect x="3.5"
                              y="3.5"
                              width="49"
                              height="49"
                              rx="24.5"
                              stroke="#079455"
                              stroke-width="7" />
                        <path d="M22.7487 28.0026L26.2487 31.5026L33.2487 24.5026M39.6654 28.0026C39.6654 34.4459 34.442 39.6693 27.9987 39.6693C21.5554 39.6693 16.332 34.4459 16.332 28.0026C16.332 21.5593 21.5554 16.3359 27.9987 16.3359C34.442 16.3359 39.6654 21.5593 39.6654 28.0026Z"
                              stroke="white"
                              stroke-width="2.33333"
                              stroke-linecap="round"
                              stroke-linejoin="round" />
                    </svg>
                    <svg v-if="!isSuccess"
                         width="56"
                         height="56"
                         viewBox="0 0 56 56"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <rect x="3.5"
                              y="3.5"
                              width="49"
                              height="49"
                              rx="24.5"
                              fill="#F79009" />
                        <rect x="3.5"
                              y="3.5"
                              width="49"
                              height="49"
                              rx="24.5"
                              stroke="#DC6803"
                              stroke-width="7" />
                        <path d="M27.9987 23.3359V28.0026M27.9987 32.6693H28.0104M39.6654 28.0026C39.6654 34.4459 34.442 39.6693 27.9987 39.6693C21.5554 39.6693 16.332 34.4459 16.332 28.0026C16.332 21.5593 21.5554 16.3359 27.9987 16.3359C34.442 16.3359 39.6654 21.5593 39.6654 28.0026Z"
                              stroke="white"
                              stroke-width="2.33333"
                              stroke-linecap="round"
                              stroke-linejoin="round" />
                    </svg>
                </div>
                <h1 class="h5Bold text-white text-center mt-5 text-2xl font-bold" v-if="isSuccess">
                    <!-- You've Registered successfully -->
                    You've been logged in successfully
                </h1>
                <h1 class="h5Bold success-failure__title" v-if="!isSuccess">Oops!</h1>
                <p class="text-center mt-2 text-[0.938rem] font-normal" v-if="isSuccess">
                    Please click the button below to continue to the interface.
                </p>
                <p class="alertText success-failure__description" v-if="!isSuccess">
                    An error happened while setting up your account!
                </p>
                <button type="submit" class="h7Semibold mt-3 w-full h-[3.25rem] rounded-2xl bg-white
                text-black border-none cursor-pointer focus:outline-0 focus:shadow-none focus-within:outline-0 focus-within:shadow-none text-[1.063rem]"
                v-if="isSuccess" @click="goToFreddy">
                    Continue
                </button>
                <button type="submit" class="h7Semibold success-failure__button" v-if="!isSuccess">
                    Try again
                </button>
       
        </div>
    </div>
</template>
 
<script setup lang="ts">
    import { ref } from "vue";
    import { useRouter } from "vue-router";
 
    const router = useRouter()
    const goToFreddy = async () => {
        router.push("/freddy/assistant");
    };
 
    const isSuccess = ref(true);
</script>