<template>
  <div
    class="bg-custom-bg bg-cover bg-center w-screen h-screen flex justify-center items-center"
  >
    <div
      class="bg-[var(--color-background)] w-[21.875rem] p-[1.563rem] rounded-[2.5rem] border-[0.188rem] border-lightBlue"
    >
      <div class="flex justify-center items-center">
        <div>
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_dd_17101_135)">
              <path
                d="M18 30C18 18.9543 26.9543 10 38 10H62C73.0457 10 82 18.9543 82 30V54C82 65.0457 73.0457 74 62 74H38C26.9543 74 18 65.0457 18 54V30Z"
                fill="#071A2B"
              />
              <path
                d="M18.5 30C18.5 19.2304 27.2304 10.5 38 10.5H62C72.7696 10.5 81.5 19.2304 81.5 30V54C81.5 64.7696 72.7696 73.5 62 73.5H38C27.2304 73.5 18.5 64.7696 18.5 54V30Z"
                stroke="#A7D0F8"
              />
              <path
                d="M48.541 40.5417H41.541C39.9075 40.5417 39.0908 40.5417 38.4669 40.8596C37.9181 41.1392 37.4719 41.5854 37.1922 42.1342C36.8743 42.7581 36.8743 43.5748 36.8743 45.2083V55.125M63.1243 55.125V33.5417C63.1243 31.9082 63.1243 31.0914 62.8064 30.4675C62.5268 29.9187 62.0806 29.4725 61.5318 29.1929C60.9079 28.875 60.0912 28.875 58.4577 28.875H53.2077C51.5742 28.875 50.7575 28.875 50.1335 29.1929C49.5847 29.4725 49.1385 29.9187 48.8589 30.4675C48.541 31.0914 48.541 31.9082 48.541 33.5417V55.125M64.5827 55.125H35.416M53.6452 34.7083H58.0202M53.6452 40.5417H58.0202M53.6452 46.375H58.0202"
                stroke="#A7D0F8"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <filter
                id="filter0_dd_17101_135"
                x="0"
                y="0"
                width="100"
                height="100"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_17101_135"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feMorphology
                  radius="6"
                  operator="dilate"
                  in="SourceAlpha"
                  result="effect2_dropShadow_17101_135"
                />
                <feOffset dy="8" />
                <feGaussianBlur stdDeviation="6" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect1_dropShadow_17101_135"
                  result="effect2_dropShadow_17101_135"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect2_dropShadow_17101_135"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>
      </div>
      <div>
        <h1 class="h5Bold text-center text-2xl font-bold">
          Company welcomes you
        </h1>
        <p class="bodySmall text-sm font-medium text-center mt-2">
          Thank you for joining please proceed to the interface and enjoy
          Freddy.
        </p>
      </div>
      <div class="mt-5 relative">
        <!-- <img src="../assets/Avatar.svg" alt="" /> -->
        <!-- <div class="absolute top-3.5 left-3.5">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M17.5 17.5L14.5834 14.5833M16.6667 9.58333C16.6667 13.4954 13.4954 16.6667 9.58333 16.6667C5.67132 16.6667 2.5 13.4954 2.5 9.58333C2.5 5.67132 5.67132 2.5 9.58333 2.5C13.4954 2.5 16.6667 5.67132 16.6667 9.58333Z" stroke="white" stroke-opacity="0.4" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg> 
        </div> -->
        <div>
          <!-- <input
            type="text"
            placeholder="Search for your organisation"
            class="bodySmall w-[18.75rem] h-12 rounded-xl p-3.5 bg-menuSelected text-sm font-medium pl-10 focus:outline-0"
          /> -->
          <!-- <div
            class="bodySmall w-[18.75rem] h-12 rounded-xl p-3.5 bg-menuSelected text-sm font-medium pl-10 focus:outline-0"
          >
            {{ companyName }}
          </div> -->
          <div
            class="bodySmall w-[18.75rem] h-12 rounded-xl p-3.5 bg-menuSelected text-sm font-medium focus:outline-0 flex justify-around"
          >
            {{ companyName }}
          </div>
        </div>
        <!-- <p class="bodySmall proceed-freddy__name">{{ companyName }}</p> -->
      </div>
      <button
        type="button"
        class="flex w-[18.75rem] h-[3.25rem] p-4 justify-center items-center rounded-2xl bg-white mt-4"
        @click="proceedToFreddy"
      >
        <p class="h7 text-center text-black text-lg font-medium cursor-pointer">
          Continue
        </p>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { UserService } from "@/Services/UserService";
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
// export default defineComponent({
//   components: {},
//   data() {
//     return {};
//   },
//   methods: {},
// });
const router = useRouter();
const companyName = ref<string>("");
let userId = ref<number>(0);

const proceedToFreddy = () => {
  router.push("/auth/login");
};

const UserIdString = ref<string>(sessionStorage.getItem("userId") || "");
onMounted(async () => {
  userId.value = parseInt(UserIdString.value);
  const userService = new UserService();
  const companyById = await userService.GetUserCompanyNameById(userId.value);
  companyName.value = companyById;
});
</script>
