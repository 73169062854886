import type { IUserOrganization } from "../../Models/Common/IUserOrganization";
import { ref, reactive } from "vue";

const AppConfigGlobalDev = reactive({
  CurrentOrganizationId: 3, //-->>PITS
  CurrentAssistantId: 1, //-->>asst_E5Frmie8wHOabavLONOwcFna
  CurrentThreadId: 0,
  Organizations: ref<IUserOrganization[]>([]),
  UserId: 40,
  UserName: "",
  UserImage: "",
  FreddyAssistantApiUrl: "https://localhost:7053",
  FreddyHubUrl: "https://localhost:7053",
  UserAuthUrl: "https://localhost:7053",
  Domain: "localhost",
  TenantName: "Freddy",
  AllowedDomains: [
    "localhost",
    "freddy-hub.aitronos.ch",
    "freddy-hub.aitronos.com",
  ],
});

export type TGlobalConfig = typeof AppConfigGlobalDev;

if ((window as any)["AppConfigGlobal"]) {
  AppConfigGlobalDev.FreddyAssistantApiUrl = (window as any)["AppConfigGlobal"][
    "FreddyAssistantApiUrl"
  ];
  AppConfigGlobalDev.FreddyHubUrl = (window as any)["AppConfigGlobal"][
    "FreddyHubUrl"
  ];
  AppConfigGlobalDev.UserAuthUrl = (window as any)["AppConfigGlobal"][
    "UserAuthUrl"
  ];
  AppConfigGlobalDev.Domain = (window as any)["AppConfigGlobal"]["Domain"];
  AppConfigGlobalDev.AllowedDomains = (window as any)["AppConfigGlobal"][
    "AllowedDomains"
  ];
}

const AppConfigGlobal = AppConfigGlobalDev;
export default AppConfigGlobal;
