<template>
  <div>
    <i class="bi bi-gear-fill"></i>
  </div>
</template>

<script>
import 'bootstrap-icons/font/bootstrap-icons.css';
export default {
};
</script>

<style>
    .bi-gear-fill {
        color: rgb(177, 168, 168);
    }
    .bi-gear-fill:hover {
        color: white;
        cursor: pointer;
    }
</style>
