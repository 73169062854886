import AppConfigGlobal from "@/core/config/uiSettings";
import axios from "axios";
import Core from "./Core";
import type { IConversation,   ITicketDecline,   ITicketDetails,   ITicketGenerate,   ITickets } from "@/Models/CheckPoints/ITickets";

export class CheckPointsService extends Core {
    
    public async getAllCheckPointsTickets(): Promise<ITickets[]> {
        //return [];
        try {
           const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/automations/checkpoints?organizationId=3`;
           const response = await axios.get(requestUrl);
           return response.data ?? [];
        } catch (error: any) {
           console.error('An unexpected error occurred:', error);
           return [];
        }
    }
    
    public async getCheckPointsTicketsById(id: number): Promise<ITicketDetails[]> {
        //return {} as ITicketDetails;
        try {
           const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/automations/checkpoints/${id}?organizationId=3`;
           const response = await axios.get(requestUrl);
           return response.data ?? [];
        } catch (error: any) {
           console.error('An unexpected error occurred:', error);
           return [];
        }
    }
    
    public async regenerateCheckPointsTicketsById(ticketId: number, message: string): Promise<boolean> {
        //return {} as ITicketDetails;
        try {
         console.log(message);
         console.log(ticketId);
         const regenerate: ITicketGenerate = {  regenerateMessage: message };
         const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/automations/checkpoints/${ticketId}/regeneratebyId?organizationId=3`;
         return await this.PerformPost(requestUrl, regenerate);
        } catch (error: any) {
           console.error('An unexpected error occurred:', error);
           return false;
        }
    }
 
    
    public async DeclineCheckPointsTicketsById(ticketId: number, message: string): Promise<ITicketDetails[]> {
        //return {} as ITicketDetails;
        try {
         
         const regenerate: ITicketDecline = {  feedback: message };
         const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/automations/checkpoints/${ticketId}/decline`;
         return await this.PerformPost(requestUrl, regenerate);
        } catch (error: any) {
           console.error('An unexpected error occurred:', error);
           return [];
        }
    }
 
    
    public async approveCheckPointsTicketsById(id: number): Promise<ITicketDetails[]> {
        //return {} as ITicketDetails;
        try { 
         console.log(id); 
         const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/automations/checkpoints/${id}/approve`;
         return await this.PerformPost(requestUrl, {});
        } catch (error: any) {
           console.error('An unexpected error occurred:', error);
           return [];
        }
    }
    
    public async saveEditedResponseByTicketId(ticketId: number, message: string): Promise<boolean> {
      //return {} as ITicketDetails;
      try {
       console.log(message);
       console.log(ticketId);
       const regenerate: ITicketGenerate = {  regenerateMessage: message };
       const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/automations/checkpoints/${ticketId}/adjust`;
       return await this.PerformPost(requestUrl, regenerate);
      } catch (error: any) {
         console.error('An unexpected error occurred:', error);
         return false;
      }
  }
     
 
}