<template>
  <div
    class="bg-custom-bg bg-cover bg-center w-screen h-screen flex justify-center items-center"
  >
    <!-- <img class="image-19" src="../assets/Background.svg" /> -->
    <div class="EmailInitialContainer">
      <div
        class="rounded-[2.375rem] border-[0.188rem] p-[1.563rem] bg-[var(--color-background)] border-[var(--light-blue)]"
      >
        <div class="flex flex-col gap-5 items-center w-[18.75rem]">
          <div
            class="rounded-[0.625rem] border-[0.125rem] border-[var(--light-blue)] p-5 flex flex-row gap-5 items-center justify-center w-16 h-16"
          >
            <svg
              class="rounded-[0.625rem] w-12 h-12 overflow-visible"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30 13.9999C30 12.9762 29.6094 11.9526 28.8284 11.1716C28.0474 10.3905 27.0237 10 26 10M26 26C32.6274 26 38 20.6274 38 14C38 7.37258 32.6274 2 26 2C19.3726 2 14 7.37258 14 14C14 14.5474 14.0366 15.0862 14.1076 15.6141C14.2244 16.4824 14.2827 16.9165 14.2434 17.1912C14.2025 17.4773 14.1504 17.6315 14.0094 17.8838C13.874 18.126 13.6354 18.3646 13.1583 18.8417L2.93726 29.0627C2.59135 29.4086 2.4184 29.5816 2.29472 29.7834C2.18506 29.9624 2.10425 30.1575 2.05526 30.3615C2 30.5917 2 30.8363 2 31.3255V34.8C2 35.9201 2 36.4802 2.21799 36.908C2.40973 37.2843 2.71569 37.5903 3.09202 37.782C3.51984 38 4.07989 38 5.2 38H10V34H14V30H18L21.1583 26.8417C21.6354 26.3646 21.874 26.126 22.1162 25.9906C22.3685 25.8496 22.5227 25.7975 22.8088 25.7566C23.0835 25.7173 23.5176 25.7756 24.3859 25.8924C24.9138 25.9634 25.4526 26 26 26Z"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="flex flex-col gap-2">
            <div
              class="flex items-center justify-center text-[var(--color-white)] text-[1.813rem] font-semibold"
            >
              Check your email
            </div>
            <div class="text-white text-center">
              We sent a verification link to {{ email }}
            </div>
          </div>
          <!-- <div class="frame-22">
                    <div class="button">
                      <button class="h7 label">Enter code manually</button>
                    </div>
                  </div> -->
          <div class="flex flex-row gap-1 items-center justify-center">
            <div class="text-recieveMail text-[0.875rem] font-medium">
              Didn't receive the email?
            </div>
            <div class="flex">
              <a
                class="text-lightBlue text-[0.875rem] font-semibold cursor-pointer"
                @click="resendEmail"
                >Click to resend</a
              >
            </div>
          </div>
          <div class="flex flex-row gap-1.5 items-center justify-center">
            <svg
              class="w-[0.729rem] h-[0.729rem]"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.8327 6.9974H1.16602M1.16602 6.9974L6.99935 12.8307M1.16602 6.9974L6.99935 1.16406"
                stroke="white"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <a
              class="text-white font-medium text-[0.875rem] cursor-pointer"
              @click="goBackToLogin"
              >Back to log in</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import IconKey from "./icons/IconKey.vue";
import AuthService from "@/Services/AuthService";

export default defineComponent({
  components: {
    IconKey,
  },
  data() {
    return {
      loginUrl: `${window.location.origin}/auth/login`,
      router: useRouter(),
      route: useRoute(),
      email: "",
    };
  },
  methods: {
    EnterCodeManually() {
      this.router.push({
        path: "/token/verify",
        query: { emailKey: this.route.query.emailKey },
      });
    },
    async resendEmail() {
      const authService = new AuthService();
      this.email = sessionStorage.getItem("userEmail");
      this.fullName = sessionStorage.getItem("fullName");
      this.userId = sessionStorage.getItem("userId");
      try {
        const data = {
          email: this.email,
          fullName: this.fullName,
          userId: this.userId,
        };

        const response = await authService.ResendVerificationEmail(data);
        this.router.push({
          path: "/checkemail",
          query: { emailKey: response.emailKey },
        });
      } catch (error) {
        console.log(error);
      }
    },
    goBackToLogin() {
      this.router.push("/auth/login");
    },
  },
  created() {
    this.email = sessionStorage.getItem("resetPasswordEmail");
  },
});
</script>

<!-- <style scoped>
    .image-19,
    .image-19 * {
        box-sizing: border-box;
    }

    .image-19 {
        position: absolute;
        background-color: var(--background-primary, #031525);
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
    }

    .container {
        width: 100vw;
        height: 100vh;
        display: grid;
        align-content: center;
        justify-content: center;
        background-image: url("../assets/Background.svg");
    }

    .login-frame,
    .login-frame * {
        box-sizing: border-box;
    }

    .login-frame {
        background: var(--background-primary, #031525);
        border-radius: 38px;
        border-style: solid;
        border-color: var(--loginlines, #a7d0f8);
        border-width: 3px;
        padding: 25px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        position: relative;
    }

    .frame-2278 {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
        width: 300px;
    }

    .frame-3467202 {
        border-radius: 10px;
        border-style: solid;
        border-color: var(--loginlines, #a7d0f8);
        border-width: 2px;
        padding: 20px;
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 64px;
        height: 64px;
        position: relative;
        overflow: hidden;
    }

    .key-01 {
        border-radius: 10px;
        flex-shrink: 0;
        width: 48px;
        height: 48px;
        position: relative;
        overflow: visible;
    }

    .frame-2 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }

    .textarea {
        /* color: var(--white, #ffffff); */
        color: #ffffff;
        text-align: center;
        position: relative;
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .no-worries-we-ll-send-you-reset-instructions {
        color: white;
        text-align: center;
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .frame-22 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }

    .button {
        background: var(--white, #ffffff);
        border-radius: 16px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 300px;
        height: 52px;
        position: relative;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
    }

    .label {
        color: var(--background-primary, #031525);
        text-align: center;
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
    }

        .label:hover {
            cursor: pointer;
        }

    .row {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        align-items: center;
    }

    .text {
        color: var(--text-tetriary, #cbd6e3);
        text-align: left;
        position: relative;
    }

        .text:hover {
            text-decoration: none;
            cursor: default;
        }

    .buttons-button {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
        overflow: hidden;
    }

    .text2 {
        color: var(--loginlines, #a7d0f8);
        text-align: left;
        position: relative;
    }

        .text2:hover {
            text-decoration: underline;
            background-color: var(--background-primary);
            cursor: pointer;
        }

    .buttons-button2 {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        overflow: hidden;
    }

    .arrow-left {
        flex-shrink: 0;
        width: 11.67px;
        height: 11.67px;
        position: relative;
        overflow: visible;
    }

    .text3 {
        /* color: var(--white, #ffffff); */
        color: #ffffff;
        text-align: left;
        position: relative;
    }

        .text3:hover {
            background-color: var(--background-primary);
            cursor: pointer;
            text-decoration: underline;
        }
</style> -->
