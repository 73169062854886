<template>
    <div class="icon-user-profile">
        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="49" viewBox="0 0 44 49" fill="none">
  <path d="M5.5 40.5C9.78228 35.5452 15.5962 32.5 22 32.5C28.4038 32.5 34.2177 35.5452 38.5 40.5M30.25 15.5C30.25 20.4706 26.5563 24.5 22 24.5C17.4437 24.5 13.75 20.4706 13.75 15.5C13.75 10.5294 17.4437 6.5 22 6.5C26.5563 6.5 30.25 10.5294 30.25 15.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    </div>
</template>

<script setup lang="ts">

</script>

<style>
    .icon-user-profile {width: 44px;
height: 48px;
flex-shrink: 0;
    }
</style>