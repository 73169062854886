<template>
  <article class="organization-card bg-[var(--bg-menu-selected)] border border-[var(--color-menu-selected)] rounded-xl flex flex-col">
    <div class="card-content p-3">
      <div class="organization-header title flex gap-3 justify-start items-center">
        <div class="icon-wrapper bg-white min-w-10 min-h-10 rounded-md">
          <img
            :src="organization.logo"
            :alt="`${organization.organizationName} logo`"
            class="organization-icon"
            v-if="organization.logo"
          />
        </div>
        <h3 class="organization-name textMdSemibold">{{ organization.organizationName }}</h3>
      </div>
      <p class="organization-description bodySmall discription mt-2">{{ organization.description }}</p>
    </div>
    <footer class="card-footer">
      <div class="footer-content actions-buttons justify-end flex items-center border-t border-[var(--color-menu-selected)] border-[var(--light-blue) px-4 py-2">
        <button
          v-if="!showActionButton && (isLeaving || organization.isUserJoined)"
          class="leave-button bodySmallSemibold btn increment-button px-4 py-1 rounded-md cursor-pointer border-[var(--light-blue)]
          text-orangeRed bg-transparent"
          @click="showActionButtonGroup"
          :disabled="isLeaving"
        >
          Leave
        </button>
        <button v-else-if="!showActionButton && !isRequested" @click="changeStateToRequested" class="join-button bodySmallSemibold bg-[var(--color-button-blue)] p-2 rounded-md border-[var(--light-blue) ">
          Request to Join
        </button>
        <button v-else-if="!showActionButton && isRequested" class="requested-button bodySmallSemibold   p-2   ">
          Requested
        </button>
        <ActionButton
          v-if="showActionButton"
          @cancel="hideActionButtonGroup"
          @leave="leaveOrganization"
        />
      </div>
    </footer>
  </article>
</template>

<script lang="ts" setup>
import { ref, reactive } from 'vue';
import type { PropType } from 'vue';
import type { IOrganization } from '@/Models/Common/IOrganization';
import ActionButton from './ActionButton.vue';
import { OrganisationService } from '../../../Services/OrganisationService';

// Define props using defineProps
const props = defineProps({
  organization: {
    type: Object as PropType<IOrganization>,
    required: true,
  },
});


// State and methods
const isLeaving = ref(false);
const isRequested = ref(false);
const showActionButton = ref(false);

async function requestOrg(){
  try {
    const service = new OrganisationService();
    const isSuccess = await service.RequestOrganization(props.organization);
    if(isSuccess)
      props.organization.isUserJoined = true;
    else
      console.log("An unexpected error occurred while requesting the organization.");
    isRequested.value = false;
  } catch (error) {
    console.error('Error requesting the organization:', error);
  }
}

function showActionButtonGroup() {
  showActionButton.value = true;
}

function hideActionButtonGroup() {
  showActionButton.value = false;
}

async function leaveOrganization() {
  isLeaving.value = true;
  showActionButton.value = false;
  props.organization.isUserJoined = false;
  const service = new OrganisationService();
  const isSuccess = await service.LeaveOrganization(props.organization);
  if(!isSuccess){
      props.organization.isUserJoined = true;
      console.log("An unexpected error occurred while leaving the organization.");
      }
  isLeaving.value = false;
  
}

function changeStateToRequested() {
  isRequested.value = true;
  requestOrg();
}
</script>
<!-- 
<style scoped>
.organization-card {
  min-width: 320px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.09);
  background-color: #071a2b;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1201px) {
  .organization-card {
    flex-basis: calc(33.33% - 24px); /* Three cards in a row on larger screens */
  }
}
.card-content {
  padding: 24px;
}

.organization-header {
  display: flex;
  align-items: center;
  gap: 12px;
}

.icon-wrapper {
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  background-color: #fff;
}

.organization-icon {
  width: 48px;
  height: 48px;
  object-fit: contain;
}

.organization-name {
  color: #fff;
}

.organization-description {
  color: #cbd6e3;
  margin-top: 24px;
}

.card-footer {
  margin-top: auto;
}

.divider {
  border: none;
  height: 1px;
  background-color: rgba(102, 141, 203, 0.23);
}

.footer-content {
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
}

.leave-button,
.join-button,
.requested-button {
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
}

.leave-button {
  color: #f14d4d;
  background: none;
  border: none;
  align-self: flex-start;
  visibility: hidden;
}

.join-button {
  color: #fff;
  background-color: #7ba8ef;
  border: 1px solid #a7d0f8;
  align-self: flex-end;
}

.requested-button {
    color: #A7D0F8;
    background-color: #071a2b;
    align-self: flex-end;
    cursor: default;
    border: none;
}

.footer-content .leave-button {
  visibility: visible;
  align-self: flex-start;
}

.footer-content .join-button,
.footer-content .requested-button {
  visibility: visible;
  align-self: flex-end;
}

@media (max-width: 991px) {
  .card-content,
  .footer-content {
    padding: 20px;
  }
}
</style> -->