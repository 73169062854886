import type {IOrganization} from '@/Models/Common/IOrganization';
import Core from "./Core";
import AppConfigGlobal from "../core/config/uiSettings";
import axios from 'axios';


export class OrganisationService extends Core{
    public async getAllOrganisation():Promise<IOrganization[]>{
        try{
        const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/user/organizations/all`;
            const response = await this.PerformGet<IOrganization[]>(requestUrl);
        return response ?? [];
    } catch (error: any) {
        console.error('An unexpected error occurred:', error);
            return [];
    }
    }

    public async RequestOrganization(Organization : IOrganization):Promise<boolean>{
        try{
            const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/user/organizations/add`;
            const response = await this.PerformPost<boolean>(requestUrl, Organization);
        return response ?? false;
    } catch (error: any) {
        console.error('An unexpected error occurred:', error);
            return false;
    }
    }

    public async LeaveOrganization(Organization : IOrganization):Promise<boolean>{
        try{
            const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/user/organizations/leave`;
            const response = await this.PerformPut<boolean>(requestUrl, Organization);
        return response ?? false;
    } catch (error: any) {
        console.error('An unexpected error occurred:', error);
            return false;
    }
    }

}