<template>
    <div class="search-input">
        <label for="search" class="visually-hidden">Search</label>
        <input type="text"
               id="search"
               class="input-field paragraph"
               placeholder="Search"
               v-model="searchQuery"
               @input="onSearch"/>
        <span class="search-icon"><IconSearch /></span>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import IconSearch from '@/components/icons/IconSearch.vue';


export default defineComponent({
  name: 'SearchInput',
  components: {
    IconSearch,
  },
  data() {
    return {
      searchQuery: '',
    };
    },
    methods: {
        onSearch() {
          this.$emit('updateSearchQuery', this.searchQuery);
        }
      }
});

</script>

<style scoped>
    .search-input {
        position: relative;
        min-width: 200px;
        flex: 1;
        max-width: 320px;
    }

    .input-field {
        width: 100%;
        padding: 10px 14px 10px 40px;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        background-color: #11385b;
        color: #cbd6e3;
    }

    .search-icon {
        position: absolute;
        left: 14px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
    }

    .visually-hidden {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
    }
</style>