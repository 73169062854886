<template>
  <section class="chat-section mt-5">
    <h2 class="section-title text-xs mb-3 text-[#CBD6E3]">{{ title }}</h2>
    <div @close="closeActionModal" ref="modalContainer">
      <ul class="chat-list">
        <li
          v-for="(chat, index) in chats"
          :key="index"
          class="chat-item flex justify-between p-3 cursor-pointer hover:bg-menuSelected rounded-lg mb-2"
          :class="{
            'selected-item bg-menuSelected':
              AppConfigGlobal.CurrentThreadId === chat.id,
          }"
          @click.self="onThreadSelect(chat.id)"
        >
          <input
            :id="'threadName' + chat.id"
            type="text"
            class="chat-title w-full resize-none overflow-hidden whitespace-nowrap h-6 bg-transparent pointer-events-none border-none focus:outline-none focus:ring-0 w-[86%]"
            :value="chat.name.trim()"
            rows="1"
            @keyup.enter="renameThread(chat.id)"
          />
          <!--<span class="chat-title" @click="onThreadSelect(chat.id)">
                    {{chat.name}}
                </span>-->
          <!-- <div class="action-modal-box"> -->
          <IconThreeDot
            class="chat-icon"
            width="28px"
            height="28px"
            @click.stop="openActionModal($event, chat.id)"
          />
          <ActionModal
            v-if="showActionModal && activeModalId === chat.id"
            ref="modal"
            class="actionmodal"
            :modalRight="modalRight"
            :modalTop="modalTop"
            :isVisible="showActionModal"
            :threadId="selectedThreadId"
            @renameThreadbtn="renameThreadbtn"
            @deleteThreadbtn="deleteThreadbtn"
            @updatedeleteThread="updateDeleted"
            @onSettingsActionModalOpen="onSettingsActionModalOpen"
            @close="closeActionModal"
          />
          <!-- </div> -->
        </li>
      </ul>
    </div>

    <div class="action-sub-modal-box">
      <ThreadRenameModal
        :isVisible="isRenameModalVisible"
        :threadId="selectedThreadId"
        @closeSettingsActionModal="onRenameActionModalClose"
        @updateThreadTitle="updateTitle"
      />
    </div>
    <ThreadSettingsModal
      :isVisible="isSettingsModalVisible"
      :threadId="selectedThreadId"
      @closeSettingsActionModal="onSettingsActionModalClose"
      @updateThreadTitle="updateTitle"
    />
  </section>
</template>

<script setup lang="ts">
import {
  defineComponent,
  defineProps,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from "vue";
import type { PropType } from "vue";
import AppConfigGlobal from "../../../core/config/uiSettings";
import type { IUserThread } from "../../../Models/Common/IUserThread";
import ActionModal from "@/components/ActionModal.vue";
import { FreddyService } from "@/Services/FreddyService";
import ThreadSettingsModal from "@/components/ThreadSettingsModal.vue";
import { AssistantService } from "@/Services/AssistantService";
import { UserService } from "@/Services/UserService";
import ThreadRenameModal from "@/components/ThreadRenameModal.vue";
import IconThreeDot from "@/components/icons/IconThreeDot.vue";

const modalTop = ref(0);
const modalLeft = ref(0);
const modalBottom = ref(0);
const modalRight = ref(10);
const threadId = ref("");
const renameThreadtype = ref("text");
const selectedThreadId = ref(0);
const showActionModal = ref<boolean>(false);
const isSettingsModalVisible = ref(false);
const renamingThreadOldTitle = ref<string>("");
const activeModalId = ref<number | null>(null);
const modalContainer = ref<HTMLElement | null>(null);

const freddyService = new FreddyService();

// const histories = ref([
//   {
//     text: "Recent",
//     items: AppConfigGlobal.?.length
//       ? [...AppConfigGlobal.Threads].reverse()
//       : [],
//   },
// ]);
//const selectedThreadId =
// const isSettingsModalVisible = ref(false);
// const isSettingsMenuVisible = ref(false);
const isRenameModalVisible = ref(false);

const emits = defineEmits([
  "reloadAssistantDashboard",
  "updateThreadList",
  "updateThreadRename",
]);
interface Chat {
  title: string;
  iconSrc: string;
}

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  chats: {
    type: Array<IUserThread>,
    required: true,
  },
});
interface UpdateTitleParams {
  threadId: string;
  newTitle: string;
}

const chatsList = ref<IUserThread[]>([]);
const updateTitle = ({ threadId, newTitle }: UpdateTitleParams) => {
  const chatupdate = props.chats.find(
    (chat) => chat.id.toString() === threadId
  );
  if (chatupdate) {
    chatupdate.name = newTitle;
  }
};

interface deleteTthreadParams {
  threadId: string;
  newTitle: string;
}
const updateDeleted = ({ threadId }: deleteTthreadParams) => {
  chatsList.value = chatsList.value.filter(
    (chat) => chat.id.toString() !== threadId
  );
  // const chats = props.chats.find((chat) => chat.id === threadId);
  // if (chats) {
  //   chats.name = "";
  //   chats.id = 0;
  // }
};

const onThreadSelect = (threadId: number) => {
  //if(isThreadRenaming.value) return;
  AppConfigGlobal.CurrentThreadId = threadId;
};

// const fetchUpdatedChats = async () => {
//   const userService = new UserService();
//   try {
//     const updatedChats = await userService.GetUserThreadsByAssistantId();
//     //chats.value = updatedChats;
//   } catch (error) {
//     console.error("Error fetching updated threads:", error);
//   }
// };

const renameThreadbtn = (threadId: number) => {
  selectedThreadId.value = threadId;
  console.log(selectedThreadId.value);
  renamingThreadOldTitle.value =
    props.chats.find((x) => x.id === threadId)?.name || "";
  //renameThreadtype.value = "text";
  showActionModal.value = !showActionModal.value;
  //isRenameModalVisible.value = !isRenameModalVisible.value;
  //emits("reloadAssistantDashboard");
  const element = document.getElementById(`threadName${threadId}`);
  if (element) {
    element.focus();
  }
};

const renameThread = async (threadId: number) => {
  const element = document.getElementById(
    `threadName${threadId}`
  ) as HTMLTextAreaElement;
  if (element) {
    try {
      const response = await freddyService.Rename_Thread(
        threadId,
        element.value
      );
      emits("updateThreadRename", threadId, element.value);
    } catch {
      element.value = renamingThreadOldTitle.value;
    } finally {
      renamingThreadOldTitle.value = "";
      element.blur();
    }
  }
};

const onSettingsActionModalOpen = () => {
  isSettingsModalVisible.value = !isSettingsModalVisible.value;
  showActionModal.value = false;
};

const onSettingsActionModalClose = () => {
  isSettingsModalVisible.value = !isSettingsModalVisible.value;
};

const onRenameActionModalClose = () => {
  if (isRenameModalVisible.value) {
    isRenameModalVisible.value = false;
  }
};

const deleteThreadbtn = (threadId: number) => {
  freddyService
    .Delete_Thread(threadId)
    .then(() => {
      showActionModal.value = !showActionModal.value;
      emits("updateThreadList", threadId);
    })
    .catch((error) => {
      console.error("Error deleting thread:", error);
    });
};

const openActionModal = (event: MouseEvent, threadIdValue: number) => {
  // showActionModal.value = !showActionModal.value;
  //showActionModal.value = false;
  const posX = event.clientX;
  const posY = event.clientY;

  if (posY < window.scrollY + window.innerHeight - 120) {
    modalBottom.value = posY + 140;
    modalTop.value = posY + 20;
  } else {
    modalTop.value = posY - 140;
    modalBottom.value = posY - 20;
  }

  modalLeft.value = posX - 133;
  if (activeModalId.value != threadIdValue) {
    showActionModal.value = true;
    selectedThreadId.value = threadIdValue;
    activeModalId.value = threadIdValue;
  } else {
    showActionModal.value = !showActionModal.value;
  }
  //threadId.value = threadIdValue;
};
const closeActionModal = () => {
  console.log("inside close modal function");
  showActionModal.value = false;
  activeModalId.value = null;
  selectedThreadId.value = 0;
};

const handleClickOutside = (event: MouseEvent) => {
  if (
    modalContainer.value &&
    !modalContainer.value.contains(event.target as Node)
  ) {
    closeActionModal();
  }
};

onMounted(() => {
  window.addEventListener("mousedown", handleClickOutside);
});

onBeforeUnmount(() => {
  window.removeEventListener("mousedown", handleClickOutside);
});
</script>

<!--<style scoped lang="scss">
    @import "@/assets/componentmainstyle";

    .chat-section {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .section-title {
        color: #cbd6e3;
        margin-left: 14px;
        text-align: center;
    }

    .chat-list {
        display: flex;
        list-style-type: none;
        gap: 10px;
        padding: 0;
        flex-direction: column;
    }

    .chat-item {
        align-items: center;
        border-radius: 15px;
        color: #fff;
        display: flex;
        justify-content: space-between;
        /*margin-top: 8px;*/
        min-height: 50px;
        padding: 5px 13px;
        gap: 2px;
    }

    .chat-item:hover {
        @extend .selected-item;
        cursor: pointer;
    }

    .chat-icon {
        height: 20px;
        width: 20px;
    }

    .chat-title {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 40px;
        width: 100%;
        border: none;
        border-radius: 10px;
        resize: none;
        overflow-y: hidden;
        border-color: #2f3e4c;
        background-color: transparent;
        color: white;
        line-height: 20px;
        outline: none;
        pointer-events: none;
        font-size: 15px;
        font-family: "Inter";
        /*padding: 10px 0px;*/
    }

    .single-line-textarea {
        resize: none; /* Prevents resizing */
        overflow: hidden; /* Hides scrollbars */
        white-space: nowrap; /* Prevents text wrapping */
        height: 24px; /* Adjust height as needed */
    }
</style>-->
