<template>
  <div class="cursor-pointer">
    <i class="bi bi-copy"></i>
  </div>
</template>

<script>
import 'bootstrap-icons/font/bootstrap-icons.css';
export default {
};
</script>

<style>
.bi-copy {
    color: rgb(177, 168, 168);
}
.bi-copy:hover {
    color:white;
}
</style>
