<template>
    <div class="chat-item">
        <div class="chat-content flex p-2 items-center cursor-pointer">
            <img :src="avatar" alt="" class="chat-avatar size-8 mr-2" />
            <span class="h7 chat-name">{{ name }}</span>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'ChatItem',
        props: {
            name: {
                type: String,
                required: true,
            },
            avatar: {
                type: String,
                required: true,
            },
        },
    });
</script>

<!--
<style scoped lang="scss">
    .chat-item {
        border-radius: 15px;
        background-color: #031525;
        display: flex;
        min-height: 50px;
        align-items: center;
        overflow: hidden;
        padding: 5px 13px;
    }

    .chat-content {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .chat-avatar {
        aspect-ratio: 1;
        object-fit: contain;
        width: 40px;
        border-radius: 10px;
    }

    .chat-name {
        color: #fff;
    }
</style>-->
