<template>
  <div
    v-if="isVisible"
    class="modal"
    :style="{
      top: `${modalTop.toString()}px`,
      left: `${modalLeft.toString()}px`,
      bottom: `${modalBottom.toString()}px`,
      right: `${modalRight.toString()}px`,
    }"
  >
    <div class="action-container">
      <div class="action-rename" @click="renameThreadbtn(threadId)">
        <span>Rename</span>
        <span><icon-rename /></span>
      </div>
      <div class="action-settings action-rename" @click="openSettings()">
        <span>Settings</span>
        <span><icon-settings /></span>
      </div>
      <div class="action-delete action-rename" @click="deleteThread(threadId)">
        <span class="warn-red">Delete</span>
        <span><icon-delete /></span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import IconRename from "./icons/IconRename.vue";
import IconDelete from "./icons/IconDelete.vue";
import IconSettings from "./icons/IconSettings.vue";
import { FreddyService } from "@/Services/FreddyService";

export default defineComponent({
  props: {
    modalTop: {
      default: 0,
      required: false,
    },
    isVisible: {
      default: false,
      required: true,
    },
    modalLeft: {
      default: 145,
      required: false,
    },
    modalBottom: {
      default: 0,
      required: false,
    },
    threadId: {
      default: 0,
    },
    modalRight: {
      default: 0,
      required: false,
    },
  },
  setup() {},
  components: {
    IconRename,
    IconDelete,
    IconSettings,
  },
  methods: {
    renameThreadbtn(threadId: number) {
      this.$emit("renameThreadbtn", threadId);
    },
    deleteThread(threadId: number) {
      this.$emit("deleteThreadbtn", threadId);
      //const freddyService = new FreddyService();
      //try {
      //  const res = freddyService.Delete_Thread(threadId);
      //  this.$emit("updatedeleteThread", { threadId: threadId });
      //} catch (error) {
      //  console.log();
      //}
    },
    openSettings() {
      this.$emit("onSettingsActionModalOpen");
    },

    closeSettingsActionModal() {},
  },


});
</script>

<style scoped>
.modal {
  display: flex;
  align-items: center;
  position: fixed;
  width: 150px;
  height: 120px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  background: #13426e;
  left: 212px !important;
  z-index: 2;
}

.modal:focus-within .element {
  display: block;
}

.action-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  width: 100%;
}

.action-rename {
  display: flex;
  justify-content: space-between;
}

.warn-red {
  color: #f14d4d;
}

/* @media (max-width: 1040px) {
  .modal {
    display: none;
  }
} */

 @media (max-width: 768px){
  .modal{
    left: 280px !important;
  }
 }

 @media (min-width: 320px)  and (max-width:600px) {
    .modal[data-v-b6fe0fce] {
        left: 192px !important;
    }
}
</style>
